import styled from 'styled-components';
import { theme } from '../../theme/theme';

export const SettingsWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const SettingsContentWrapper = styled.div`
  width: ${theme.widths.contentWidth};
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  margin-top: 6rem;
  margin-bottom: 2rem;

  @media (min-width: 1300px) {
    width: 83%;
  }
`;

export const SettingsContent = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
  padding: 1.5rem;
  height: 100vh;
`;

// update image and password styles
export const UpdateImageAndPasswordContainer = styled.div`
  width: 100%;
  padding: 1rem;
  background: #f9fafb;
  border: 2px solid ${theme.colors.borderColor};
  display: flex;
  justify-content: space-between;
  border-radius: 7px;
  align-items: center;
`;

export const ImageAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ProfileImageContainer = styled.div`
  width: 65px;
  height: 65px;
  background-image: url('/assets/images/profile_image.jpeg');
  background-size: cover;
  border-radius: 100%;
`;

export const NameAndRoleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

export const UpdateButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

//personalinformation styles
export const PersonalInformationContainer = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  flex-direction: column;
  margin-top: 4rem;
  padding: 1rem;
  border-radius: 7px;
`;

export const PersonalInformationTopSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const EditButton = styled.button`
  width: 78px;
  height: 40px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
  border-radius: 20px;
  border: 1px solid ${theme.colors.borderColor};
  cursor: pointer;
`;

//infor card styles
export const InforCardWrapper = styled.div`
  min-width: 300px;
  max-width: 500px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const InforCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 7px;
  width: 50%;
`;
