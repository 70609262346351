import styled from "styled-components";

interface SpowseImageProbs {
  height?: string;
  width?: string;
  borderRadius?: string;
}

export const SpowseImage = styled.img<SpowseImageProbs>`
  width: ${(probs) => probs.width || "100%"};
  height: ${(probs) => probs.height};
  border-radius: ${(probs) => probs.borderRadius};
`;
