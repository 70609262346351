import React, { useState } from 'react';
import { RequestVerificationLinkSectionWrapper, RequestVerificationLinkSectionContent, RequestVerificationLinkSectionLeftSection, RequestVerificationLinkSectionFormArea, AuthPromptContainer } from './RequestVerificationink_styles';
import { theme } from '../../../theme/theme';
import { SpowseButton } from '../../../core/common/reuseables/spowse_button';
import { CommonText, Heading1 } from '../../../core/common/reuseables/text_styles';
import { HeightGap } from '../../../core/common/reuseables/gap';
import SpowseTextField from '../../../core/common/reuseables/spowse_textfield';
import { useNavigate } from 'react-router-dom';
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { CreateAccountSectionRightSection } from '../create_account/CreateAccount_styles';
import { SpowseImage } from '../../../core/common/reuseables/spowse_image';
import { AppImages } from '../../../core/utilities/app_images';
import ApiService from '../../../core/api/api';
import { RequestVerificationData, RequestVerificationResponse } from '../../../logic/responnse/request_verification_email';
import { ApiUrl } from '../../../core/api/api_url';
import SpinnerLoader from '../../../core/common/reuseables/spinner_loader';
import { createUser } from '../controller/controller';

type RequestVerificationLinkProps = {

};

const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Invalid email address")
    .required("Email is required"),
});

const RequestVerificationLink: React.FC<RequestVerificationLinkProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const apiService = new ApiService();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      const data: RequestVerificationData = {
        email: values.email,
      };
      try {
        setIsLoading(true);
        await apiService.postData<RequestVerificationData, RequestVerificationResponse>(ApiUrl.requestRegistrationLink, data).then(async (r) => {
          if (r?.data?.success === true) {
            await createUser(values.email, "");
            localStorage.setItem('user_email', values.email);
            navigate("/verify_email");
            setIsLoading(false);
            values.email = "";
          } else {
            setIsLoading(false);
            toast.error(r?.data?.message ?? "");
          }
        });
      } catch (e) {
        setIsLoading(false);
        toast.error("Sorry an error occurred!");
      }
    },
  });

  return <RequestVerificationLinkSectionWrapper>
    {
      isLoading && <SpinnerLoader />
    }
    <RequestVerificationLinkSectionContent>
      <RequestVerificationLinkSectionLeftSection>
        <RequestVerificationLinkSectionFormArea onSubmit={formik.handleSubmit}>
          <Heading1
            fontSize="30px"
            fontWeight="600"
            fontFamily="matter-bold"
            lineHeight="38px"
          >
            Create Account
          </Heading1>
          <HeightGap height="10px" />
          <CommonText
            fontSize="16px"
            fontWeight="400"
            fontFamily="matter-light"
            lineHeight="24px"
            color={`${theme.colors.lightGrey}`}
          >
            Sign Up to spowse admin
          </CommonText>
          <HeightGap height="20px" />
          <SpowseTextField
            height="44px"
            width="360px"
            placeHolder="Enter your email"
            label="Email"
            id="email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
          />
          {formik.touched.email && formik.errors.email ? (
            <div style={{ color: "red", padding: "10px" }}>{formik.errors.email}</div>) : null
          }
          <HeightGap height="40px" />

          <SpowseButton
            width="360px"
            height="50px"
            backgroundColor={`${theme.colors.primary}`}
            fontSize="18px"
            fontWeight="400"
            lineHeight="20px"
            fontFamily="matter-light"
            textColor={`${theme.colors.white}`}
            type='submit'
          >
            Verify Email
          </SpowseButton>
          <HeightGap height="20px" />
          <AuthPromptContainer>
            <CommonText
              fontSize="14px"
              fontWeight="400"
              lineHeight="20px"
              fontFamily="matter-light"
              color={`${theme.colors.lightGrey}`}
            >
              Already have an account?
            </CommonText>
            <SpowseButton
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              textColor={`${theme.colors.primary}`}
              width="auto"
              height="auto"
              backgroundColor="transparent"
              onClick={() => {
                navigate("/login", { replace: true });
              }}
            >
              Log in
            </SpowseButton>
          </AuthPromptContainer>
        </RequestVerificationLinkSectionFormArea>
      </RequestVerificationLinkSectionLeftSection>
      <CreateAccountSectionRightSection>
        <SpowseImage src={AppImages.welcome} alt="welcome-image" />
      </CreateAccountSectionRightSection>
    </RequestVerificationLinkSectionContent>
  </RequestVerificationLinkSectionWrapper>
}
export default RequestVerificationLink;