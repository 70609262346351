import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const UserManagementOverview = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 1rem 1.5rem;
  width: 100%;
`;

//user type styles
export const UserTypeSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const UserTypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 7px;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

export const UserTypeContainerFisrstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const UserTypeIconContainer = styled.div<{ background: string }>`
  background: ${(probs) => probs.background};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 110px;
  border: 0;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.linkInActiveTextColor};
  font-family: 'matter-medium';
  font-weight: 500;
`;

export const Option = styled.option``;

export const UserTypeContainerThirdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RateContainer = styled.div`
  background: #e7f6ec;
  width: 28px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

export const UserDemographicContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const UserDemographicByAge = styled.div`
  width: 49%;
  height: 445px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1rem;

  & > :nth-child(2) {
    margin-top: 1rem;
    margin-bottom: 10px;
  }
`;

export const UserDemographicByGender = styled.div`
  width: 49%;
  height: 445px;
  border: 1px solid ${theme.colors.borderColor};
  padding-top: 1.5rem;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :nth-child(1) {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

//user list styles
export const UserListWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1.2rem 0;
  flex-direction: column;
  margin-top: 2rem;
`;

export const UserListSearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

//table styles
export const UserListTableHeader = styled.thead`
  background: #f2f4f7;
`;

export const UserListTableHeaderText = styled.th`
  background: #f2f4f7;
  padding: 1.2rem 9px;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.linkInActiveTextColor};
`;

export const UserListTableText = styled.td`
  padding: 1rem 9px;
  text-align: start;
  border-bottom: 1px solid ${theme.colors.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.linkActiveTextColor};
`;

export const UserDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  & .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
  }
`;

export const UserProfilePicsAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const UserStatusContainer = styled.div<{ status?: string }>`
  background: #e4fff7;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${(probs) =>
    probs.status === 'Active'
      ? '#0a9341'
      : probs.status === 'Blocked' || probs.status === 'Suspended'
      ? '#F50707'
      : '#475467'};
  width: ${(probs) => (probs.status === 'Suspended' ? '75px' : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) =>
    probs.status === 'Active'
      ? '#E4FFF7'
      : probs.status === 'Blocked' || probs.status === 'Suspended'
      ? '#FEE6E6'
      : '#EAECF0'};
`;

export const UserOptionContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
`;

export const OptionContainer = styled.div`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const UserImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: black;
  font-size: 8px;
  font-weight: 300;
  font-family: 'Inter';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTr = styled.tr``;

//pagination styles

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  & .pagination {
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
    list-style: none;
  }

  & .pagination li {
    height: 40px;
    width: 40px;
    border-left: 1px solid ${theme.colors.borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'matter-bold';
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
    font-weight: 600;
  }

  & .pagination > :nth-child(1) {
    width: 100px;
  }

  & .n {
    width: 100px !important;
  }

  & .activeClass {
    background: #eaecf0;
  }
`;
