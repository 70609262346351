import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../../../core/firebase/firebase_config';
import * as Yup from 'yup';

//!firebase function

export const updateUser = async (
  email: string,
  token: string,
  isEmailVerified: boolean,
) => {
  try {
    const userDoc = doc(collection(db, 'users'), email);

    await updateDoc(userDoc, {
      token,
      isEmailVerified,
    });
  } catch (error) {
    console.error('Error updating user:', error);
  }
};

interface User {
  email: string;
  isEmailVerified: boolean;
  token: string;
}

export const createUser = async (email: string, token: string) => {
  try {
    const userDoc = doc(collection(db, 'users'), email);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      throw new Error('Email already exists.');
    }

    const newUser: User = {
      email,
      isEmailVerified: false,
      token,
    };

    await setDoc(userDoc, newUser);
  } catch (error) {
    console.error('Error creating user:', error);
  }
};

export const listenToEmailVerification = (
  email: string,
  callback: () => void,
) => {
  if (!email) {
    console.error('Email is required to listen for verification changes.');
    return () => {};
  }
  const userDoc = doc(collection(db, 'users'), email);

  return onSnapshot(userDoc, (docSnapshot) => {
    if (docSnapshot.exists()) {
      const data = docSnapshot.data();
      if (data?.isEmailVerified === true) {
        callback();
      }
    }
  });
};

export const getUserByEmail = async (
  email: string,
  setSucess: any,
): Promise<User | null> => {
  try {
    const userDoc = doc(db, 'users', email);
    const userSnapshot = await getDoc(userDoc);

    if (userSnapshot.exists()) {
      setSucess(true);
      return userSnapshot.data() as User;
    } else {
      console.log('No such user exists!');
      setSucess(false);
      return null;
    }
  } catch (error) {
    setSucess(false);
    console.error('Error fetching user by email:', error);
    return null;
  }
};

//! commom function
export interface FormValues {
  firstname: string;
  lastname: string;
  password: string;
  confirmPassword: string;
}

export interface LoginFormValues {
  email: string;
  password: string;
}

export const initialValues: FormValues = {
  firstname: '',
  lastname: '',
  password: '',
  confirmPassword: '',
};

export const loginInitialValues: LoginFormValues = {
  email: '',
  password: '',
};

// Yup validation schema
export const validationSchema = Yup.object({
  firstname: Yup.string()
    .min(2, 'Firstname must be at least 2 characters')
    .required('Firstname is required'),
  lastname: Yup.string()
    .min(2, 'Lastname must be at least 2 characters')
    .required('Lastname is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), ''], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const loginValidationSchema = Yup.object().shape({
  email: Yup.string()
    .email('Invalid email address')
    .required('Email is required'),
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
});
