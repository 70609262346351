import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const SubscriptionCardWrapper = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 1.5rem;
`;

export const SubscriptionCardFirstChild = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const SubscriptionCardDataContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${theme.colors.borderColor};
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 10px;
`;
