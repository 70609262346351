import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

export interface AuthenticationState {
  isUserAuthenticated: boolean;
  isUserEmailVerify: boolean;
  userEmail: string;
  saveToken: boolean;
}

const initialState: AuthenticationState = {
  isUserAuthenticated: false,
  isUserEmailVerify: false,
  userEmail: '',
  saveToken: false,
};

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    setIsUserAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isUserAuthenticated = action.payload;
    },

    setIsUserEmailVerify: (state, action: PayloadAction<boolean>) => {
      state.isUserEmailVerify = action.payload;
    },

    setIsSave: (state, action: PayloadAction<boolean>) => {
      state.saveToken = action.payload;
    },
  },
});

export const { setIsUserAuthenticated, setIsUserEmailVerify, setIsSave } =
  authenticationSlice.actions;

export default authenticationSlice.reducer;
