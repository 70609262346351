import styled from "styled-components";
import { theme } from "../../theme/theme";

export const DashboardWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const DashboardContentWrapper = styled.div`
  width: ${theme.widths.contentWidth};
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  margin-top: 6rem;
  margin-bottom: 2rem;

  @media (min-width: 1300px){
    width: 83%;
  }
`;

export const DashboardContent = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
  padding: 1.5rem;
`;
