import React from "react";
import {
  CampaignCardContainer,
  CampaignCardContainerFisrstRow,
  CampaignCardIconContainer,
  CampaignCardSectionWrapper,
  Select,
  Option,
  CampaignCardContainerThirdRow,
  RateContainer,
  OverviewWrapper,
  CampaignAndNewsletterContainer,
  CampaignChartContainer,
  TotalNewsletterChartContainer,
  PartnerShipContainer,
  PartnerShipContainerFirstChild,
  StatusContainer,
  StatusCircularContainer,
} from "./Overview_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import {
  campaignCardValues,
  overviewCampaignChart,
  overviewPartnershipChart,
  overviewTotalNewsletterChart,
} from "../../controller/campaign_config";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

type OverviewSectionProps = {};

const OverviewSection: React.FC<OverviewSectionProps> = () => {
  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                M${x},${y + height}
                L${x},${y + radius}
                Q${x},${y} ${x + radius},${y}
                L${x + width - radius},${y}
                Q${x + width},${y} ${x + width},${y + radius}
                L${x + width},${y + height}
                Z
              `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };
  return (
    <OverviewWrapper>
      <CampaignCardSectionWrapper>
        {campaignCardValues.map((value, index) => {
          return (
            <CampaignCardContainer key={index}>
              <CampaignCardContainerFisrstRow>
                <CampaignCardIconContainer
                  background={value.iconBackgroundColor}
                >
                  <SpowseImage src={value.icon} alt="img" width="auto" />
                </CampaignCardIconContainer>
                <Select id="options" name="options">
                  <Option value="This month">This month</Option>
                  <Option value="Today">Today</Option>
                  <Option value="7 day ago">7 day ago</Option>
                  <Option value="Last month">Last month</Option>
                </Select>
              </CampaignCardContainerFisrstRow>
              <Heading1
                fontFamily="matter-bold"
                fontWeight="600"
                color={`${theme.colors.linkActiveTextColor}`}
                fontSize="20px"
                lineHeight="30px"
              >
                {value.count}
              </Heading1>
              <CampaignCardContainerThirdRow>
                <CommonText
                  fontFamily="matter-medium"
                  fontWeight="500"
                  color={`${theme.colors.linkInActiveTextColor}`}
                  fontSize="14px"
                  lineHeight="20px"
                >
                  {value.type}
                </CommonText>
                <RateContainer>
                  <CommonText
                    fontFamily="matter-medium"
                    fontWeight="500"
                    fontSize="12px"
                    lineHeight="18px"
                    color="#0F973D"
                  >
                    {value.rate}
                  </CommonText>
                </RateContainer>
              </CampaignCardContainerThirdRow>
            </CampaignCardContainer>
          );
        })}
      </CampaignCardSectionWrapper>
      {/* campaign and total newsletter */}
      <CampaignAndNewsletterContainer>
        <CampaignChartContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-bold"
          >
            Campaigns
          </CommonText>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={overviewCampaignChart} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis
                type="number"
                tickLine={false}
                axisLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-light"
              />
              <YAxis
                type="category"
                dataKey="name"
                tickLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-light"
              />
              <Tooltip cursor={false} />
              <Bar dataKey="pv" fill="#165DFF" barSize={7}></Bar>
            </BarChart>
          </ResponsiveContainer>
        </CampaignChartContainer>
        <TotalNewsletterChartContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-bold"
          >
            Total newsletter
          </CommonText>
          <CommonText fontSize="12px" fontWeight="400" color="#475467">
            Amount
          </CommonText>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={overviewTotalNewsletterChart}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" fontSize="12px" tickLine={false} />
              <YAxis
                fontSize="12px"
                width={35}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatValue}
              />
              <Tooltip cursor={false} />
              <Bar
                dataKey="pv"
                fill="#165DFF"
                shape={<CustomBar />}
                barSize={20}
              />
            </BarChart>
          </ResponsiveContainer>
        </TotalNewsletterChartContainer>
      </CampaignAndNewsletterContainer>
      <PartnerShipContainer>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Partnership
        </CommonText>
        <PartnerShipContainerFirstChild>
          <CommonText fontSize="12px" fontWeight="400" color="#475467">
            Unit
          </CommonText>
          <div>
            <StatusContainer>
              <StatusCircularContainer backgroundColor="#165DFF" />
              <CommonText fontSize="12px" fontWeight="400" color="#1D2129">
                Active
              </CommonText>
            </StatusContainer>
            <StatusContainer>
              <StatusCircularContainer backgroundColor="#0FC6C2" />
              <CommonText fontSize="12px" fontWeight="400" color="#1D2129">
                Completed
              </CommonText>
            </StatusContainer>
          </div>
        </PartnerShipContainerFirstChild>
        <ResponsiveContainer width="100%" height="85%">
          <BarChart data={overviewPartnershipChart} barGap={0}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" fontSize="12px" tickLine={false} />
            <YAxis
              fontSize="12px"
              width={35}
              tickLine={false}
              axisLine={false}
              tickFormatter={formatValue}
            />
            <Tooltip cursor={false} />
            <Bar dataKey="active" fill="#165DFF" barSize={30} />
            <Bar dataKey="completed" fill="#0FC6C2" barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </PartnerShipContainer>
    </OverviewWrapper>
  );
};
export default OverviewSection;
