import React from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import { theme } from "./theme/theme";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./theme/global_styles";
import { AppContainer } from "./theme/AppContainer";
import RoutePaths from "./routes/routePaths";
import ScrollToTopOnNavigate from "./routes/scroll_to_top";
import { Provider } from "react-redux";
import { store } from "./logic/redux_store";
import useMediaQuery from "./core/common/use_media_Query";
import NotSupportedScreen from "./core/common/not_supported_screen";
import { ToastContainer } from "react-toastify";

function App() {
  return (
    <Provider store={store}>
      <Router>
        <ScrollToTopOnNavigate />
        <ThemeProvider theme={theme}>
          <GlobalStyles />
          <MainContent />
        </ThemeProvider>
      </Router>
    </Provider>
  );
}

function MainContent() {
  const media = useMediaQuery("(max-width: 1200px)");
  const location = useLocation();

  const isAuthVerifyRoute = location.pathname === "/auth/verify";

  return (
    <>
      {media && !isAuthVerifyRoute ? (
        <NotSupportedScreen />
      ) : (
        <AppContainer>
          <ToastContainer />
          <RoutePaths />
        </AppContainer>
      )}
    </>
  );
}

export default App;