import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const VerifySignUpSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

export const VerifySignUpSection = styled.div`
  width: 40%;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
  display: flex;
  align-content: center;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  padding: 2rem;
  flex-direction: column;

  & > :nth-child(1) {
    width: 60px;
    height: 60px;
    color: ${theme.colors.primary};
  }

  @media (max-width: 967px) {
    width: 60%;
  }

  @media (max-width: 500px) {
    width: 90%;
  }
`;
