import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const VisitorsAndDeviceWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const VisitorsSection = styled.div`
  width: 49%;
  height: 339px;
  border: 1px solid ${theme.colors.borderColor};
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :nth-child(1) {
    margin-left: 1.5rem;
    margin-bottom: 2rem;
  }

  & > :nth-child(2) {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
`;

//device section styles

export const DeviceInforSection = styled.div`
  width: 49%;
  height: 339px;
  border: 1px solid ${theme.colors.borderColor};
  padding-top: 1.5rem;

  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :nth-child(1) {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const DeviceOsBarChartContainer = styled.div<{ height?: string }>`
  width: 100%;
  height: ${(probs) => probs.height || '200px'};
  display: flex;
  align-items: center;
  padding-right: 1.5rem;
  margin-top: 1rem;
`;

export const DeviceOsContainer = styled.div`
  width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  margin-left: 1.5rem;
`;
