import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { CommonText } from "./text_styles";

type SpowseTextFieldProps = {
  width?: string;
  height?: string;
  borderColor?: string;
  border?: string;
  borderRadius?: string;
  placeHolder?: string;
  label?: string;
  prefixIxon?: any;
  background?: string;
  surfixIcon?: any;
  id?: string;
  name?: string;
  type?: string;
  onChange?: any;
  onBlur?: any;
  value?: string;
};

const TextFieldWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
`;

const TextFieldContainer = styled.div<{
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  backgrouground?: any;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 0 10px;
  width: ${(probs) => probs.width};
  height: ${(probs) => probs.height};
  position: relative;
  outline: none;
  border: ${(probs) => probs.border || `1px solid ${theme.colors.borderColor}`};
  box-shadow: 1px 1px 10px rgba(16, 24, 40, 0.05);
  border-radius: ${(probs) => probs.borderRadius || "7px"};
  background: ${(probs) => probs.backgrouground || "transparent"};

  & .icon{
    width: 30px;
    height: 30px;
    cursor: pointer;
    color: ${theme.colors.linkInActiveTextColor};
  }
`;

const TextField = styled.input<{
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  prefixIcon?: any;
  backgrouground?: any;
}>`
  width: ${(probs) => (probs.prefixIcon ? "90%" : "100%")};
  height: 100%;
  position: relative;
  outline: none;
  border: 0;
  font-family: "Inter", sans-serif;
  border-radius: ${(probs) => probs.borderRadius || "7px"};
  font-size: 16px;
  color: ${theme.colors.primary};
  font-weight: 500;
  padding: 0;
  background: ${(probs) => probs.backgrouground || "transparent"};

  /* Remove autofill background */
&:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 0px transparent inset;
  -webkit-text-fill-color: ${theme.colors.primary};;
}

&:-moz-autofill {
  box-shadow: 0 0 0px 0px transparent inset;
  -moz-text-fill-color: ${theme.colors.primary};
}

&:-ms-autofill {
  box-shadow: 0 0 0px 0px transparent inset;
  -ms-text-fill-color: ${theme.colors.primary};
}

&:-o-autofill {
  box-shadow: 0 0 0px 0px transparent inset;
  -o-text-fill-color: ${theme.colors.primary};
}

&:-webkit-autofill::first-line {
  font-size: 16px;
}

  &::placeholder {
    font-size: 16px;
    font-family: "matter-light";
    color: ${theme.colors.hintColor};
    font-weight: 400;
  }
`;

const SpowseTextField: React.FC<SpowseTextFieldProps> = ({
  width,
  height,
  borderColor,
  border,
  borderRadius,
  placeHolder,
  label,
  background,
  prefixIxon,
  surfixIcon,
  id,
  name,
  type,
  onChange,
  onBlur,
  value,
}) => {
  return (
    <TextFieldWrapper>
      {label && (
        <CommonText
          fontFamily={`"Inter", sans-serif`}
          color={`${theme.colors.veryDarkGrey}`}
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
        >
          {label}
        </CommonText>
      )}
      <TextFieldContainer
        height={height}
        width={width}
        border={border}
        borderColor={borderColor}
        borderRadius={borderRadius}
        backgrouground={background}
      >
        {prefixIxon && prefixIxon}
        <TextField placeholder={placeHolder}
          id={id}
          name={name}
          type={type}
          onChange={onChange}
          onBlur={onBlur}
          value={value} />
        {surfixIcon && surfixIcon}
      </TextFieldContainer>
    </TextFieldWrapper>
  );
};
export default SpowseTextField;
