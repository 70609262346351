import React from "react";
import {
  AccountCreatedSectionContent,
  AccountCreatedSectionFormArea,
  AccountCreatedSectionLeftSection,
  AccountCreatedSectionRightSection,
  AccountCreatedSectionWrapper,
} from "./AccountCreated_styles";

import { SpowseImage } from "../../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../../core/utilities/app_images";
import {
  CommonText,
  Heading1,
} from "../../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../../theme/theme";
import { HeightGap } from "../../../../../core/common/reuseables/gap";
import { useNavigate } from "react-router-dom";
import { AppIcons } from "../../../../../core/utilities/app_icons";
import { SpowseButton } from "../../../../../core/common/reuseables/spowse_button";
import { GoBackContainer } from "../../../verification/email_verification/EmailVerification_styles";
import { GoArrowLeft } from "react-icons/go";
import { setIsUserEmailVerify } from "../../../../../logic/slices/authentication_slice";
import { useDispatch } from "react-redux";

type AccountCreatedProps = {};

const AccountCreated: React.FC<AccountCreatedProps> = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleGoToLogin = () => {
    dispatch(setIsUserEmailVerify(false));
    navigate("/login", { replace: true });
  };

  return (
    <AccountCreatedSectionWrapper>
      <AccountCreatedSectionContent>
        <AccountCreatedSectionLeftSection>
          <AccountCreatedSectionFormArea>
            <SpowseImage src={AppIcons.micsIcon} alt="icon" width="auto" />
            <HeightGap height="30px" />
            <Heading1
              fontSize="30px"
              fontWeight="600"
              fontFamily="matter-bold"
              lineHeight="38px"
            >
              Account Created
            </Heading1>
            <HeightGap height="10px" />
            <CommonText
              fontSize="16px"
              fontWeight="400"
              fontFamily="matter-light"
              lineHeight="24px"
              color={`${theme.colors.lightGrey}`}
            >
              Your Account Is been Reviewed By the Admin. This Process Can Take
              Upto 48hrs
            </CommonText>
            <HeightGap height="30px" />
            <SpowseButton
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              textColor={`${theme.colors.primary}`}
              width="auto"
              height="auto"
              backgroundColor="transparent"
              onClick={handleGoToLogin}
            >
              <GoBackContainer>
                <GoArrowLeft />
                <CommonText
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color={`${theme.colors.primary}`}
                >
                  Go back To login
                </CommonText>
              </GoBackContainer>
            </SpowseButton>
          </AccountCreatedSectionFormArea>
        </AccountCreatedSectionLeftSection>
        <AccountCreatedSectionRightSection>
          <SpowseImage src={AppImages.welcome} alt="welcome-image" />
        </AccountCreatedSectionRightSection>
      </AccountCreatedSectionContent>
    </AccountCreatedSectionWrapper>
  );
};
export default AccountCreated;
