import styled from "styled-components";
import { theme } from "../../../../theme/theme";
import ProgressBar from "@ramonak/react-progress-bar";

export const ActiveUsersRightNowWrapper = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1.5rem 0;
  border-radius: 10px;
  margin-top: 2rem;

  & > :nth-child(1) {
    margin-left: 1.5rem;
    margin-bottom: 1.5rem;
  }
`;

export const ActiveUsersRightNowContentSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

export const ActiveUsersRightNowLeftSection = styled.div`
  width: 65%;
  height: 400px;
  background-image: url("/assets/icons/world_map.svg");
  background-size: cover;
  overflow: auto;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 1417px) {
    width: 60%;
  }
`;

export const ActiveUsersRightNowFirstPointer = styled.div`
  background: rgba(9, 152, 56, 0.1);
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const ActiveUsersRightNowSecondPointer = styled.div`
  background: rgba(9, 152, 56, 0.2);
  width: 24px;
  height: 24px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const ActiveUsersRightNowThirdPointer = styled.div`
  background: rgba(9, 152, 56, 1);
  width: 8px;
  height: 8px;
  border-radius: 100%;
  position: relative;
  z-index: 10;
`;

export const ActiveUsersRightNowContryInfor = styled.div<{
  index: number;
  pointerIndex: number;
}>`
  position: absolute;
  transform: scale(
    ${(probs) => (probs.index === probs.pointerIndex + 1 ? "1" : "0")}
  );
  transition: all 300ms ease-in-out;
  background: ${theme.colors.white};
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.4);
  flex-direction: column;
  min-width: 126px;
  max-width: 150px;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  left: 70%;
  gap: 4px;
  z-index: 10;
  margin: 10px;
`;

export const CountryFlagContainer = styled.div<{ backgroundImage: string }>`
  background-image: url(${(probs) => probs.backgroundImage});
  width: 30px;
  height: 30px;
  background-size: cover;
  border-radius: 100%;
  box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);
`;

//left section styles

export const ActiveUsersRightNowRightSection = styled.div`
  width: 31%;
  height: 400px;
  overflow: auto;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: 10px;

  @media (max-width: 1417px) {
    width: 36%;
  }
`;

export const Progress = styled(ProgressBar)``;

export const ProgressAndPercentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
