import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const SubscriptionRightSectionWrapper = styled.div`
  width: 30%;
  display: flex;
  flex-direction: column;
`;

export const SubscriptionRightSectionFirstConatiner = styled.div`
  width: 100%;
  padding: 1rem;
`;

export const PieChartWithNeedleContainer = styled.div`
  width: 100%;
  height: 300px;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const SubscriptionCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  & > :nth-child(1) {
    margin-bottom: 1.3rem;
    margin-top: 0.5rem;
  }
`;
