export const campaignCardValues = [
  {
    iconBackgroundColor: "#E6F0FE",
    count: "100,000",
    type: "Total Campaigns",
    rate: "+5%",
    icon: "/assets/icons/campaign_blue_icon.svg",
  },
  {
    iconBackgroundColor: "#E6FEF0",
    count: "7000",
    type: "Completed Campaigns",
    rate: "+5%",
    icon: "/assets/icons/campaign_green_icon.svg",
  },
  {
    iconBackgroundColor: "#FEF2E6",
    count: "2,500",
    type: "Ongoing Campaign",
    rate: "+5%",
    icon: "/assets/icons/campaign_gold_icon.svg",
  },

  {
    iconBackgroundColor: "#FEE6E6",
    count: "30",
    type: "Failed Campaign",
    rate: "+5%",
    icon: "/assets/icons/campaign_red_icon.svg",
  },
];

export const overviewCampaignChart = [
  { name: "Tech", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Matching", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Twitter", uv: 2000, pv: 800, amt: 2290 },
  { name: "Instagram", uv: 2780, pv: 3908, amt: 2000 },
  { name: "Facebook", uv: 1890, pv: 4800, amt: 2181 },
];

export const overviewTotalNewsletterChart = [
  { name: "Jan", uv:3000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 1000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 900, pv: 800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 2300, amt: 2100 },
  { name: "Aug", uv: 3490, pv: 1300, amt: 2100 },
  { name: "Sep", uv: 3490, pv: 1100, amt: 2100 },
  { name: "Oct", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Nov", uv: 3490, pv: 2100, amt: 2100 },
  { name: "Dec", uv: 3490, pv: 1300, amt: 2100 },
];

export const overviewPartnershipChart = [
  { name: "TMT", completed: 4000, active: 2400, amt: 2400 },
  { name: "UPT", completed: 3000, active: 1398, amt: 2210 },
  { name: "LIS", completed: 2000, active: 800, amt: 2290 },
  { name: "TYU", completed: 2780, active: 3908, amt: 2000 },
  { name: "PIO", completed: 1890, active: 4800, amt: 2181 },
  { name: "SINS", completed: 2390, active: 3800, amt: 2500 },
  { name: "KIO", completed: 3490, active: 5300, amt: 2100 },
  { name: "YT", completed: 3490, active: 4300, amt: 2100 },
  { name: "GOTY", completed: 3490, active: 4300, amt: 2100 },
  { name: "SLI", completed: 3490, active: 4300, amt: 2100 },
  { name: "TIS", completed: 3490, active: 4100, amt: 2100 },
  { name: "DoY", completed: 3490, active: 7300, amt: 2100 },
];

export const conversionRaterChart = [
  { name: "Tech", uv: 4000, pv: 400, amt: 2400 },
  { name: "Marketing", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Twitter", uv: 2000, pv: 800, amt: 2290 },
  { name: "Instagram", uv: 2780, pv: 2908, amt: 2000 },
  { name: "Facebook", uv: 1890, pv: 1800, amt: 2181 },
];

export const campaignListHeaderText = [
  "No",
  "Name",
  "Start Date",
  "End Date",
  "Budget",
  "Performance",
  "Status",
  "",
];

export const partnershipListHeaderText = [
  "No",
  "Partner Name",
  "Start Date",
  "Open Rate",
  "Click Rate",
  "Performance",
  "Status",
  "",
];

export const newsLetterListHeaderText = [
  "No",
  "Name",
  "Start Date",
  "Open Rate",
  "Click Rate",
  "Performance",
  "Status",
  "",
];

export const partnershipList = [
  {
    partnerName: "John News Letter",
    startDate: "12/6/24",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Tech Daily",
    startDate: "1/7/24",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Completed",
  },
  {
    partnerName: "Health Weekly",
    startDate: "2/10/24",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Travel Explorer",
    startDate: "3/12/24",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Foodie Magazine",
    startDate: "4/5/24",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
  {
    partnerName: "Sports Monthly",
    startDate: "5/15/24",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Fashion Insights",
    startDate: "6/1/24",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
  {
    partnerName: "Music Vibes",
    startDate: "7/14/24",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Fitness Guide",
    startDate: "8/20/24",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Automotive Digest",
    startDate: "9/10/24",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Completed",
  },
  {
    partnerName: "Finance Journal",
    startDate: "10/3/24",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Real Estate News",
    startDate: "11/20/24",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Beauty Tips",
    startDate: "12/1/24",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
  {
    partnerName: "Gaming World",
    startDate: "1/18/25",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Literature Weekly",
    startDate: "2/25/25",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Science Today",
    startDate: "3/6/25",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Completed",
  },
  {
    partnerName: "Art Magazine",
    startDate: "4/22/25",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Photography Journal",
    startDate: "5/10/25",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Nature Explorer",
    startDate: "6/12/25",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
  {
    partnerName: "Environment Weekly",
    startDate: "7/14/25",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Culture Digest",
    startDate: "8/16/25",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "History Monthly",
    startDate: "9/18/25",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Completed",
  },
  {
    partnerName: "Adventure Weekly",
    startDate: "10/20/25",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Charity News",
    startDate: "11/22/25",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Community Digest",
    startDate: "12/7/25",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
  {
    partnerName: "Innovation Weekly",
    startDate: "1/1/26",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Leadership Journal",
    startDate: "2/3/26",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Mentorship Digest",
    startDate: "3/5/26",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Completed",
  },
  {
    partnerName: "Technology Monthly",
    startDate: "4/7/26",
    openRate: "Good",
    clickRate: "Good",
    performance: "Good",
    status: "Active",
  },
  {
    partnerName: "Sustainability News",
    startDate: "5/9/26",
    openRate: "Good",
    clickRate: "Bad",
    performance: "Good",
    status: "Completed",
  },
  {
    partnerName: "Media Trends",
    startDate: "6/11/26",
    openRate: "Bad",
    clickRate: "Good",
    performance: "Bad",
    status: "Active",
  },
];

export const campaignListData = [
  {
    name: "Dating Campaign",
    startDate: "12/6/24",
    endDate: "12/6/24",
    budget: "N300,00",
    performance: "Good",
    status: "Active",
  },
  {
    name: "Health Campaign",
    startDate: "1/7/24",
    endDate: "1/8/24",
    budget: "N450,00",
    performance: "Bad",
    status: "Completed",
  },
  {
    name: "Education Campaign",
    startDate: "2/10/24",
    endDate: "3/12/24",
    budget: "N200,00",
    performance: "Good",
    status: "Active",
  },
  {
    name: "Fitness Campaign",
    startDate: "4/5/24",
    endDate: "4/20/24",
    budget: "N500,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Travel Campaign",
    startDate: "5/11/24",
    endDate: "5/15/24",
    budget: "N350,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Food Campaign",
    startDate: "6/1/24",
    endDate: "6/30/24",
    budget: "N400,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Tech Campaign",
    startDate: "7/7/24",
    endDate: "7/14/24",
    budget: "N600,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Music Campaign",
    startDate: "8/20/24",
    endDate: "9/10/24",
    budget: "N250,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Fashion Campaign",
    startDate: "10/3/24",
    endDate: "10/17/24",
    budget: "N550,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Sports Campaign",
    startDate: "11/5/24",
    endDate: "11/20/24",
    budget: "N300,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Finance Campaign",
    startDate: "12/1/24",
    endDate: "12/15/24",
    budget: "N450,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Real Estate Campaign",
    startDate: "1/2/25",
    endDate: "1/18/25",
    budget: "N700,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Automotive Campaign",
    startDate: "2/4/25",
    endDate: "2/25/25",
    budget: "N500,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Beauty Campaign",
    startDate: "3/6/25",
    endDate: "3/20/25",
    budget: "N300,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Gaming Campaign",
    startDate: "4/8/25",
    endDate: "4/22/25",
    budget: "N350,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Literature Campaign",
    startDate: "5/10/25",
    endDate: "5/30/25",
    budget: "N450,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Science Campaign",
    startDate: "6/12/25",
    endDate: "6/27/25",
    budget: "N600,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Art Campaign",
    startDate: "7/14/25",
    endDate: "7/29/25",
    budget: "N200,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Photography Campaign",
    startDate: "8/16/25",
    endDate: "8/31/25",
    budget: "N550,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Nature Campaign",
    startDate: "9/18/25",
    endDate: "10/3/25",
    budget: "N300,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Environment Campaign",
    startDate: "10/20/25",
    endDate: "11/4/25",
    budget: "N400,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Culture Campaign",
    startDate: "11/22/25",
    endDate: "12/7/25",
    budget: "N600,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "History Campaign",
    startDate: "1/1/26",
    endDate: "1/15/26",
    budget: "N500,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Adventure Campaign",
    startDate: "2/3/26",
    endDate: "2/18/26",
    budget: "N700,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Charity Campaign",
    startDate: "3/5/26",
    endDate: "3/20/26",
    budget: "N250,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Community Campaign",
    startDate: "4/7/26",
    endDate: "4/22/26",
    budget: "N300,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Innovation Campaign",
    startDate: "5/9/26",
    endDate: "5/24/26",
    budget: "N450,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Leadership Campaign",
    startDate: "6/11/26",
    endDate: "6/26/26",
    budget: "N600,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Mentorship Campaign",
    startDate: "7/13/26",
    endDate: "7/28/26",
    budget: "N200,00",
    performance: "Bad",
    status: "Active",
  },
  {
    name: "Technology Campaign",
    startDate: "8/15/26",
    endDate: "8/30/26",
    budget: "N500,00",
    performance: "Good",
    status: "Completed",
  },
  {
    name: "Sustainability Campaign",
    startDate: "9/17/26",
    endDate: "10/2/26",
    budget: "N350,00",
    performance: "Bad",
    status: "Active",
  },
];
