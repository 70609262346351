import styled from "styled-components";
import { theme } from "../../theme/theme";
import ProgressBar from "@ramonak/react-progress-bar";

export const RevenueWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const RevenueContentWrapper = styled.div`
  width: ${theme.widths.contentWidth};
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  margin-top: 6rem;
  margin-bottom: 2rem;

  @media (min-width: 1300px){
    width: 83%;
  }
`;

export const RevenueContent = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
  padding: 1.5rem;
`;

// top section styles
export const RevenueCardSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const RevenueCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 7px;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

export const RevenueCardContainerFisrstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const RevenueCardIconContainer = styled.div<{ background: string }>`
  background: ${(probs) => probs.background};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 110px;
  border: 0;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.linkInActiveTextColor};
  font-family: "matter-medium";
  font-weight: 500;
`;

export const Option = styled.option``;

export const RevenueCardContainerThirdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RateContainer = styled.div`
  background: #e7f6ec;
  width: 28px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

//! revenue area chart styles
export const RevenueAreaChartContainer = styled.div`
  width: 100%;
  height: 340px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  margin-top: 1.5rem;
  padding: 1rem;

  & > :nth-child(2) {
    margin-top: 1rem;
    margin-bottom: 10px;
  }
`;

//! revenue by age and country styles
export const RevenueByAgeAndCountryContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const RevenueMadeByAgeContainer = styled.div`
  width: 59%;
  height: 445px;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1rem;

  & > :nth-child(2) {
    margin-top: 1rem;
    margin-bottom: 10px;
  }
`;

export const RevenueMadeByCountryContainer = styled.div`
  width: 39%;
  height: 445px;
  overflow: auto;
  gap: 1rem;
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 10px;
`;

export const Progress = styled(ProgressBar)``;

export const ProgressAndPercentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ProgressWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const ProgressContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;
