import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const EmailMarketingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem 1.5rem;
`;

// card section styles
export const EmailMarketingCardSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const EmailMarketingCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 7px;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

export const EmailMarketingCardContainerFisrstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const EmailMarketingCardIconContainer = styled.div<{
  background: string;
}>`
  background: ${(probs) => probs.background};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 110px;
  border: 0;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.linkInActiveTextColor};
  font-family: "matter-medium";
  font-weight: 500;
`;

export const Option = styled.option``;

export const EmailMarketingCardContainerThirdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RateContainer = styled.div`
  background: #e7f6ec;
  width: 28px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

// email data and sent email styles
export const EmailDataAndSentEmailContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
`;

export const EmailDataAndChartContainer = styled.div`
  width: 49%;
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1.5rem 1.3rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
`;
export const ValueContainer = styled.div<{
  justifyContent?: string;
  marginTop?: string;
  marginBottom?: string;
}>`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: ${(probs) => probs.justifyContent || "end"};
  gap: 20px;
  margin-top: ${(probs) => probs.marginTop || "2rem"};
  margin-bottom: ${(probs) => probs.marginBottom || "5px"};
`;

export const ValueTextContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const ColoredContainer = styled.div<{ backgroundColor: string }>`
  background: ${(probs) => probs.backgroundColor};
  width: 8px;
  height: 8px;
  border-radius: 100%;
`;

export const SentEmailContainer = styled.div<{ width?: string }>`
  width: ${(probs) => probs.width || "49%"};
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    margin-bottom: 1rem;
  }

  & > :nth-child(2) {
    margin-bottom: 10px;
  }
`;


// email performance styles
export const EmailPerformanceListWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1.2rem 0;
  flex-direction: column;
  margin-top: 2rem;
`;

export const EmailPerformanceListSearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

//table styles
export const EmailPerformanceListTableHeader = styled.thead`
  background: #f2f4f7;
`;

export const EmailPerformanceListTableHeaderText = styled.th`
  background: #f2f4f7;
  padding: 1.2rem 9px;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.linkInActiveTextColor};
`;

export const EmailPerformanceListTableText = styled.td`
  padding: 1rem 9px;
  text-align: start;
  border-bottom: 1px solid ${theme.colors.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.linkActiveTextColor};
`;

export const EmailPerformanceDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  & .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
  }
`;

export const EmailPerformanceProfilePicsAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const EmailPerformanceStatusContainer = styled.div<{ status?: string }>`
  background: #e4fff7;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${(probs) =>
    probs.status === "Good"
      ? "#0a9341"
      : probs.status === "Blocked" || probs.status === "Fair"
      ? "#F50707"
      : "#475467"};
  width: ${(probs) => (probs.status === "Excellent" ? "75px" : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) =>
    probs.status === "Good"
      ? "#E4FFF7"
      : probs.status === "Blocked" || probs.status === "Fair"
      ? "#FEE6E6"
      : "#EAECF0"};
`;

export const EmailPerformanceOptionContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
`;

export const OptionContainer = styled.div`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const EmailPerformanceImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: black;
  font-size: 8px;
  font-weight: 300;
  font-family: "Inter";
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTr = styled.tr``;

//pagination styles

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  & .pagination {
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
    list-style: none;
  }

  & .pagination li {
    height: 40px;
    width: 40px;
    border-left: 1px solid ${theme.colors.borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "matter-bold";
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
    font-weight: 600;
  }

  & .pagination > :nth-child(1) {
    width: 100px;
  }

  & .n {
    width: 100px !important;
  }

  & .activeClass {
    background: #eaecf0;
  }
`;
