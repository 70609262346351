import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const RequestVerificationLinkSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const RequestVerificationLinkSectionContent = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: 100vh;
  width: 100%;
`;

//!left sectionstyles
export const RequestVerificationLinkSectionLeftSection = styled.div`
  width: 50%;
  height: 100vh;
  max-height: 50000px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow-y: auto;
  padding: 4rem 0;
  background: ${theme.colors.white};

  &::-webkit-scrollbar {
    width: 12px;
    background: red;
  }

  &::-webkit-scrollbar-track {
    background: red;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: green;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: pink;
  }

  &::-webkit-scrollbar-thumb:active {
    background: yellow;
  }
`;

export const RequestVerificationLinkSectionFormArea = styled.form`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const ForgotPasswordContainer = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RememberMeContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

export const AuthPromptContainer = styled.div`
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

//! right section styles
export const RequestVerificationLinkSectionRightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.lightBlue};
  overflow: hidden;
`;
