import React, { useState } from 'react';
import { CommonText } from '../../../../core/common/reuseables/text_styles';
import {
  EditPermissionWrapper,
  EditToggleContainer,
  ModulesContainer,
  PermissionRoleSelectorContainer,
  PermissionWrapper,
  SetPermissionLeftSection,
  SetPermissionRightSection,
  SetPermissionWrapper,
} from './Permission_styles';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import { Divider } from '../../../../core/common/reuseables/Divider';
import { theme } from '../../../../theme/theme';
import { editPermission } from '../../controller/user_management_config';
import Switch from "react-switch";

type PermissionProps = {};

const Permission: React.FC<PermissionProps> = () => {
  const options = ['Super Admin', 'Admin', 'Editor'];
  const [checked, setChecked] = useState(false);

  return (
    <PermissionWrapper>
      <PermissionRoleSelectorContainer>
        <CommonText
          fontFamily="matter-medium"
          fontWeight="500"
          color="#344054"
          fontSize="16px"
        >
          Role
        </CommonText>
        <Dropdown
          options={options}
          onChange={(e) => { }}
          placeholder="Select an option"
          className="dropdown"
        />
      </PermissionRoleSelectorContainer>
      <EditPermissionWrapper>
        <CommonText>Edit Permission</CommonText>
        <Divider
          backgroundColor={`${theme.colors.borderColor}`}
          width="100%"
          height="1px"
        />
        <SetPermissionWrapper>
          <SetPermissionLeftSection>
            {editPermission.map((e, index) => {
              return (
                <ModulesContainer index={index}>
                  <CommonText fontSize='12px' fontWeight='500'>{e.option}</CommonText>
                </ModulesContainer>
              );
            })}
          </SetPermissionLeftSection>
          <SetPermissionRightSection>
            {editPermission.map((e, index) => {
              return (
                <EditToggleContainer index={index}>
                  {index === 0 ? <CommonText fontSize='12px' fontWeight='500'>{e.value}</CommonText> :
                    <Switch onColor='#0D6EFD' height={18} width={29} checkedIcon={false} uncheckedIcon={false} onChange={(e) => {
                      setChecked(e);
                    }} checked={checked} />
                  }
                </EditToggleContainer>
              );
            })}
          </SetPermissionRightSection>
        </SetPermissionWrapper>
      </EditPermissionWrapper>
    </PermissionWrapper>
  );
};
export default Permission;
