export const emailMarketingCardDetails = [
  {
    icon: '/assets/icons/send-2.svg',
    amount: '$40,000',
    backgroundColor: '#E6F0FE',
    emailMarketingType: 'Sent',
    rate: '+5%',
  },
  {
    icon: '/assets/icons/handclick.svg',
    amount: '30%',
    backgroundColor: '#E6FEF0',
    emailMarketingType: 'Click Through',
    rate: '+5%',
  },

  {
    icon: '/assets/icons/fluent_open-48-regular.svg',
    amount: '15.7%',
    backgroundColor: '#EFE6FE',
    emailMarketingType: 'Open Rate',
    rate: '+5%',
  },
  {
    icon: '/assets/icons/material-symbols-light_left-click.svg',
    amount: '50.7%',
    backgroundColor: '#EEEEEE',
    emailMarketingType: 'Click Rate',
    rate: '+5%',
  },
];

export const emailPerformanceHeaderText = [
  'No',
  'Email',
  'Publish Date',
  'Sent',
  'Click Through Rate',
  'Delivery Rate',
  'UnsubscribeRate',
  'Spam Rate',
];

export const featureCampaignHeaderText = [
  'No',
  'Full Name',
  'Created At',
  'Status',
  'Conversion',
];

export const socialMediaCard = [
  {
    type: 'Followers',
    number: '40,000',
    rate: '50%',
    text: 'compare to last month',
  },
  {
    type: 'Comments',
    number: '40,000',
    rate: '70%',
    text: 'compare to last month',
  },
  {
    type: 'Likes',
    number: '20,000',
    rate: '50%',
    text: 'compare to last month',
  },
  {
    type: 'Reach',
    number: '40,000',
    rate: '50%',
    text: 'compare to last month',
  },
];

export const seoCard = [
  {
    type: 'Traffic',
    number: '40,000',
    rate: '50%',
    text: 'compare to last month',
  },
  {
    type: 'Impression',
    number: '40,000',
    rate: '70%',
    text: 'compare to last month',
  },
  {
    type: 'Traffic',
    number: '20,000',
    rate: '50%',
    text: 'compare to last month',
  },
  {
    type: 'Reach',
    number: '40,000',
    rate: '50%',
    text: 'compare to last month',
  },
];

export const purpularPostListHeaderText = [
  'No',
  'Name',
  'Date',
  'Views',
  'Likes',
  'Comments',
  '',
];

export const purpularPostList = [
  {
    name: 'Dating',
    date: '12/12/2015',
    views: '200',
    likes: '10',
    comments: '10,000',
  },
  {
    name: 'Health Tips',
    date: '01/15/2016',
    views: '1,500',
    likes: '150',
    comments: '8,500',
  },
  {
    name: 'Tech Trends',
    date: '03/20/2016',
    views: '2,300',
    likes: '230',
    comments: '12,300',
  },
  {
    name: 'Travel Guide',
    date: '05/22/2016',
    views: '3,100',
    likes: '310',
    comments: '15,400',
  },
  {
    name: 'Food Recipes',
    date: '07/30/2016',
    views: '4,000',
    likes: '400',
    comments: '14,800',
  },
  {
    name: 'Fitness Routine',
    date: '09/05/2016',
    views: '5,600',
    likes: '560',
    comments: '11,200',
  },
  {
    name: 'Fashion Trends',
    date: '11/12/2016',
    views: '6,700',
    likes: '670',
    comments: '10,500',
  },
  {
    name: 'Business Advice',
    date: '01/19/2017',
    views: '7,800',
    likes: '780',
    comments: '16,000',
  },
  {
    name: 'Marketing Strategies',
    date: '03/25/2017',
    views: '9,200',
    likes: '920',
    comments: '17,300',
  },
  {
    name: 'Productivity Hacks',
    date: '05/30/2017',
    views: '10,500',
    likes: '1,050',
    comments: '20,000',
  },
  {
    name: 'Home Decor',
    date: '07/15/2017',
    views: '11,300',
    likes: '1,130',
    comments: '9,700',
  },
  {
    name: 'Gaming News',
    date: '09/20/2017',
    views: '13,400',
    likes: '1,340',
    comments: '18,200',
  },
  {
    name: 'Movie Reviews',
    date: '11/28/2017',
    views: '14,600',
    likes: '1,460',
    comments: '21,500',
  },
  {
    name: 'Music Trends',
    date: '01/07/2018',
    views: '15,700',
    likes: '1,570',
    comments: '22,600',
  },
  {
    name: 'Art and Culture',
    date: '03/14/2018',
    views: '17,800',
    likes: '1,780',
    comments: '19,800',
  },
  {
    name: 'Science Discoveries',
    date: '05/19/2018',
    views: '19,900',
    likes: '1,990',
    comments: '24,000',
  },
  {
    name: 'Historical Events',
    date: '07/22/2018',
    views: '20,300',
    likes: '2,030',
    comments: '25,500',
  },
  {
    name: 'Educational Content',
    date: '09/15/2018',
    views: '22,400',
    likes: '2,240',
    comments: '28,300',
  },
  {
    name: 'Political News',
    date: '11/21/2018',
    views: '24,500',
    likes: '2,450',
    comments: '30,000',
  },
  {
    name: 'Economics Insights',
    date: '01/11/2019',
    views: '26,600',
    likes: '2,660',
    comments: '31,700',
  },
  {
    name: 'Spiritual Guidance',
    date: '03/30/2019',
    views: '28,800',
    likes: '2,880',
    comments: '33,500',
  },
];

export const emailPerformanceList = [
  {
    email: 'Dating',
    publishDate: '12/10/2015',
    sent: '14',
    clickThroughRate: '20%',
    deliveryRate: '10%',
    spamRate: 'Good',
    unSbscribeRate: '40%',
  },
  {
    email: 'Shopping',
    publishDate: '01/15/2016',
    sent: '150',
    clickThroughRate: '18%',
    deliveryRate: '95%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Health',
    publishDate: '02/20/2016',
    sent: '200',
    clickThroughRate: '22%',
    deliveryRate: '90%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
  {
    email: 'Travel',
    publishDate: '03/12/2016',
    sent: '500',
    clickThroughRate: '25%',
    deliveryRate: '85%',
    spamRate: 'Good',
    unSbscribeRate: '3%',
  },
  {
    email: 'Education',
    publishDate: '04/17/2016',
    sent: '300',
    clickThroughRate: '19%',
    deliveryRate: '93%',
    spamRate: 'Fair',
    unSbscribeRate: '4%',
  },
  {
    email: 'Sports',
    publishDate: '05/10/2016',
    sent: '400',
    clickThroughRate: '23%',
    deliveryRate: '88%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'News',
    publishDate: '06/22/2016',
    sent: '600',
    clickThroughRate: '21%',
    deliveryRate: '92%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
  {
    email: 'Music',
    publishDate: '07/30/2016',
    sent: '700',
    clickThroughRate: '24%',
    deliveryRate: '89%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Fitness',
    publishDate: '08/15/2016',
    sent: '800',
    clickThroughRate: '26%',
    deliveryRate: '87%',
    spamRate: 'Good',
    unSbscribeRate: '3%',
  },
  {
    email: 'Finance',
    publishDate: '09/05/2016',
    sent: '900',
    clickThroughRate: '28%',
    deliveryRate: '91%',
    spamRate: 'Excellent',
    unSbscribeRate: '2%',
  },
  {
    email: 'Technology',
    publishDate: '10/25/2016',
    sent: '1000',
    clickThroughRate: '30%',
    deliveryRate: '94%',
    spamRate: 'Good',
    unSbscribeRate: '1%',
  },
  {
    email: 'Automotive',
    publishDate: '11/19/2016',
    sent: '1100',
    clickThroughRate: '32%',
    deliveryRate: '89%',
    spamRate: 'Fair',
    unSbscribeRate: '3%',
  },
  {
    email: 'Real Estate',
    publishDate: '12/01/2016',
    sent: '1200',
    clickThroughRate: '27%',
    deliveryRate: '86%',
    spamRate: 'Good',
    unSbscribeRate: '4%',
  },
  {
    email: 'Food',
    publishDate: '01/08/2017',
    sent: '1300',
    clickThroughRate: '29%',
    deliveryRate: '90%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Entertainment',
    publishDate: '02/14/2017',
    sent: '1400',
    clickThroughRate: '31%',
    deliveryRate: '92%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
  {
    email: 'Lifestyle',
    publishDate: '03/22/2017',
    sent: '1500',
    clickThroughRate: '33%',
    deliveryRate: '95%',
    spamRate: 'Good',
    unSbscribeRate: '1%',
  },
  {
    email: 'Fashion',
    publishDate: '04/15/2017',
    sent: '1600',
    clickThroughRate: '34%',
    deliveryRate: '90%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Beauty',
    publishDate: '05/10/2017',
    sent: '1700',
    clickThroughRate: '35%',
    deliveryRate: '89%',
    spamRate: 'Fair',
    unSbscribeRate: '3%',
  },
  {
    email: 'Gaming',
    publishDate: '06/05/2017',
    sent: '1800',
    clickThroughRate: '36%',
    deliveryRate: '88%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Parenting',
    publishDate: '07/01/2017',
    sent: '1900',
    clickThroughRate: '37%',
    deliveryRate: '92%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
  {
    email: 'Pets',
    publishDate: '08/15/2017',
    sent: '2000',
    clickThroughRate: '38%',
    deliveryRate: '94%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Home Improvement',
    publishDate: '09/12/2017',
    sent: '2100',
    clickThroughRate: '39%',
    deliveryRate: '90%',
    spamRate: 'Fair',
    unSbscribeRate: '3%',
  },
  {
    email: 'Gifts',
    publishDate: '10/05/2017',
    sent: '2200',
    clickThroughRate: '40%',
    deliveryRate: '92%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Gadgets',
    publishDate: '11/20/2017',
    sent: '2300',
    clickThroughRate: '41%',
    deliveryRate: '94%',
    spamRate: 'Good',
    unSbscribeRate: '1%',
  },
  {
    email: 'Photography',
    publishDate: '12/10/2017',
    sent: '2400',
    clickThroughRate: '42%',
    deliveryRate: '93%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
  {
    email: 'Movies',
    publishDate: '01/12/2018',
    sent: '2500',
    clickThroughRate: '43%',
    deliveryRate: '95%',
    spamRate: 'Good',
    unSbscribeRate: '1%',
  },
  {
    email: 'Books',
    publishDate: '02/15/2018',
    sent: '2600',
    clickThroughRate: '44%',
    deliveryRate: '91%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Gardening',
    publishDate: '03/25/2018',
    sent: '2700',
    clickThroughRate: '45%',
    deliveryRate: '92%',
    spamRate: 'Fair',
    unSbscribeRate: '3%',
  },
  {
    email: 'Crafts',
    publishDate: '04/20/2018',
    sent: '2800',
    clickThroughRate: '46%',
    deliveryRate: '94%',
    spamRate: 'Good',
    unSbscribeRate: '2%',
  },
  {
    email: 'Outdoors',
    publishDate: '05/15/2018',
    sent: '2900',
    clickThroughRate: '47%',
    deliveryRate: '96%',
    spamRate: 'Good',
    unSbscribeRate: '1%',
  },
  {
    email: 'Wellness',
    publishDate: '06/30/2018',
    sent: '3000',
    clickThroughRate: '48%',
    deliveryRate: '90%',
    spamRate: 'Excellent',
    unSbscribeRate: '1%',
  },
];
