import React, { useEffect, useState } from "react";
import {
  EmailVerificationSectionContent,
  EmailVerificationSectionFormArea,
  EmailVerificationSectionLeftSection,
  EmailVerificationSectionRightSection,
  EmailVerificationSectionWrapper,
  GoBackContainer,
} from "./EmailVerification_styles";

import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utilities/app_images";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { useNavigate } from "react-router-dom";
import { AppIcons } from "../../../../core/utilities/app_icons";
import { GoArrowLeft } from "react-icons/go";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { listenToEmailVerification } from "../../controller/controller";

type EmailVerificationProps = {};

const EmailVerification: React.FC<EmailVerificationProps> = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  function maskEmail() {
    const [localPart, domain] = email.split("@");

    if (localPart.length > 1) {
      const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
      return `${maskedLocalPart}@${domain}`;
    }

    return email;
  }

  useEffect(() => {
    const d = localStorage.getItem("user_email");
    setEmail(d ?? "");
    const unsubscribe = listenToEmailVerification(d ?? "", () => {
      navigate("/create_account", { replace: true });
    });

    return () => unsubscribe();
  }, [email, navigate]);

  return (
    <EmailVerificationSectionWrapper>
      <EmailVerificationSectionContent>
        <EmailVerificationSectionLeftSection>
          <EmailVerificationSectionFormArea>
            <SpowseImage src={AppIcons.emptyState} alt="icon" width="auto" />
            <Heading1
              fontSize="30px"
              fontWeight="600"
              fontFamily="matter-bold"
              lineHeight="38px"
            >
              Email Verification
            </Heading1>
            <HeightGap height="10px" />
            <CommonText
              fontSize="16px"
              fontWeight="400"
              fontFamily="matter-light"
              lineHeight="24px"
              color={`${theme.colors.lightGrey}`}
            >
              We sent a verification link to you {maskEmail()}
            </CommonText>
            <HeightGap height="30px" />
            <SpowseButton
              width="360px"
              height="50px"
              backgroundColor={`${theme.colors.primary}`}
              fontSize="18px"
              fontWeight="400"
              lineHeight="20px"
              fontFamily="matter-light"
              textColor={`${theme.colors.white}`}
              onClick={() => {
                toast.success("Open Your mail box to verify");
              }}
            >
              Verify Email
            </SpowseButton>
            <HeightGap height="20px" />
            <SpowseButton
              fontSize="14px"
              fontWeight="600"
              lineHeight="20px"
              textColor={`${theme.colors.primary}`}
              width="auto"
              height="auto"
              backgroundColor="transparent"
              onClick={() => {
                navigate("/request_verification_link", { replace: true });
              }}
            >
              <GoBackContainer>
                <GoArrowLeft />
                <CommonText
                  fontSize="14px"
                  fontWeight="600"
                  lineHeight="20px"
                  color={`${theme.colors.primary}`}
                >
                  Go back
                </CommonText>
              </GoBackContainer>
            </SpowseButton>
          </EmailVerificationSectionFormArea>
        </EmailVerificationSectionLeftSection>
        <EmailVerificationSectionRightSection>
          <SpowseImage src={AppImages.welcome} alt="welcome-image" />
        </EmailVerificationSectionRightSection>
      </EmailVerificationSectionContent>
    </EmailVerificationSectionWrapper>
  );
};
export default EmailVerification;
