import React, { useState } from 'react';
import { BlogContent, BlogContentWrapper, BlogListSearchbarContainer, BlogListTableHeader, BlogListTableHeaderText, BlogListTableText, BlogListWrapper, BlogOptionContainer, BlogWrapper, OptionContainer, PaginationWrapper, TableTr } from './Blog_styles';
import Header from '../header/Header';
import SideBar from '../side_bar/SideBar';
import ReactPaginate from 'react-paginate';
import { SearchAndButtonContainer } from '../campaigns/widdets/campaign_section/CampignSection_styles';
import { SpowseButton } from '../../core/common/reuseables/spowse_button';
import { SpowseImage } from '../../core/common/reuseables/spowse_image';
import { AppIcons } from '../../core/utilities/app_icons';
import SpowseTextField from '../../core/common/reuseables/spowse_textfield';
import { CommonText } from '../../core/common/reuseables/text_styles';
import { theme } from '../../theme/theme';
import { blogHeaderText, blogs } from './controllers/blog_config';

type BlogProps = {

};

const Blog: React.FC<BlogProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const blogsPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayedEmails = blogs.slice(
    currentPage * blogsPerPage,
    (currentPage + 1) * blogsPerPage
  );


  return <BlogWrapper>
    <Header headingText="Blog post" />
    <SideBar />
    <BlogContentWrapper>
      <BlogContent>
        <BlogListWrapper>
          <BlogListSearchbarContainer>
            <CommonText
              fontSize="18px"
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight="500"
            >
              Posts
            </CommonText>
            <SearchAndButtonContainer>
              <SpowseTextField
                placeHolder="Search"
                width="320px"
                height="45px"
                borderColor="transparent"
                prefixIxon={
                  <SpowseImage
                    src={AppIcons.searchIcon}
                    alt="search icon"
                    width="auto"
                  />
                }
              />
              <SpowseButton
                width="135px"
                height="38px"
                fontSize="14px"
                fontWeight="400"
                fontFamily="matter-light"
              >
                Create New Post
              </SpowseButton>
            </SearchAndButtonContainer>
          </BlogListSearchbarContainer>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "0",
              outline: "none",
              marginTop: "2rem",
            }}
          >
            <BlogListTableHeader>
              <tr>
                {blogHeaderText.map((e, index) => {
                  return (
                    <BlogListTableHeaderText
                      key={index}
                      style={{
                        width: index === 0 ? "100px" : "auto",
                        paddingLeft: index === 0 ? "1.2rem" : "9px",
                        fontWeight: "600"
                      }}
                    >
                      {e}
                    </BlogListTableHeaderText>
                  );
                })}
              </tr>
            </BlogListTableHeader>
            <tbody>
              {displayedEmails.map((row, index) => (
                <TableTr key={index}>
                  <BlogListTableText
                    style={{ width: "100px", paddingLeft: "1.2rem" }}
                  >
                    {index + 1}
                  </BlogListTableText>
                  <BlogListTableText style={{ fontWeight: "600",width: "200px" }}>
                    {row.title}
                  </BlogListTableText>
                  <BlogListTableText style={{ width: "150px" }}>
                    {row.publishDate}
                  </BlogListTableText>
                  <BlogListTableText style={{ width: "200px" }}>
                    {row.categories}
                  </BlogListTableText>
                  <BlogListTableText style={{ width: "200px" }}>
                    {row.author}
                  </BlogListTableText>
                  <BlogListTableText style={{ width: "150px" }}>
                    {row.tag}
                  </BlogListTableText>
                  <BlogListTableText>
                    <BlogOptionContainer>
                      <OptionContainer>
                        <SpowseImage
                          src={AppIcons.optionIcon}
                          alt="img"
                          width="auto"
                        />
                      </OptionContainer>
                    </BlogOptionContainer>
                  </BlogListTableText>
                </TableTr>
              ))}
            </tbody>
          </table>
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={`🡠 Previous`}
              nextLabel={"Next 🡢"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(blogs.length / blogsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              nextClassName="n"
              activeClassName={"activeClass"}
            />
          </PaginationWrapper>
        </BlogListWrapper>
      </BlogContent>
    </BlogContentWrapper>
  </BlogWrapper>
}
export default Blog;