import { createGlobalStyle } from "styled-components";
import { theme } from "./theme";

export const GlobalStyles = createGlobalStyle`

@font-face {
  font-family: "athletics-bold";
  src: url("/fonts/Athletics%20Black.otf") format("opentype");
}

@font-face {
  font-family: "athletics-extral-bold";
  src: url("/fonts/Athletics%20Black.otf") format("opentype");
}

@font-face {
  font-family: "athletics-medium";
  src: url("/fonts/Athletics%20Medium.otf") format("opentype");
}

@font-face {
  font-family: "athletics-regular";
  src: url("/fonts/Athletics%20Regular.otf") format("opentype");
}

@font-face {
  font-family: "matter-bold";
  src: url("/fonts/Matter-Bold.otf") format("opentype");
}

@font-face {
  font-family: "matter-extral-bold";
  src: url("/fonts/Matter-Heavy.otf") format("opentype");
}

@font-face {
  font-family: "matter-light";
  src: url("/fonts/Matter-Light.otf") format("opentype");
}

@font-face {
  font-family: "matter-medium";
  src: url("/fonts/Matter-Medium.otf") format("opentype");
}

@font-face {
  font-family: "matter-regular";
  src: url("/fonts/Matter-Regular.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  font-family: "matter-light", sans-serif;
}

body {
  font-family: 'matter-medium', sans-serif;
  width: 100%;
  padding: 0;
  margin: 0;
  background: ${theme.colors.backgroundColor};
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-thumb { 
  background-color: ${theme.colors.black};
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  display: none;
  background-color: rgba(0, 0, 0, 0.13);
}

/* For Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #888 #f0f0f0;
}

/* For Microsoft Edge */
*::-ms-scrollbar {
  width: 8px;
}

*::-ms-scrollbar-thumb {
  background-color: #888;
  border-radius: 4px;
}

*::-ms-scrollbar-track {
  background-color: #f0f0f0;
}
`;
