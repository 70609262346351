import React, { useEffect } from "react";
import {
  DashboardContent,
  DashboardContentWrapper,
  DashboardWrapper,
} from "./Dashboard_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import UserType from "./widgets/User_type/UserType";
import VisitorsAndDevice from "./widgets/Visitors_and_Device_type/VisitorsAndDevice";
import ActiveUsersRightNow from "./widgets/Active_user_right_now/ActiveUsersRightNow";
import ActiveUser from "./widgets/Active_user/ActiveUser";
import { SecureDataBaseUrl } from "../../core/database/secure_database_key";
import { getSecureItem } from "../../core/database/cryto";
import { useNavigate } from "react-router-dom";
import { VerifyLoginEmailResponse } from "../../logic/responnse/verify_login_email_response";

type DashboardProps = {};

const Dashboard: React.FC<DashboardProps> = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const decryptedData = async () => {
      try {
        const userDatas: VerifyLoginEmailResponse = await getSecureItem(SecureDataBaseUrl.USER_DATA);
        if (userDatas.accessToken === "" || userDatas.accessToken === null) {
          navigate("/login", { replace: true });
        }
      } catch (error) {
        navigate("/login", { replace: true });
      }
    };

    decryptedData();
  }, [navigate]);

  return (
    <DashboardWrapper>
      <SideBar />
      <Header headingText="Dashboard" />
      <DashboardContentWrapper>
        <DashboardContent>
          <UserType />
          <VisitorsAndDevice />
          <ActiveUsersRightNow />
          <ActiveUser />
        </DashboardContent>
      </DashboardContentWrapper>
    </DashboardWrapper>
  );
};
export default Dashboard;
