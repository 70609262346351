import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const SeoWrapper = styled.div`
  padding: 1.5rem;
`;

// cards styles
export const SeoCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const SeoCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
`;

export const SeoCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  & > :nth-child(1) {
    margin-top: 1.5rem;
  }
`;

export const SeoCardFirstChild = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 10px 10px;
`;

export const SeoCardThirdChild = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 1rem;
  gap: 10px;
`;

export const RateContainer = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  font-family: 'matter-medium';
  color: #0f973d;
  width: 38px;
  height: 17px;
  align-items: center;
  justify-content: center;
  background-color: #e7f6ec;
  border-radius: 8px;
`;

export const AtiveUserByOsAndClickRateContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const ClickRateSection = styled.div`
  width: 49%;
  height: 339px;
  border: 1px solid ${theme.colors.borderColor};
  padding-top: 1.5rem;
  padding-right: 1.5rem;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & > :nth-child(1) {
    margin-left: 1.5rem;
    margin-bottom: 2rem;
  }

  & > :nth-child(2) {
    margin-left: 2rem;
    margin-bottom: 1rem;
  }
`;

//feature campaigns and channel styles
export const FeatureCampaignAndChannelContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
`;

export const FeatureCampaignContainer = styled.div`
  width: 60%;
`;

//table styles
export const FeatureCampaignListWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1.5rem 0;
  flex-direction: column;
`;

export const FeatureCampaignListSearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

export const FeatureCampaignListTableHeader = styled.thead`
  background: #f2f4f7;
`;

export const FeatureCampaignListTableHeaderText = styled.th`
  background: #f2f4f7;
  padding: 1.2rem 9px;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.linkInActiveTextColor};
`;

export const FeatureCampaignListTableText = styled.td`
  padding: 1rem 9px;
  text-align: start;
  border-bottom: 1px solid ${theme.colors.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.linkActiveTextColor};
`;

export const FeatureCampaignDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  & .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
  }
`;

export const FeatureCampaignProfilePicsAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const FeatureCampaignStatusContainer = styled.div<{ status?: string }>`
  background: #e4fff7;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${(probs) =>
    probs.status === 'Good'
      ? '#0a9341'
      : probs.status === 'Blocked' || probs.status === 'Fair'
      ? '#F50707'
      : '#475467'};
  width: ${(probs) => (probs.status === 'Excellent' ? '75px' : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) =>
    probs.status === 'Good'
      ? '#E4FFF7'
      : probs.status === 'Blocked' || probs.status === 'Fair'
      ? '#FEE6E6'
      : '#EAECF0'};
`;

export const FeatureCampaignOptionContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
`;

export const OptionContainer = styled.div`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const FeatureCampaignImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: black;
  font-size: 8px;
  font-weight: 300;
  font-family: 'Inter';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTr = styled.tr``;

//pagination styles

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  & .pagination {
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
    list-style: none;
  }

  & .pagination li {
    height: 40px;
    width: 40px;
    border-left: 1px solid ${theme.colors.borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: 'matter-bold';
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
    font-weight: 600;
  }

  & .pagination > :nth-child(1) {
    width: 100px;
  }

  & .n {
    width: 100px !important;
  }

  & .activeClass {
    background: #eaecf0;
  }
`;

//channels styles
export const ChannelContainer = styled.div`
  width: 38%;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1.5rem 14px;
  border-radius: 10px;

  & > :nth-child(1) {
    margin-bottom: 7px;
  }

  & > :nth-child(2) {
    margin-bottom: 2rem;
  }
`;

export const ColoredContainerWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  align-self: center;
  justify-self: center;
  justify-content: center;
  margin-top: 2.5rem;
  gap: 10px;
`;

export const ColoredContainerWithText = styled.div<{ background: string }>`
  display: flex;
  align-items: center;
  gap: 5px;

  & > :nth-child(1) {
    background: ${(probs) => probs.background};
    width: 10px;
    height: 10px;
    border-radius: 100%;
  }
`;
