export class AppIcons {
  static emptyState = '/assets/icons/Empty State.svg';
  static micsIcon = '/assets/icons/Misc icon.svg';
  static spowseFullLogo = '/assets/icons/spowse_full_logo.svg';
  static blogIcon = '/assets/icons/blog_post.svg';
  static campaignIcon = '/assets/icons/campaign.svg';
  static dashboardIcon = '/assets/icons/dashboard.svg';
  static financeIcon = '/assets/icons/finance.svg';
  static logOutIcon = '/assets/icons/Logout_icon.svg';
  static marketingIcon = '/assets/icons/marketing.svg';
  static notificationIcon = '/assets/icons/Notification.svg';
  static searchIcon = '/assets/icons/Search_icon.svg';
  static settingIcon = '/assets/icons/setting-2.svg';
  static subscriptionIcon = '/assets/icons/subscriptions.svg';
  static userLog = '/assets/icons/user_log.svg';
  static userIcon = '/assets/icons/user.svg';
  static optionIcon = '/assets/icons/option.svg';
  static newsletterIcon = '/assets/icons/newsletter.svg';
  static overview = '/assets/icons/overview.svg';
  static partnership = '/assets/icons/partnership.svg';
  static sms = '/assets/icons/sms.svg';
  static document = '/assets/icons/document.svg';
  static twitter = '/assets/icons/twitter.svg';
  static instagram = '/assets/icons/instagram.svg';
  static youtube = '/assets/icons/openmoji_youtube.svg';
  static linkedin = '/assets/icons/skill-icons_linkedin.svg';
  static optionIcon2 = '/assets/icons/option_icon2.svg';
  static editIcon = '/assets/icons/edit.svg';
  static userRolesIcon = '/assets/icons/oui_app-users-roles.svg';
  static userOverview = "/assets/icons/user_overview.svg";
  static permissionsIcon = '/assets/icons/premission.svg';
}
