import React, { useEffect, useState } from "react";
import {
  VerifyLoginSectionContent,
  VerifyLoginSectionFormArea,
  VerifyLoginSectionLeftSection,
  VerifyLoginSectionRightSection,
  VerifyLoginSectionWrapper,
} from "./VerifyLogin_styles";

import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../../core/utilities/app_images";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import PinInput from "react-pin-input";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { useNavigate } from "react-router-dom";
import { RootState } from "../../../../logic/redux_store";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ApiService from "../../../../core/api/api";
import { ApiUrl } from "../../../../core/api/api_url";
import { VerifyLoginEmailData, VerifyLoginEmailResponse } from "../../../../logic/responnse/verify_login_email_response";
import SpinnerLoader from "../../../../core/common/reuseables/spinner_loader";
import { SecureDataBaseUrl } from "../../../../core/database/secure_database_key";
import { setSecureItem } from "../../../../core/database/cryto";

type VerifyLoginProps = {};

const VerifyLogin: React.FC<VerifyLoginProps> = () => {
  const [pin, setPin] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const apiService = new ApiService();
  const isSsaveToken = useSelector(
    (state: RootState) => state.authentication.saveToken
  );

  const handlePinChange = (value: string) => {
    setPin(value);
  };

  function maskEmail() {
    const email = localStorage.getItem("email") ?? "";
    const [localPart, domain] = email.split("@");

    if (localPart.length > 1) {
      const maskedLocalPart = localPart[0] + "*".repeat(localPart.length - 1);
      return `${maskedLocalPart}@${domain}`;
    }

    return email;
  }

  const handleVerifyLogin = async () => {
    if (pin.length < 4) {
      toast.error("Fill all the fields");
    } else {
      const email = localStorage.getItem("email") ?? "";
      setIsLoading(true);
      const data: VerifyLoginEmailData = {
        email: email,
        verificationPin: pin,
      }
      try {
        await apiService.postData<VerifyLoginEmailData, VerifyLoginEmailResponse>(ApiUrl.verifyLogin, data).then((response) => {
          if (response.data?.success === true) {
            if (isSsaveToken) {
              setSecureItem(SecureDataBaseUrl.USER_DATA, response.data);
              setIsLoading(false);
              navigate("/", { replace: true });
            } else {
              setSecureItem(SecureDataBaseUrl.USER_DATA, response.data);
              setIsLoading(false);
              navigate("/", { replace: true });
            }
          } else {
            setIsLoading(false);
            toast.error(response.data?.message);
          }
        })
      } catch (e) {
        setIsLoading(false);
        toast.error("Sorry an error occurred!");
      }
    }
  };

  useEffect(() => {
    console.log(localStorage.getItem("email") ?? "");
  }, [])
  return (
    <VerifyLoginSectionWrapper>
      {isLoading && <SpinnerLoader />}
      <VerifyLoginSectionContent>
        <VerifyLoginSectionLeftSection>
          <VerifyLoginSectionFormArea>
            <Heading1
              fontSize="30px"
              fontWeight="600"
              fontFamily="matter-bold"
              lineHeight="38px"
            >
              Verify your mail
            </Heading1>
            <HeightGap height="10px" />
            <CommonText
              fontSize="16px"
              fontWeight="400"
              fontFamily="matter-light"
              lineHeight="24px"
              color={`${theme.colors.lightGrey}`}
            >
              Please enter the four digit code sent to {maskEmail()}
            </CommonText>
            <HeightGap height="30px" />
            <PinInput
              length={4}
              initialValue={pin}
              onChange={handlePinChange}
              type="numeric"
              inputMode="number"
              style={{ padding: "0" }}
              inputStyle={{
                borderColor: `${theme.colors.borderColor}`,
                borderRadius: "4px",
                width: "100px",
                height: "100px",
                textAlign: "center",
                marginRight: "10px",
              }}
              inputFocusStyle={{
                borderColor: `${theme.colors.primary}`,
              }}
              autoSelect={true}
              regexCriteria={/^[0-9]*$/}
            />
            <HeightGap height="40px" />

            <SpowseButton
              width="100%"
              height="50px"
              backgroundColor={`${theme.colors.primary}`}
              fontSize="18px"
              fontWeight="400"
              lineHeight="20px"
              fontFamily="matter-light"
              textColor={`${theme.colors.white}`}
              onClick={handleVerifyLogin}
            >
              Sign in
            </SpowseButton>
          </VerifyLoginSectionFormArea>
        </VerifyLoginSectionLeftSection>
        <VerifyLoginSectionRightSection>
          <SpowseImage src={AppImages.welcome} alt="welcome-image" />
        </VerifyLoginSectionRightSection>
      </VerifyLoginSectionContent>
    </VerifyLoginSectionWrapper>
  );
};
export default VerifyLogin;
