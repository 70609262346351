import styled from "styled-components";
import { theme } from "../../theme/theme";

export const SubscriptionWrapper = styled.section`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: end;
`;

export const SubscriptionContentWrapper = styled.section`
  width: ${theme.widths.contentWidth};
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  margin-top: 4.5rem;
  margin-bottom: 2rem;

  @media (min-width: 1300px){
    width: 83%;
  }
`;

export const SubscriptionContent = styled.section`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${theme.colors.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
  padding-left: 1.5rem;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
`;
