import React, { useState } from 'react';
import { PermissionContainer, PermissionRow, RoleWrapper, TextConntainer } from './Roles_styles';
import { SpowseButton } from '../../../../core/common/reuseables/spowse_button';
import { SpowseImage } from '../../../../core/common/reuseables/spowse_image';
import SpowseTextField from '../../../../core/common/reuseables/spowse_textfield';
import { CommonText } from '../../../../core/common/reuseables/text_styles';
import { AppIcons } from '../../../../core/utilities/app_icons';
import { theme } from '../../../../theme/theme';
import { SearchAndButtonContainer } from '../../../campaigns/widdets/campaign_section/CampignSection_styles';
import { roleHederText, roles } from '../../controller/user_management_config';
import { RoleListWrapper, RoleListSearchbarContainer, RoleListTableHeader, RoleListTableHeaderText, TableTr, RoleListTableText, RoleOptionContainer, OptionContainer } from '../roles/Roles_styles';
import Modal from '../../../../core/common/reuseables/modal';
import SpowseTextArea from '../../../../core/common/reuseables/spowse_textarea';
import { HeightGap } from '../../../../core/common/reuseables/gap';

type RolesProps = {

};

const Roles: React.FC<RolesProps> = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return <RoleWrapper>
    <Modal title='New Role' isOpen={isModalOpen} onClose={closeModal}>
      <SpowseTextField label='Name' placeHolder='Enter role name' width='100%' height='45px' borderColor={`${theme.colors.borderColor}`} />
      <HeightGap height="1.5rem" />
      <SpowseTextArea label='Description' placeHolder='Enter role description' width='100%' height='200px' borderColor={`${theme.colors.borderColor}`} />
      <HeightGap height="1.5rem" />
      <SpowseButton
        width="100%"
        height="38px"
        fontSize="14px"
        fontWeight="400"
        fontFamily="matter-light"
        onClick={openModal}
      >
        Create Role
      </SpowseButton>
    </Modal>
    <RoleListWrapper>
      <RoleListSearchbarContainer>
        <CommonText
          fontSize="18px"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="500"
        >
          Roles
        </CommonText>
        <SearchAndButtonContainer>
          <SpowseTextField
            placeHolder="Search"
            width="320px"
            height="45px"
            borderColor="transparent"
            prefixIxon={
              <SpowseImage
                src={AppIcons.searchIcon}
                alt="search icon"
                width="auto"
              />
            }
          />
          <SpowseButton
            width="135px"
            height="38px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
            onClick={openModal}
          >
            New Role
          </SpowseButton>
        </SearchAndButtonContainer>
      </RoleListSearchbarContainer>
      <table
        style={{
          width: "95%",
          borderCollapse: "collapse",
          borderRadius: "10px",
          outline: "none",
          marginTop: "2rem",
          border: `1px solid ${theme.colors.borderColor}`,
        }}
      >
        <RoleListTableHeader>
          <tr style={{ borderTopRightRadius: "10px" }}>
            {roleHederText.map((e, index) => {
              return (
                <RoleListTableHeaderText
                  key={index}
                  style={{
                    width: index === 0 ? "200px" : "auto",
                    paddingLeft: index === 0 ? "1.2rem" : "1.2rem",
                    borderLeft: index === 0 ? `1px solid #EAECF0` : "0",
                  }}
                >
                  {e}
                </RoleListTableHeaderText>
              );
            })}
          </tr>
        </RoleListTableHeader>
        <tbody>
          {roles.map((row, index) => (
            <TableTr key={index}>
              <RoleListTableText

              >
                {row.role}
              </RoleListTableText>
              <RoleListTableText style={{ width: "300px" }}>
                <TextConntainer>
                  <CommonText fontFamily="matter-regular" fontSize="14px" color='#475467'>
                    {
                      row.description
                    }</CommonText>
                </TextConntainer>
              </RoleListTableText>
              <RoleListTableText style={{ width: "300px" }}>
                <PermissionContainer>
                  {
                    row.permission.map((e, index) => {
                      return <PermissionRow key={index}>
                        <div></div>
                        <CommonText
                          fontFamily="matter-regular" fontSize="14px" color='#475467'
                        >{e}</CommonText>
                      </PermissionRow>
                    })
                  }
                </PermissionContainer>
              </RoleListTableText>
              {/* <RoleListTableText>{row.country}</RoleListTableText> */}
              <RoleListTableText style={{ width: "200px" }}>
                {row.member}
              </RoleListTableText>
              <RoleListTableText>
                <RoleOptionContainer>
                  <OptionContainer>
                    <SpowseImage
                      src={AppIcons.optionIcon}
                      alt="img"
                      width="auto"
                    />
                  </OptionContainer>
                </RoleOptionContainer>
              </RoleListTableText>
            </TableTr>
          ))}
        </tbody>
      </table>
    </RoleListWrapper>
  </RoleWrapper>
}
export default Roles;