import React, { useState } from "react";
import {
  PieChartWithNeedleContainer,
  SubscriptionCardContainer,
  SubscriptionRightSectionFirstConatiner,
  SubscriptionRightSectionWrapper,
} from "./Subscription_right_Section_styles";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import { Divider } from "../../../../core/common/reuseables/Divider";
import { theme } from "../../../../theme/theme";
import SubscriptionCard from "../subscription_card/SubscriptionCard";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import Modal from "../../../../core/common/reuseables/modal";

type SubscriptionRightSectionProps = {};

const RADIAN = Math.PI / 180;

interface DataEntry {
  name: string;
  value: number;
  color: string;
}

interface NeedleProps {
  value: number;
  data: DataEntry[];
  cx: number;
  cy: number;
  iR: number;
  oR: number;
  color: string;
}

const data: DataEntry[] = [
  { name: "A", value: 80, color: "#ff0000" },
  { name: "B", value: 45, color: "#00ff00" },
  { name: "C", value: 25, color: "#0000ff" },
];

const cx = 150;
const cy = 200;
const iR = 50;
const oR = 100;
const value = 50;

const Needle: React.FC<NeedleProps> = ({
  value,
  data,
  cx,
  cy,
  iR,
  oR,
  color,
}) => {
  let total = data.reduce((sum, entry) => sum + entry.value, 0);
  const ang = 180.0 * (1 - value / total);
  const length = (iR + 2 * oR) / 3;
  const sin = Math.sin(-RADIAN * ang);
  const cos = Math.cos(-RADIAN * ang);
  const r = 5;
  const x0 = cx + 5;
  const y0 = cy + 5;
  const xba = x0 + r * sin;
  const yba = y0 - r * cos;
  const xbb = x0 - r * sin;
  const ybb = y0 + r * cos;
  const xp = x0 + length * cos;
  const yp = y0 + length * sin;

  return (
    <>
      <circle cx={x0} cy={y0} r={r} fill={color} />
      <path
        d={`M${xba} ${yba}L${xbb} ${ybb} L${xp} ${yp} L${xba} ${yba}`}
        fill={color}
      />
    </>
  );
};

const SubscriptionRightSection: React.FC<
  SubscriptionRightSectionProps
> = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <SubscriptionRightSectionWrapper>
      <Modal title="Edit Spowse premium" isOpen={isModalOpen} onClose={closeModal} width="600px">
        <SpowseTextField
          label="Monthly"
          placeHolder="N amount"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="3 months"
          placeHolder="N amount"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="6 months"
          placeHolder="N amount"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <SpowseButton
            width="49%"
            height="50px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
            onClick={openModal}
          >
            Update
          </SpowseButton> <SpowseButton
            width="49%"
            height="50px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
            onClick={openModal}
            backgroundColor="white"
            textColor={`${theme.colors.linkActiveTextColor}`}
            border={`1px solid ${theme.colors.borderColor}`}
          >
            Cancel
          </SpowseButton>
        </div>

      </Modal>
      <SubscriptionRightSectionFirstConatiner>
        <PieChartWithNeedleContainer>
          <CommonText>I'm still working on this</CommonText>
          <ResponsiveContainer width="100%" height="70%">
            <PieChart>
              <Pie
                dataKey="value"
                startAngle={180}
                endAngle={0}
                data={data}
                cx={cx}
                cy={cy}
                innerRadius={iR}
                outerRadius={oR}
                fill="#8884d8"
                stroke="none"
              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={entry.color} />
                ))}
              </Pie>
              <Needle
                value={value}
                data={data}
                cx={cx}
                cy={cy}
                iR={iR}
                oR={oR}
                color="#d0d000"
              />
            </PieChart>
          </ResponsiveContainer>
        </PieChartWithNeedleContainer>
      </SubscriptionRightSectionFirstConatiner>
      <Divider
        height="1px"
        width="100%"
        backgroundColor={`${theme.colors.borderColor}`}
      />
      <SubscriptionCardContainer>
        <CommonText
          fontFamily="matter-medium"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="500"
          fontSize="20px"
        >
          Subscriptions
        </CommonText>
        <SubscriptionCard
          data={[
            {
              value: "Monthly",
              price: "N6,413.00",
            },
            {
              value: "3 Monthly",
              price: "N18,331.00",
            },
            {
              value: "6 Monthly",
              price: "N36,209.00",
            },
          ]}
          onTap={openModal}
          subscriptionType="Spowse premium"
        />
        <SubscriptionCard
          data={[
            {
              value: "Monthly",
              price: "N6,413.00",
            },
            {
              value: "3 Monthly",
              price: "N18,331.00",
            },
            {
              value: "6 Monthly",
              price: "N36,209.00",
            },
          ]}
          onTap={openModal}
          subscriptionType="Spowse Platinium"
        />
      </SubscriptionCardContainer>
    </SubscriptionRightSectionWrapper>
  );
};
export default SubscriptionRightSection;
