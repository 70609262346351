import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const RoleWrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

//tables styles

export const RoleListWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1.2rem 0;
  flex-direction: column;
  margin-top: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const RoleListSearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
  width: 100%;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding-bottom: 1rem;
`;

export const RoleListTableHeader = styled.thead`
  background: #f2f4f7;
  border-radius: 10px;
`;

export const RoleListTableHeaderText = styled.th`
  background: #f2f4f7;
  padding: 1.2rem 9px;
  font-family: 'matter-medium';
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.linkInActiveTextColor};
  border-right: 1px solid #eaecf0;
`;

export const RoleListTableText = styled.td`
  padding: 1rem 1.2rem;
  text-align: start;
  border-bottom: 1px solid ${theme.colors.borderColor};
  font-weight: 400;
  font-size: 14px;
  color: #475467;
  border-right: 1px solid #eaecf0;
  font-family: 'matter-regular';
`;

export const RoleDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  & .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
  }
`;

export const RoleProfilePicsAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const RoleStatusContainer = styled.div<{ status?: string }>`
  background: #e4fff7;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${(probs) =>
    probs.status === 'Active'
      ? '#0a9341'
      : probs.status === 'Blocked' || probs.status === 'Suspended'
      ? '#F50707'
      : '#475467'};
  width: ${(probs) => (probs.status === 'Suspended' ? '75px' : '50px')};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) =>
    probs.status === 'Active'
      ? '#E4FFF7'
      : probs.status === 'Blocked' || probs.status === 'Suspended'
      ? '#FEE6E6'
      : '#EAECF0'};
`;

export const RoleOptionContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
`;

export const OptionContainer = styled.div`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const RoleImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: black;
  font-size: 8px;
  font-weight: 300;
  font-family: 'Inter';
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTr = styled.tr``;

export const TextConntainer = styled.div`
  width: 300px;
`;

export const PermissionContainer = styled.div``;

export const PermissionRow = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 5px;

  & > :nth-child(1) {
    width: 5px;
    height: 5px;
    background: #475467;
    border-radius: 100%;
  }
`;
