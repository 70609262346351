export const revenueCardDetails = [
  {
    icon: "/assets/icons/money_blue.svg",
    amount: "$40,000",
    backgroundColor: "#E6F0FE",
    revenueType: "Total Revenue made",
    rate: "+5%",
  },
  {
    icon: "/assets/icons/money_gold.svg",
    amount: "$20,000",
    backgroundColor: "#FEF2E6",
    revenueType: "Revenue From Premium",
    rate: "+5%",
  },
  {
    icon: "/assets/icons/money_green.svg",
    amount: "$20,000",
    backgroundColor: "#E6FEF0",
    revenueType: "Revenue From platinium",
    rate: "+5%",
  },
];

export const revenueData = [
  { name: "Jan", uv: 1000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 5300, amt: 2100 },
  { name: "Aug", uv: 2490, pv: 4300, amt: 2100 },
  { name: "Sep", uv: 1490, pv: 4300, amt: 2100 },
  { name: "Oct", uv: 5490, pv: 4300, amt: 2100 },
  { name: "Nov", uv: 3490, pv: 4100, amt: 2100 },
  { name: "Dec", uv: 1490, pv: 7300, amt: 2100 },
];

export const revenueMadeByAge = [
  { name: "18-24", uv: 4000, pv: 2400, amt: 2400 },
  { name: "24-30", uv: 3000, pv: 1398, amt: 2210 },
  { name: "30-40", uv: 2000, pv: 800, amt: 2290 },
  { name: "40-48", uv: 2780, pv: 3908, amt: 2000 },
  { name: "48-54", uv: 1890, pv: 4800, amt: 2181 },
  { name: "55-60", uv: 2390, pv: 3800, amt: 2500 },
];

export const countries = [
  {
    name: "United States",
    flag: "/assets/images/england.jpeg",
    progress: 3560,
  },
  {
    name: "Nigeria",
    flag: "/assets/images/Nigeria.jpeg",
    progress: 3000,
  },
  { name: "Togo", flag: "/assets/images/togo.jpeg", progress: 7000 },
  {
    name: "England",
    flag: "/assets/images/unitedkindom.jpeg",
    progress: 3700,
  },
  { name: "Canada", flag: "/assets/images/canada.jpeg", progress: 300 },
  { name: "Brazil", flag: "/assets/images/brazil.jpeg", progress: 1000 },
  {
    name: "Australia",
    flag: "/assets/images/autralia.jpeg",
    progress: 2000,
  },
  { name: "India", flag: "/assets/images/India.jpeg", progress: 3000 },
  {
    name: "China",
    flag: "/assets/images/china.jpeg",
    progress: 300,
  },
  { name: "Japan", flag: "/assets/images/japan.jpeg", progress: 200 },
];
