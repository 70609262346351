import React from "react";
import styled from "styled-components";
import { theme } from "../../../theme/theme";
import { CommonText } from "./text_styles";

type SpowseTextFieldProps = {
  width?: string;
  height?: string;
  borderColor?: string;
  border?: string;
  borderRadius?: string;
  placeHolder?: string;
  label?: string;
  prefixIxon?: any;
  background?: string;
  surfixIcon?: any;
};

const TextFieldWrapper = styled.div`
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 7px;
`;

const TextFieldContainer = styled.div<{
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  backgrouground?: any;
}>`
  display: flex;
  align-items: center;
  gap: 10px;
  position: relative;
  padding: 0 10px;
  width: ${(probs) => probs.width};
  height: ${(probs) => probs.height};
  position: relative;
  outline: none;
  border: ${(probs) => probs.border || `1px solid ${theme.colors.borderColor}`};
  box-shadow: 1px 1px 10px rgba(16, 24, 40, 0.05);
  border-radius: ${(probs) => probs.borderRadius || "7px"};
  background: ${(probs) => probs.backgrouground || "transparent"};
`;

const TextField = styled.textarea<{
  width?: string;
  height?: string;
  borderRadius?: string;
  border?: string;
  borderColor?: string;
  prefixIcon?: any;
  backgrouground?: any;

}>`
  width: ${(probs) => (probs.prefix ? "90%" : "100%")};
  height: 100%;
  position: relative;
  outline: none;
  border: 0;
  font-family: "Inter", sans-serif;
  border-radius: ${(probs) => probs.borderRadius || "7px"};
  font-size: 16px;
  color: ${theme.colors.primary};
  font-weight: 500;
  padding: 10px  0;
  background: ${(probs) => probs.backgrouground || "transparent"};
  resize: none ;
  min-height:200px ;

  &::placeholder {
    font-size: 16px;
    font-family: "matter-light";
    color: ${theme.colors.hintColor};
    font-weight: 400;
  }
`;

const SpowseTextArea: React.FC<SpowseTextFieldProps> = ({
  width,
  height,
  borderColor,
  border,
  borderRadius,
  placeHolder,
  label,
  background,
  prefixIxon,
  surfixIcon,
}) => {
  return (
    <TextFieldWrapper>
      {label && (
        <CommonText
          fontFamily={`"Inter", sans-serif`}
          color={`${theme.colors.veryDarkGrey}`}
          fontWeight="500"
          fontSize="14px"
          lineHeight="20px"
        >
          {label}
        </CommonText>
      )}
      <TextFieldContainer
        height={height}
        width={width}
        border={border}
        borderColor={borderColor}
        borderRadius={borderRadius}
        backgrouground={background}
      >
        {prefixIxon && prefixIxon}
        <TextField placeholder={placeHolder} />
        {surfixIcon && surfixIcon}
      </TextFieldContainer>
    </TextFieldWrapper>
  );
};
export default SpowseTextArea;
