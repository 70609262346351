import React from 'react';
import Header from '../header/Header';
import SideBar from '../side_bar/SideBar';
import { SettingsWrapper, SettingsContentWrapper, SettingsContent, UpdateImageAndPasswordContainer, ImageAndNameContainer, ProfileImageContainer, NameAndRoleContainer, UpdateButtonContainer, PersonalInformationContainer, PersonalInformationTopSection, EditButton, InforCardWrapper, InforCard } from './Settings_styles';
import { CommonText } from '../../core/common/reuseables/text_styles';
import { SpowseButton } from '../../core/common/reuseables/spowse_button';
import { theme } from '../../theme/theme';
import { SpowseImage } from '../../core/common/reuseables/spowse_image';
import { AppIcons } from '../../core/utilities/app_icons';
import { HeightGap } from '../../core/common/reuseables/gap';

type SettingsProps = {

};

const Settings: React.FC<SettingsProps> = () => {

  return <SettingsWrapper>
    <Header headingText="Settings" />
    <SideBar />
    <SettingsContentWrapper>
      <SettingsContent>
        <UpdateImageAndPasswordContainer>
          <ImageAndNameContainer >
            <ProfileImageContainer />
            <NameAndRoleContainer>
              <CommonText
                fontFamily="matter-medium"
                fontSize='20px'
                color={`${theme.colors.linkActiveTextColor}`}
                fontWeight='500'
              >
                Hosea
              </CommonText>
              <CommonText
                fontFamily="matter-medium"
                fontSize='16px'
                color={`${theme.colors.linkInActiveTextColor}`}
                fontWeight='500'
              >
                Admin
              </CommonText>
            </NameAndRoleContainer>
          </ImageAndNameContainer>
          <UpdateButtonContainer>
            <SpowseButton
              width="144px"
              height="40px"
              border={`2px solid ${theme.colors.borderColor}`}
              backgroundColor={`${theme.colors.white}`}
              fontSize='14x'
              textColor={`${theme.colors.linkActiveTextColor}`}
              fontWeight='500'
            >
              Update Password
            </SpowseButton>
            <SpowseButton
              width="215px"
              height="40px"
              fontSize='14x'
              fontWeight='500'
            >
              Update Profile Picture
            </SpowseButton>
          </UpdateButtonContainer>
        </UpdateImageAndPasswordContainer>
        {/* personal information section */}
        <PersonalInformationContainer>
          <PersonalInformationTopSection>
            <CommonText>Personal Information</CommonText>
            <EditButton>
              <SpowseImage src={AppIcons.editIcon} alt="ico" width='auto' />
              <CommonText fontFamily="matter-medium" fontSize='14px'>Edit</CommonText>
            </EditButton>
          </PersonalInformationTopSection>
        <HeightGap height='2rem'/>
          <InforContainer firstTitle={'Sex'} secodTitle={'Country'} firstValue={'Male'} secondValue={'Nigeria'} />
          <InforContainer firstTitle={'Email'} secodTitle={'Phone'} firstValue={'hoseasalako@gmail.com'} secondValue={'+2347065546783'} />
          <InforContainer firstTitle={'Name'} secodTitle={'Date of birth'} firstValue={'Hosea'} secondValue={'12/12/2006'} />
        </PersonalInformationContainer>
      </SettingsContent>
    </SettingsContentWrapper>
  </SettingsWrapper>
}
export default Settings;

type InforCardProbs = {
  firstTitle: string,
  secodTitle: string,
  firstValue: string,
  secondValue: string,
}

const InforContainer: React.FC<InforCardProbs> = ({ firstTitle, secodTitle, firstValue, secondValue, }) => {
  return <InforCardWrapper>
    <InforCard>
      <CommonText
        fontSize='14px'
        fontWeight='500'
        color='#475467'
      >
        {firstTitle}
      </CommonText>
      <CommonText>{firstValue}</CommonText>
    </InforCard>
    <InforCard>
      <CommonText
        fontSize='14px'
        fontWeight='500'
        color='#475467'
      >{secodTitle}
      </CommonText>
      <CommonText>{secondValue}</CommonText>
    </InforCard>
  </InforCardWrapper>
}