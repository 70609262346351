import React, { useState } from "react";
import {
  PaginationWrapper,
  PatnershipListSearchbarContainer,
  PatnershipListTableHeader,
  PatnershipListTableHeaderText,
  PatnershipListTableText,
  PatnershipListWrapper,
  PatnershipSectionWrapper,
  SearchAndButtonContainer,
  TableTr,
  UserStatusContainer,
} from "./PartnershipSection_styles";
import {
  PartnerShipContainer,
  PartnerShipContainerFirstChild,
  StatusCircularContainer,
  StatusContainer,
} from "../overview_section/Overview_styles";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import {
  Bar,
  BarChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ReactPaginate from "react-paginate";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import { AppIcons } from "../../../../core/utilities/app_icons";
import {
  overviewPartnershipChart,
  partnershipList,
  partnershipListHeaderText,
} from "../../controller/campaign_config";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import Modal from "../../../../core/common/reuseables/modal";

type PatnershipSectionProps = {};

const PatnershipSection: React.FC<PatnershipSectionProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const partnershipPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displaypartnership = partnershipList.slice(
    currentPage * partnershipPerPage,
    (currentPage + 1) * partnershipPerPage
  );

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <PatnershipSectionWrapper>
      <Modal title="Create Patnerships" isOpen={isModalOpen} onClose={closeModal}>
        <SpowseTextField
          label="Partner Name"
          placeHolder="Enter partner name"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Integration details"
          placeHolder="Enter integration details"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Objectives"
          placeHolder="Enter Objectives"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Send Date"
          placeHolder="Enter send date"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
          surfixIcon={<SpowseImage src="/assets/icons/calendar.svg" alt="icon" width="auto" />}
        />

        <HeightGap height="1.3rem" />
        <SpowseButton
          width="100%"
          height="50px"
          fontSize="14px"
          fontWeight="400"
          fontFamily="matter-light"
          onClick={openModal}
        >
          Create Partnership
        </SpowseButton>
      </Modal>
      <PartnerShipContainer>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Partnership
        </CommonText>
        <PartnerShipContainerFirstChild>
          <CommonText fontSize="12px" fontWeight="400" color="#475467">
            Unit
          </CommonText>
          <div>
            <StatusContainer>
              <StatusCircularContainer backgroundColor="#165DFF" />
              <CommonText fontSize="12px" fontWeight="400" color="#1D2129">
                Active
              </CommonText>
            </StatusContainer>
            <StatusContainer>
              <StatusCircularContainer backgroundColor="#0FC6C2" />
              <CommonText fontSize="12px" fontWeight="400" color="#1D2129">
                Completed
              </CommonText>
            </StatusContainer>
          </div>
        </PartnerShipContainerFirstChild>
        <ResponsiveContainer width="100%" height="85%">
          <BarChart data={overviewPartnershipChart} barGap={0}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" fontSize="12px" tickLine={false} />
            <YAxis
              fontSize="12px"
              width={35}
              tickLine={false}
              axisLine={false}
              tickFormatter={formatValue}
            />
            <Tooltip cursor={false} />
            <Bar dataKey="active" fill="#165DFF" barSize={30} />
            <Bar dataKey="completed" fill="#0FC6C2" barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </PartnerShipContainer>
      <PatnershipListWrapper>
        <PatnershipListSearchbarContainer>
          <CommonText
            fontSize="18px"
            color={`${theme.colors.linkActiveTextColor}`}
            fontWeight="500"
          >
            Patnerships
          </CommonText>
          <SearchAndButtonContainer>
            <SpowseTextField
              placeHolder="Search"
              width="320px"
              height="45px"
              borderColor="transparent"
              prefixIxon={
                <SpowseImage
                  src={AppIcons.searchIcon}
                  alt="search icon"
                  width="auto"
                />
              }
            />
            <SpowseButton
              width="135px"
              height="38px"
              fontSize="14px"
              fontWeight="400"
              fontFamily="matter-light"
              onClick={openModal}
            >
              Add Partnership
            </SpowseButton>
          </SearchAndButtonContainer>
        </PatnershipListSearchbarContainer>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "0",
            outline: "none",
            marginTop: "2rem",
          }}
        >
          <PatnershipListTableHeader>
            <tr>
              {partnershipListHeaderText.map((e, index) => {
                return (
                  <PatnershipListTableHeaderText
                    key={index}
                    style={{
                      width: index === 0 ? "100px" : "auto",
                      paddingLeft: index === 0 ? "1.2rem" : "9px",
                    }}
                  >
                    {e}
                  </PatnershipListTableHeaderText>
                );
              })}
            </tr>
          </PatnershipListTableHeader>
          <tbody>
            {displaypartnership.map((row, index) => (
              <TableTr key={index}>
                <PatnershipListTableText
                  style={{ width: "100px", paddingLeft: "1.2rem" }}
                >
                  {index + 1}
                </PatnershipListTableText>
                <PatnershipListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-regular"
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.partnerName}
                  </CommonText>
                </PatnershipListTableText>
                <PatnershipListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.startDate}
                  </CommonText>
                </PatnershipListTableText>
                <PatnershipListTableText style={{ width: "130px" }}>
                  <UserStatusContainer status={row.openRate}>
                    {row.openRate}
                  </UserStatusContainer>
                </PatnershipListTableText>
                <PatnershipListTableText
                  style={{
                    width: "130px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <UserStatusContainer status={row.clickRate}>
                    {row.clickRate}
                  </UserStatusContainer>
                </PatnershipListTableText>
                <PatnershipListTableText style={{ width: "130px" }}>
                  <UserStatusContainer status={row.performance}>
                    {row.performance}
                  </UserStatusContainer>
                </PatnershipListTableText>
                <PatnershipListTableText>
                  <UserStatusContainer status={row.status}>
                    {row.status}
                  </UserStatusContainer>
                </PatnershipListTableText>
              </TableTr>
            ))}
          </tbody>
        </table>
        <PaginationWrapper>
          <ReactPaginate
            previousLabel={`🡠   Previous`}
            nextLabel={"Next 🡢"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(partnershipList.length / partnershipPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            nextClassName="n"
            activeClassName={"activeClass"}
          />
        </PaginationWrapper>
      </PatnershipListWrapper>
    </PatnershipSectionWrapper>
  );
};
export default PatnershipSection;
