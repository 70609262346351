import styled from 'styled-components';
import { theme } from '../../../theme/theme';

export const LoginSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const LoginSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 5000px;
  width: 100%;
`;

//!left sectionstyles
export const LoginSectionLeftSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

export const LoginSectionFormArea = styled.form`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  & .error_text {
    font-size: 10px;
    padding: 10px;
    color: red;
  }
`;

export const ForgotPasswordContainer = styled.div`
  width: 360px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RememberMeContainer = styled.div`
  display: flex;
  gap: 7px;
  align-items: center;
`;

export const AuthPromptContainer = styled.div`
  width: 360px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

//! right section styles
export const LoginSectionRightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.lightBlue};
  overflow: hidden;
`;
