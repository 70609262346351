import React from 'react';
import { theme } from '../../theme/theme';
import { SpowseImage } from './reuseables/spowse_image';
import { CommonText } from './reuseables/text_styles';
import styled from 'styled-components';

type ActiveButtonProps = {
  icon: string;
  activeText: string;
  buttonText: string;
  type: string;
  onClick: any;
  width?: string;
};

export const Button = styled.button<{
  background: string;
  borderColor: string;
  textColor: string;
  width: string;
}>`
  display: flex;
  gap: 10px;
  width: ${(probs) => probs.width || "220px"};
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(probs) => probs.borderColor};
  background: ${(probs) => probs.background};
  color: ${(probs) => probs.textColor};
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
`;

const ActiveButton: React.FC<ActiveButtonProps> = ({ icon, activeText, buttonText, type, onClick, width }) => {

  return <Button
    background={
      type === activeText
        ? `${theme.colors.backgroundColor}`
        : `transparent`
    }
    textColor={
      type === activeText
        ? `${theme.colors.linkActiveTextColor}`
        : `${theme.colors.linkInActiveTextColor}`
    }
    borderColor={
      type === activeText
        ? `${theme.colors.borderColor}`
        : `transparent`
    }
    width={width || "220px"}
    onClick={onClick}
  >
    <SpowseImage
      src={icon}
      alt="icon"
      width="auto"
      color={`${type === activeText
        ? `${theme.colors.linkActiveTextColor}`
        : `${theme.colors.linkInActiveTextColor}`
        }`}
    />
    <CommonText
      fontSize="14px"
      fontWeight="500"
      lineHeight="20px"
      fontFamily="matter-medium"
      color={`${type === activeText
        ? `${theme.colors.linkActiveTextColor}`
        : `${theme.colors.linkInActiveTextColor}`
        }`}
    >
      {buttonText}
    </CommonText>
  </Button>
}
export default ActiveButton;