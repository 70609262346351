import React, { useState } from "react";
import {
  CampaignAndConversionRateContainer,
  CampaignListSearchbarContainer,
  CampaignListTableHeader,
  CampaignListTableHeaderText,
  CampaignListTableText,
  CampaignListWrapper,
  CampaignSectionWrapper,
  ConversionChartContainer,
  PaginationWrapper,
  SearchAndButtonContainer,
  TableTr,
  UserStatusContainer,
} from "./CampignSection_styles";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { CampaignChartContainer } from "../overview_section/Overview_styles";
import { theme } from "../../../../theme/theme";
import {
  campaignListData,
  campaignListHeaderText,
  conversionRaterChart,
  overviewCampaignChart,
} from "../../controller/campaign_config";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import { AppIcons } from "../../../../core/utilities/app_icons";
import ReactPaginate from "react-paginate";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import Modal from "../../../../core/common/reuseables/modal";
import { HeightGap } from "../../../../core/common/reuseables/gap";

type CampaignSectionProps = {};

const CampaignSection: React.FC<CampaignSectionProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const campaignsPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayCampaign = campaignListData.slice(
    currentPage * campaignsPerPage,
    (currentPage + 1) * campaignsPerPage
  );

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <CampaignSectionWrapper>
      <Modal title="New Campaign" isOpen={isModalOpen} onClose={closeModal}>
        <SpowseTextField
          label="Campaign Name"
          placeHolder="Enter campaign name"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Budget"
          placeHolder="N amount"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Target Audience"
          placeHolder="Choose audience"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            marginTop: "1.3rem",
          }}>
          <SpowseTextField
            label="Start Date"
            placeHolder="Enter start date"
            width="245px"
            height="45px"
            borderColor={`${theme.colors.borderColor}`}
            surfixIcon={<SpowseImage src="/assets/icons/calendar.svg" alt="icon" width="auto" />}
          />
          <SpowseTextField
            label="Enter Date"
            placeHolder="Enter end date"
            width="245px"
            height="45px"
            borderColor={`${theme.colors.borderColor}`}
            surfixIcon={<SpowseImage src="/assets/icons/calendar.svg" alt="icon" width="auto" />}

          />
        </div>
        <HeightGap height="1.3rem" />
        <SpowseButton
          width="100%"
          height= "50px"
          fontSize="14px"
          fontWeight="400"
          fontFamily="matter-light"
          onClick={openModal}
        >
          Create Campaign
        </SpowseButton>
      </Modal>
      <CampaignAndConversionRateContainer>
        <CampaignChartContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-bold"
          >
            Campaigns
          </CommonText>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={overviewCampaignChart} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis
                type="number"
                tickLine={false}
                axisLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-light"
              />
              <YAxis
                type="category"
                dataKey="name"
                tickLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-light"
              />
              <Tooltip cursor={false} />
              <Bar dataKey="pv" fill="#165DFF" barSize={7}></Bar>
            </BarChart>
          </ResponsiveContainer>
        </CampaignChartContainer>
        <ConversionChartContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-bold"
          >
            Conversion rate
          </CommonText>
          <CommonText fontSize="12px" fontWeight="400" color="#475467">
            Rate
          </CommonText>
          <ResponsiveContainer width="100%" height="90%">
            <LineChart data={conversionRaterChart}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" tickLine={false} fontSize="12px" />
              <YAxis
                axisLine={false}
                fontSize="12px"
                tickLine={false}
                domain={[0, 10000]}
              />
              <Tooltip />
              <Line
                type="monotone"
                dataKey="pv"
                stroke="#6B8BFF"
                strokeWidth={2}
                dot={false}
                fill="url(#colorPv)"
              />
            </LineChart>
          </ResponsiveContainer>
        </ConversionChartContainer>
      </CampaignAndConversionRateContainer>
      <CampaignListWrapper>
        <CampaignListSearchbarContainer>
          <CommonText
            fontSize="18px"
            color={`${theme.colors.linkActiveTextColor}`}
            fontWeight="500"
          >
            Campaign List
          </CommonText>
          <SearchAndButtonContainer>
            <SpowseTextField
              placeHolder="Search"
              width="320px"
              height="45px"
              borderColor="transparent"
              prefixIxon={
                <SpowseImage
                  src={AppIcons.searchIcon}
                  alt="search icon"
                  width="auto"
                />
              }
            />
            <SpowseButton
              width="135px"
              height="38px"
              fontSize="14px"
              fontWeight="400"
              fontFamily="matter-light"
              onClick={openModal}
            >
              New Campaign
            </SpowseButton>
          </SearchAndButtonContainer>
        </CampaignListSearchbarContainer>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "0",
            outline: "none",
            marginTop: "2rem",
          }}
        >
          <CampaignListTableHeader>
            <tr>
              {campaignListHeaderText.map((e, index) => {
                return (
                  <CampaignListTableHeaderText
                    key={index}
                    style={{
                      width: index === 0 ? "100px" : "auto",
                      paddingLeft: index === 0 ? "1.2rem" : "9px",
                    }}
                  >
                    {e}
                  </CampaignListTableHeaderText>
                );
              })}
            </tr>
          </CampaignListTableHeader>
          <tbody>
            {displayCampaign.map((row, index) => (
              <TableTr key={index}>
                <CampaignListTableText
                  style={{ width: "100px", paddingLeft: "1.2rem" }}
                >
                  {index + 1}
                </CampaignListTableText>
                <CampaignListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-regular"
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.name}
                  </CommonText>
                </CampaignListTableText>
                <CampaignListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.startDate}
                  </CommonText>
                </CampaignListTableText>
                <CampaignListTableText style={{ width: "130px" }}>
                  {row.endDate}
                </CampaignListTableText>
                <CampaignListTableText
                  style={{
                    width: "130px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {row.budget}
                </CampaignListTableText>
                <CampaignListTableText style={{ width: "130px" }}>
                  <UserStatusContainer status={row.performance}>
                    {row.performance}
                  </UserStatusContainer>
                </CampaignListTableText>
                <CampaignListTableText>
                  <UserStatusContainer status={row.status}>
                    {row.status}
                  </UserStatusContainer>
                </CampaignListTableText>

              </TableTr>
            ))}
          </tbody>
        </table>
        <PaginationWrapper>
          <ReactPaginate
            previousLabel={`🡠   Previous`}
            nextLabel={"Next 🡢"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(campaignListData.length / campaignsPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            nextClassName="n"
            activeClassName={"activeClass"}
          />
        </PaginationWrapper>
      </CampaignListWrapper>
    </CampaignSectionWrapper>
  );
};
export default CampaignSection;
