import React from "react";
import {
  Select,
  UserTypeContainer,
  UserTypeContainerFisrstRow,
  UserTypeIconContainer,
  UserTypeWrapper,
  Option,
  UserTypeContainerThirdRow,
  RateContainer,
} from "./UserType_styles";
import { userTypes } from "../../controller/dashboard_config";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

type UserTypeProps = {};

const UserType: React.FC<UserTypeProps> = () => {
  return (
    <UserTypeWrapper>
      {userTypes.map((userType, index) => {
        return (
          <UserTypeContainer>
            <UserTypeContainerFisrstRow>
              <UserTypeIconContainer background={userType.iconBackgroundColor}>
                <SpowseImage src={userType.icon} alt="img" width="auto" />
              </UserTypeIconContainer>
              <Select id="options" name="options">
                <Option value="This month">This month</Option>
                <Option value="Today">Today</Option>
                <Option value="7 day ago">7 day ago</Option>
                <Option value="Last month">Last month</Option>
              </Select>
            </UserTypeContainerFisrstRow>
            <Heading1
              fontFamily="matter-bold"
              fontWeight="600"
              color={`${theme.colors.linkActiveTextColor}`}
              fontSize="20px"
              lineHeight="30px"
            >
              {userType.userCount}
            </Heading1>
            <UserTypeContainerThirdRow>
              <CommonText
                fontFamily="matter-medium"
                fontWeight="500"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontSize="14px"
                lineHeight="20px"
              >
                {userType.userType}
              </CommonText>
              <RateContainer>
                <CommonText
                  fontFamily="matter-medium"
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="18px"
                  color="#0F973D"
                >
                  {userType.rate}
                </CommonText>
              </RateContainer>
            </UserTypeContainerThirdRow>
          </UserTypeContainer>
        );
      })}
    </UserTypeWrapper>
  );
};
export default UserType;
