import React from "react";
import { Routes, Route } from "react-router-dom";
import Dashboard from "../presentations/dashboard/Dashboard";
import Login from "../presentations/Authentication/login/Login";
import VerifyLogin from "../presentations/Authentication/verification/login_verification/VerifyLogin";
import CreateAccount from "../presentations/Authentication/create_account/CreateAccount";
import EmailVerification from "../presentations/Authentication/verification/email_verification/EmailVerification";
import VerifySignUp from "../presentations/Authentication/verification/signup_verification/VerifySignUp";
import AccountCreated from "../presentations/Authentication/create_account/components/Account_created/AccountCreated";
import UserManagement from "../presentations/User_management/UserManageMent";
import Subscription from "../presentations/subscriptions/Subscription";
import UserLogs from "../presentations/User_logs/UserLogs";
import Campaigns from "../presentations/campaigns/Campaigns";
import Revenue from "../presentations/revenue/Revenue";
import Marketing from "../presentations/marketing/Marketing";
import Blog from "../presentations/blog/Blog";
import Settings from "../presentations/settings/Settings";
import RequestVerificationLink from "../presentations/Authentication/request_verification_link/RequestVerificationLink";

type RoutePathsProps = {};

const RoutePaths: React.FC<RoutePathsProps> = () => {
  return (
    <Routes>
      <Route path="/" element={<Dashboard />} />
      <Route path="/login" element={<Login />} />
      <Route path="/verify_login" element={<VerifyLogin />} />
      <Route path="/create_account" element={<CreateAccount />} />
      <Route path="/verify_email" element={<EmailVerification />} />
      <Route path="/auth/verify" element={<VerifySignUp />} />
      <Route path="/account_created" element={<AccountCreated />} />
      <Route path="/request_verification_link" element={<RequestVerificationLink />} />
      <Route path="/user_management" element={<UserManagement />} />
      <Route path="/subscription" element={<Subscription />} />
      <Route path="/user_logs" element={<UserLogs />} />
      <Route path="/campaigns" element={<Campaigns />} />
      <Route path="/revenue" element={<Revenue />} />
      <Route path="/marketing" element={<Marketing />} />
      <Route path="/blog_post" element={<Blog />} />
      <Route path="settings" element={<Settings />} />
    </Routes>
  );
};
export default RoutePaths;
