import styled from "styled-components";

interface DividerProbs {
  height?: string;
  backgroundColor?: any;
  width?: string;
  spacing?: string;
}

export const Divider = styled.div<DividerProbs>`
  width: ${(probs) => probs.width};
  height: ${(probs) => probs.height};
  background: ${(probs) => probs.backgroundColor || "rgba(203, 209, 206, 1)"};
  margin: ${(probs) => probs.spacing};
`;
