import styled from "styled-components";
import { theme } from "../../theme/theme";

export const MarketingWrapper = styled.section`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
`;

export const MarketingContentWrapper = styled.div`
  width: ${theme.widths.contentWidth};
  padding-left: 0.7rem;
  padding-right: 1.2rem;
  margin-top: 6rem;
  margin-bottom: 2rem;

  @media (min-width: 1300px){
    width: 83%;
  }
`;

export const MarketingContent = styled.div`
  width: 100%;
  background: ${theme.colors.white};
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  box-shadow: ${theme.boxShadows.contentWrapperBoxShadow};
`;


export const MarketingHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 1rem 1.5rem;
`;

export const MarketingHeaderButton = styled.button<{
  background: string;
  borderColor: string;
  textColor: string;
}>`
  display: flex;
  gap: 10px;
  width: 220px;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: center;
  border: 2px solid ${(probs) => probs.borderColor};
  background: ${(probs) => probs.background};
  color: ${(probs) => probs.textColor};
  border-radius: 5px;
  transition: all 300ms ease-in-out;
  cursor: pointer;
`;
