import React, { useState } from "react";
import {
  ActivationCancellationContainer,
  ColoredContainer,
  CustomerGrowthContainer,
  OptionContainer,
  PaginationWrapper,
  SubscribersSearchbarContainer,
  SubscribersTableHeader,
  SubscribersTableHeaderText,
  SubscribersTableText,
  SubscribersWrapper,
  SubscriptionLeftSectionWrapper,
  TableTr,
  UserImageContainer,
  UserOptionContainer,
  UserProfilePicsAndNameContainer,
  ValueContainer,
  ValueTextContainer,
} from "./Subscription_left_section_styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  BarChart,
  Bar,
} from "recharts";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../../../core/utilities/app_icons";
import { userData } from "../../../User_management/controller/user_management_config";
import ReactPaginate from "react-paginate";
import { userListHeaderText } from "../../controllers/subscription_config";

type SubscriptionLeftSectionProps = {};

const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 5300, amt: 2100 },
  { name: "Aug", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Sep", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Oct", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Nov", uv: 3490, pv: 4100, amt: 2100 },
  { name: "Dec", uv: 3490, pv: 7300, amt: 2100 },
];

const SubscriptionLeftSection: React.FC<SubscriptionLeftSectionProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const usersPerPage = 5;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayUsers = userData.slice(
    currentPage * usersPerPage,
    (currentPage + 1) * usersPerPage
  );

  const getInitials = (name: string) => {
    const nameParts = name.split(" ");

    if (nameParts.length > 1) {
      return `${nameParts[0][0]}${nameParts[1][0]}`;
    } else {
      return nameParts[0].substring(0, 2);
    }
  };

  return (
    <SubscriptionLeftSectionWrapper>
      <ActivationCancellationContainer>
        <CommonText
          fontFamily="matter-bold"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="600"
          fontSize="16px"
        >
          Activation Vs Net cancellation
        </CommonText>
        <ValueContainer>
          <ValueTextContainer>
            <ColoredContainer backgroundColor="#569AD9" />
            <CommonText
              fontFamily="matter-regular"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontWeight="400"
              fontSize="14px"
            >
              Activation
            </CommonText>
          </ValueTextContainer>
          <ValueTextContainer>
            <ColoredContainer backgroundColor="#92C0F6" />
            <CommonText
              fontFamily="matter-regular"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontWeight="400"
              fontSize="14px"
            >
              Net cancellation
            </CommonText>
          </ValueTextContainer>
        </ValueContainer>
        <ResponsiveContainer width="100%" height="70%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 5,
              left: 1,
              right: 1,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              tickLine={false}
              fontSize="12px"
              padding={{ left: 0 }}
            />
            <YAxis
              axisLine={false}
              fontSize="12px"
              tickLine={false}
              domain={["auto", "auto"]}
              width={35}
            />
            <Tooltip />
            {/* <Legend /> */}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#0f0f12"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#569AD9"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#92C0F6"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
          </LineChart>
        </ResponsiveContainer>
        <ValueContainer marginTop="0" justifyContent="center">
          <CommonText
            fontFamily="Inter"
            color={`${theme.colors.linkInActiveTextColor}`}
            fontWeight="600"
            fontSize="12px"
          >
            Month
          </CommonText>
        </ValueContainer>
      </ActivationCancellationContainer>
      {/* customer growth */}
      <CustomerGrowthContainer>
        <CommonText
          fontFamily="matter-medium"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="500"
          fontSize="20px"
        >
          Customer Growth
        </CommonText>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" fontSize="12px" tickLine={false} />
            <YAxis
              fontSize="12px"
              tickLine={false}
              axisLine={false}
              width={35}
            />
            <Tooltip cursor={false} />
            <Bar dataKey="pv" fill="#165DFF" barSize={30} />
          </BarChart>
        </ResponsiveContainer>
      </CustomerGrowthContainer>
      <SubscribersWrapper>
        <SubscribersSearchbarContainer>
          <CommonText
            fontSize="18px"
            color={`${theme.colors.linkActiveTextColor}`}
            fontWeight="500"
          >
           Subscribers
          </CommonText>
          <SpowseTextField
            placeHolder="Search"
            width="320px"
            height="45px"
            borderColor="transparent"
            prefixIxon={
              <SpowseImage
                src={AppIcons.searchIcon}
                alt="search icon"
                width="auto"
              />
            }
          />
        </SubscribersSearchbarContainer>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "0",
            outline: "none",
            marginTop: "2rem",
          }}
        >
          <SubscribersTableHeader>
            <tr>
              {userListHeaderText.map((e, index) => {
                return (
                  <SubscribersTableHeaderText key={index}>
                    {e}
                  </SubscribersTableHeaderText>
                );
              })}
            </tr>
          </SubscribersTableHeader>
          <tbody>
            {displayUsers.map((row, index) => (
              <TableTr key={index}>
                <SubscribersTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-regular"
                  >
                    {index + 1}
                  </CommonText>
                </SubscribersTableText>
                <SubscribersTableText>
                  <UserProfilePicsAndNameContainer>
                    <UserImageContainer>
                      {getInitials(row.name)}
                    </UserImageContainer>
                    <CommonText
                      fontWeight="400"
                      fontSize=" 14px"
                      color={`${theme.colors.linkActiveTextColor}`}
                      fontFamily="matter-regular"
                      style={{
                        width: "70px",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {row.name}
                    </CommonText>
                  </UserProfilePicsAndNameContainer>
                </SubscribersTableText>
                <SubscribersTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "70px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.email}
                  </CommonText>
                </SubscribersTableText>
                <SubscribersTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "70px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.phone}
                  </CommonText>
                </SubscribersTableText>
                <SubscribersTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "60px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.country}
                  </CommonText>
                </SubscribersTableText>
                <SubscribersTableText
                  style={{
                    width: "60px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {row.plan}
                </SubscribersTableText>
                {/*  */}
                <SubscribersTableText>
                  <UserOptionContainer>
                    <OptionContainer>
                      <SpowseImage
                        src={AppIcons.optionIcon}
                        alt="img"
                        width="auto"
                      />
                    </OptionContainer>
                  </UserOptionContainer>
                </SubscribersTableText>
              </TableTr>
            ))}
          </tbody>
        </table>
        <PaginationWrapper>
          <ReactPaginate
            previousLabel={`🡠   Previous`}
            nextLabel={"Next 🡢"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(userData.length / usersPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            nextClassName="n"
            activeClassName={"activeClass"}
          />
        </PaginationWrapper>
      </SubscribersWrapper>
    </SubscriptionLeftSectionWrapper>
  );
};
export default SubscriptionLeftSection;
