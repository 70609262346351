import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  BarChart,
  Bar,
  LabelList,
} from "recharts";
import {
  DeviceInforSection,
  DeviceOsBarChartContainer,
  DeviceOsContainer,
  VisitorsAndDeviceWrapper,
  VisitorsSection,
} from "./VisitorsAndDevice_styles";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { Divider } from "../../../../core/common/reuseables/Divider";

const data = [
  { name: "Jan", uv: 4000, pv: 2400, amt: 2400 },
  { name: "Feb", uv: 3000, pv: 1398, amt: 2210 },
  { name: "Mar", uv: 2000, pv: 800, amt: 2290 },
  { name: "Apr", uv: 2780, pv: 3908, amt: 2000 },
  { name: "May", uv: 1890, pv: 4800, amt: 2181 },
  { name: "Jun", uv: 2390, pv: 3800, amt: 2500 },
  { name: "Jul", uv: 3490, pv: 5300, amt: 2100 },
  { name: "Aug", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Sep", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Oct", uv: 3490, pv: 4300, amt: 2100 },
  { name: "Nov", uv: 3490, pv: 4100, amt: 2100 },
  { name: "Dec", uv: 3490, pv: 7300, amt: 2100 },
];

const data1 = [
  { name: "Android", uv: 4000, pv: 500400, amt: 2800 },
  { name: "iOS", uv: 3000, pv: 300398, amt: 2210 },
];

const VisitorsAndDevice: React.FC = () => {
  const formatValue = (value: number) => {
    if (value >= 1_000_000_000) {
      return `${(value / 1_000_000_000).toFixed(1)}B`;
    } else if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}k`;
    }
    return value.toString();
  };
  return (
    <VisitorsAndDeviceWrapper>
      <VisitorsSection>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Visitors
        </CommonText>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          unit
        </CommonText>
        <ResponsiveContainer width="100%" height="70%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tickLine={false} fontSize="12px" />
            <YAxis axisLine={false} fontSize="12px" tickLine={false} />
            <Tooltip />
            {/* <Legend /> */}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#0f0f12"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#6B8BFF"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
          </LineChart>
        </ResponsiveContainer>
      </VisitorsSection>
      <DeviceInforSection>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Active users by OS/Device Type
        </CommonText>
        <Divider
          width="100%"
          height="1px"
          backgroundColor={`${theme.colors.borderColor}`}
        />
        <DeviceOsBarChartContainer>
          <DeviceOsContainer>
            <CommonText
              fontSize="14px"
              fontWeight="400"
              color={`${theme.colors.linkActiveTextColor}`}
              lineHeight="22px"
            >
              Android
            </CommonText>
            <CommonText
              fontSize="14px"
              fontWeight="400"
              color={`${theme.colors.linkActiveTextColor}`}
              lineHeight="22px"
            >
              iOS
            </CommonText>
          </DeviceOsContainer>
          <ResponsiveContainer width="70%" height="100%">
            <BarChart data={data1} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis
                type="number"
                domain={[0, 1000000]}
                tickFormatter={formatValue}
                tickLine={false}
                axisLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-regular"
              />
              <YAxis type="category" dataKey="name" hide tickLine={false} />
              <Tooltip cursor={false}/>
              <Bar dataKey="pv" fill="#6B8BFF" barSize={56}>
                <LabelList
                  dataKey="pv"
                  position="right"
                  formatter={formatValue}
                  fontSize="14px"
                  fontWeight="400"
                  color={`${theme.colors.linkActiveTextColor}`}
                  fontFamily="matter-regular"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </DeviceOsBarChartContainer>
        <CommonText
          style={{
            marginLeft: "30%",
            marginTop: "10px",
          }}
          fontSize="14px"
          fontWeight="400"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-regular"
        >
          Percentage of Active Users
        </CommonText>
      </DeviceInforSection>
    </VisitorsAndDeviceWrapper>
  );
};

export default VisitorsAndDevice;
