export const blogHeaderText = [
  'No',
  'Title',
  'Publish Date',
  'Categories',
  'Author',
  'Tags',
  '',
];

export const blogs = [
  {
    title: 'Dating',
    publishDate: '12/12/2015',
    categories: 'Love',
    author: 'Hozier',
    tag: 'Dating',
  },
  {
    title: 'Travel Tips',
    publishDate: '01/05/2016',
    categories: 'Travel',
    author: 'John Doe',
    tag: 'Travel',
  },
  {
    title: 'Tech Innovations',
    publishDate: '03/15/2017',
    categories: 'Technology',
    author: 'Jane Smith',
    tag: 'Tech',
  },
  {
    title: 'Healthy Living',
    publishDate: '07/22/2018',
    categories: 'Health',
    author: 'Emily Brown',
    tag: 'Health',
  },
  {
    title: 'Gourmet Recipes',
    publishDate: '10/10/2019',
    categories: 'Food',
    author: 'Michael Johnson',
    tag: 'Food',
  },
  {
    title: 'Fitness Goals',
    publishDate: '11/25/2020',
    categories: 'Fitness',
    author: 'Olivia Davis',
    tag: 'Fitness',
  },
  {
    title: 'Finance 101',
    publishDate: '05/03/2021',
    categories: 'Finance',
    author: 'Chris Wilson',
    tag: 'Finance',
  },
  {
    title: 'Photography Tips',
    publishDate: '08/18/2021',
    categories: 'Photography',
    author: 'Sophia Taylor',
    tag: 'Photography',
  },
  {
    title: 'DIY Crafts',
    publishDate: '12/09/2022',
    categories: 'DIY',
    author: 'Emma Martinez',
    tag: 'DIY',
  },
  {
    title: 'Mindfulness Techniques',
    publishDate: '03/12/2023',
    categories: 'Wellness',
    author: 'James Anderson',
    tag: 'Wellness',
  },
  {
    title: 'Adventure Sports',
    publishDate: '06/30/2023',
    categories: 'Sports',
    author: 'Ava Wilson',
    tag: 'Sports',
  },
  {
    title: 'Modern Architecture',
    publishDate: '09/05/2023',
    categories: 'Architecture',
    author: 'Liam Thompson',
    tag: 'Architecture',
  }
];

