import React from "react";
import styled from "styled-components";
import { MdOutlineError } from "react-icons/md";
import { CommonText, Heading1 } from "./reuseables/text_styles";
import useMediaQuery from "./use_media_Query";

type NotSupportedScreenProps = {};

const NotSupportedScreenWrapper = styled.section`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NotSupportedScreenContent = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: white;
  height: 50%;
  width: 80%;
  justify-content: center;
  gap: 10px;
  border-radius: 35px;

  & .error_icon {
    width: 100px;
    height: 100px;

    @media (max-width: 451px) {
      width: 50px;
      height: 50px;
    }
  }

  @media (max-width: 451px) {
    width: 90%;
  }
`;

const NotSupportedScreen: React.FC<NotSupportedScreenProps> = () => {
  const media = useMediaQuery("(max-width: 739px)");
  return (
    <NotSupportedScreenWrapper>
      <NotSupportedScreenContent>
        <MdOutlineError className="error_icon" />
        <Heading1 fontSize={media ? "18px" : "30px"}>
          This screen is not supported yet.
        </Heading1>
        <CommonText>Please switch to a larger screen.</CommonText>
      </NotSupportedScreenContent>
    </NotSupportedScreenWrapper>
  );
};
export default NotSupportedScreen;
