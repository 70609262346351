import { styled } from 'styled-components';
import { theme } from '../../../../theme/theme';

export const PermissionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1.6rem 1.5rem;
`;

export const PermissionRoleSelectorContainer = styled.div`
  border: 1px solid #d0d5dd;
  background: #f9fafb;
  width: 100%;
  padding: 1rem;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
export const EditPermissionWrapper = styled.div`
  width: 100%;
  border: 1px solid ${theme.colors.borderColor};
  margin-top: 1.5rem;
  padding: 1.5rem 0;
  border-radius: 7px;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    padding-left: 1rem;
    padding-bottom: 1rem;
    font-family: 'matter-medium';
    font-size: 12px;
  }
`;

export const SetPermissionWrapper = styled.div`
  width: 95%;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-self: center;
  justify-self: center;
  margin-top: 1.5rem;
  border-radius: 7px;
`;

export const SetPermissionLeftSection = styled.div`
  width: 75%;
  display: flex;
  flex-direction: column;
`;

export const SetPermissionRightSection = styled.div`
  width: 25%;
  display: flex;
  flex-direction: column;
`;

export const ModulesContainer = styled.div<{ index: number }>`
  background: ${(probs) => (probs.index === 0 ? '#F0F2F5' : '#F8FAFC')};
  border-bottom: 1px solid
    ${(probs) => (probs.index === 6 ? 'white' : '#d0d5dd')};
  padding-left: 1.5rem;
  height: ${(probs) => (probs.index === 0 ? '45px' : '72px')};
  display: flex;
  align-items: center;
  border-top-left-radius: ${(probs) => (probs.index === 0 ? '7px' : '0')};
  border-bottom-left-radius: ${(probs) => (probs.index === 6 ? '7px' : '0')};
`;

export const EditToggleContainer = styled.div<{ index: number }>`
  background: ${(probs) => (probs.index === 0 ? '#F0F2F5' : 'white')};
  border-bottom: 1px solid
    ${(probs) => (probs.index === 6 ? 'white' : '#d0d5dd')};
  padding-left: 1.5rem;
  height: ${(probs) => (probs.index === 0 ? '45px' : '72px')};
  display: flex;
  align-items: center;
  border-top-right-radius: ${(probs) => (probs.index === 0 ? '7px' : '0')};
  border-bottom-right-radius: ${(probs) => (probs.index === 6 ? '7px' : '0')};
`;
