export const userLogsHeaderText = [
  "No",
  "Name",
  "Email",
  "Activity",
  "Date",
  "Authorized",
  "Status",
  "",
];

export const userLogsData = [
  {
    name: "Ayo Smith",
    image: "",
    activity: "User Login",
    email: "ayosmith@gmail.com",
    authorized: "Authorized",
    date: "12/02/2024",
    status: "success",
  },
  {
    name: "Jane Doe",
    image: "",
    activity: "User Login",
    email: "janedoe@gmail.com",
    authorized: "Unauthorized",
    date: "12/03/2024",
    status: "false",
  },
  {
    name: "John Johnson",
    image: "",
    activity: "User Login",
    email: "johnjohnson@gmail.com",
    authorized: "Authorized",
    date: "12/04/2024",
    status: "success",
  },
  {
    name: "Tom Brown",
    image: "",
    activity: "User Login",
    email: "tombrown@gmail.com",
    authorized: "Unauthorized",
    date: "12/05/2024",
    status: "false",
  },
  {
    name: "Sara Davis",
    image: "",
    activity: "User Login",
    email: "saradavis@gmail.com",
    authorized: "Authorized",
    date: "12/06/2024",
    status: "success",
  },
  {
    name: "Mike Miller",
    image: "",
    activity: "User Login",
    email: "mikemiller@gmail.com",
    authorized: "Unauthorized",
    date: "12/07/2024",
    status: "false",
  },
  {
    name: "Alice Wilson",
    image: "",
    activity: "User Login",
    email: "alicewilson@gmail.com",
    authorized: "Authorized",
    date: "12/08/2024",
    status: "success",
  },
  {
    name: "Bob Moore",
    image: "",
    activity: "User Login",
    email: "bobmoore@gmail.com",
    authorized: "Unauthorized",
    date: "12/09/2024",
    status: "false",
  },
  {
    name: "Nancy Taylor",
    image: "",
    activity: "User Login",
    email: "nancytaylor@gmail.com",
    authorized: "Authorized",
    date: "12/10/2024",
    status: "success",
  },
  {
    name: "Chris Anderson",
    image: "",
    activity: "User Login",
    email: "chrisanderson@gmail.com",
    authorized: "Unauthorized",
    date: "12/11/2024",
    status: "false",
  },
  {
    name: "Emma Thompson",
    image: "",
    activity: "User Login",
    email: "emmathompson@gmail.com",
    authorized: "Authorized",
    date: "12/12/2024",
    status: "success",
  },
  {
    name: "Liam Martinez",
    image: "",
    activity: "User Login",
    email: "liammartinez@gmail.com",
    authorized: "Unauthorized",
    date: "12/13/2024",
    status: "false",
  },
  {
    name: "Olivia Robinson",
    image: "",
    activity: "User Login",
    email: "oliviarobinson@gmail.com",
    authorized: "Authorized",
    date: "12/14/2024",
    status: "success",
  },
  {
    name: "Noah Clark",
    image: "",
    activity: "User Login",
    email: "noahclark@gmail.com",
    authorized: "Unauthorized",
    date: "12/15/2024",
    status: "false",
  },
  {
    name: "Ava Rodriguez",
    image: "",
    activity: "User Login",
    email: "avarodriguez@gmail.com",
    authorized: "Authorized",
    date: "12/16/2024",
    status: "success",
  },
  {
    name: "William Lewis",
    image: "",
    activity: "User Login",
    email: "williamlewis@gmail.com",
    authorized: "Unauthorized",
    date: "12/17/2024",
    status: "false",
  },
  {
    name: "Sophia Lee",
    image: "",
    activity: "User Login",
    email: "sophialee@gmail.com",
    authorized: "Authorized",
    date: "12/18/2024",
    status: "success",
  },
  {
    name: "James Walker",
    image: "",
    activity: "User Login",
    email: "jameswalker@gmail.com",
    authorized: "Unauthorized",
    date: "12/19/2024",
    status: "false",
  },
  {
    name: "Isabella Hall",
    image: "",
    activity: "User Login",
    email: "isabellahall@gmail.com",
    authorized: "Authorized",
    date: "12/20/2024",
    status: "success",
  },
  {
    name: "Benjamin Allen",
    image: "",
    activity: "User Login",
    email: "benjaminallen@gmail.com",
    authorized: "Unauthorized",
    date: "12/21/2024",
    status: "false",
  },
  {
    name: "Mia Young",
    image: "",
    activity: "User Login",
    email: "miayoung@gmail.com",
    authorized: "Authorized",
    date: "12/22/2024",
    status: "success",
  },
  {
    name: "Lucas Hernandez",
    image: "",
    activity: "User Login",
    email: "lucashernandez@gmail.com",
    authorized: "Unauthorized",
    date: "12/23/2024",
    status: "false",
  },
  {
    name: "Charlotte King",
    image: "",
    activity: "User Login",
    email: "charlotteking@gmail.com",
    authorized: "Authorized",
    date: "12/24/2024",
    status: "success",
  },
  {
    name: "Henry Wright",
    image: "",
    activity: "User Login",
    email: "henrywright@gmail.com",
    authorized: "Unauthorized",
    date: "12/25/2024",
    status: "false",
  },
  {
    name: "Amelia Lopez",
    image: "",
    activity: "User Login",
    email: "amelialopez@gmail.com",
    authorized: "Authorized",
    date: "12/26/2024",
    status: "success",
  },
  {
    name: "Alexander Hill",
    image: "",
    activity: "User Login",
    email: "alexanderhill@gmail.com",
    authorized: "Unauthorized",
    date: "12/27/2024",
    status: "false",
  },
  {
    name: "Harper Scott",
    image: "",
    activity: "User Login",
    email: "harperscott@gmail.com",
    authorized: "Authorized",
    date: "12/28/2024",
    status: "success",
  },
  {
    name: "Ethan Green",
    image: "",
    activity: "User Login",
    email: "ethangreen@gmail.com",
    authorized: "Unauthorized",
    date: "12/29/2024",
    status: "false",
  },
  {
    name: "Evelyn Adams",
    image: "",
    activity: "User Login",
    email: "evelynadams@gmail.com",
    authorized: "Authorized",
    date: "12/30/2024",
    status: "success",
  },
  {
    name: "Daniel Baker",
    image: "",
    activity: "User Login",
    email: "danielbaker@gmail.com",
    authorized: "Unauthorized",
    date: "12/31/2024",
    status: "false",
  },
];
