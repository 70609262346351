import styled from "styled-components";
import { theme } from "../../../../../theme/theme";

export const AccountCreatedSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const AccountCreatedSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 5000px;
  width: 100%;
`;

//!left sectionstyles
export const AccountCreatedSectionLeftSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

export const AccountCreatedSectionFormArea = styled.div`
  width: 42%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;


//! right section styles
export const AccountCreatedSectionRightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.lightBlue};
  overflow: hidden;
`;
