import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export interface CampaignState {
  campaignType: string;
}

const initialState: CampaignState = {
  campaignType: "overview",
};

export const CampaignSlice = createSlice({
  name: "campaign",
  initialState,
  reducers: {
    setCampaignTypeState: (state, action: PayloadAction<string>) => {
      state.campaignType = action.payload;
    },
  },
});

export const { setCampaignTypeState } = CampaignSlice.actions;

export default CampaignSlice.reducer;
