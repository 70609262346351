import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const ActiveUserWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2rem 0;
`;

export const ActiveUserLeftSection = styled.div`
  width: 49%;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1.2rem;
  border-radius: 10px;

  & > :nth-child(2) {
    margin-top: 2rem;
    margin-left: 2rem;
    margin-bottom: 10px;
  }
`;

export const ActiveUserLeftFirstSection = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const BarChartContainer = styled.div`
  width: 100%;
  height: 340px;
`;

export const Select = styled.select`
  font-size: 20px;
  font-weight: 500;
  font-family: "matter-medium";
  line-height: 28px;
  color: ${theme.colors.linkActiveTextColor};
  width: 110px;
  outline: none;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 5px;
  height: 40px;
`;

//right section styles
export const ActiveUserRightSection = styled.div`
  width: 49%;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1.2rem;
  border-radius: 10px;

  & > :nth-child(2) {
    margin-top: 2rem;
    margin-left: 10px;
    margin-bottom: 10px;
  }
`;
