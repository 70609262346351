export const theme = {
  colors: {
    primary: "rgba(0, 91, 54, 1)",
    secondary: "rgba(9, 152, 56, 1)",
    black: "#131110",
    white: "#ffffff",
    blueGradient: "linear-gradient(97.05deg, #10281d 23%, #ffffff 31%)",
    orangeGradient: "linear-gradient(270deg, #ffb978 0%, #ff922d 100%)",
    lightGreen: "#7bf0a2",
    lightGreen1: "rgba(9, 152, 56, 1)",
    blue: "#4066ff",
    lightBlue: "rgba(226, 238, 232, 1)",
    shadow: "0px 23px 21px -8px rgba(136, 160, 255, 0.25)",
    offWhite: "rgba(242, 242, 242, 1)",
    borderColor: "#EAECF0",
    hintColor: "rgba(102, 112, 133, 1)",
    lightGrey: "rgba(71, 84, 103, 1)",
    veryDarkGrey: "rgba(52, 64, 84, 1)",
    backgroundColor: "rgba(246, 247, 249, 1)",
    linkActiveTextColor: "rgba(16, 24, 40, 1)",
    linkInActiveTextColor: "rgba(52, 64, 84, 1)",
    linkActiveBorderColor: "rgba(208, 213, 221, 1)",
  },
  boxShadows: {
    primaryBoxShadow: "2px 2px 5px rgba(16, 24, 40, 0.05)",
    contentWrapperBoxShadow: "-2px -2px 10px rgba(227, 227, 227, 0.25)",
  },

  widths: {
    contentWidth: "79%",
  },
};
