export const userTypes = [
  {
    iconBackgroundColor: "#FEF4E6",
    userCount: "100,000",
    userType: "Total Users",
    rate: "+5%",
    icon: "/assets/icons/people.svg",
  },
  {
    iconBackgroundColor: "#EBE6FE",
    userCount: "80,000",
    userType: "Active Users",
    rate: "+5%",
    icon: "/assets/icons/actUser.svg",
  },
  {
    iconBackgroundColor: "#E6F0FE",
    userCount: "70,000",
    userType: "Free Users",
    rate: "+5%",
    icon: "/assets/icons/user-minus.svg",
  },

  {
    iconBackgroundColor: "#E7F6EC",
    userCount: "30,000",
    userType: "Paid Users",
    rate: "+5%",
    icon: "/assets/icons/doller_sign.svg",
  },
];
