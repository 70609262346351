import React, { useState } from "react";
import {
  AuthPromptContainer,
  CreateAccountSectionContent,
  CreateAccountSectionFormArea,
  CreateAccountSectionLeftSection,
  CreateAccountSectionRightSection,
  CreateAccountSectionWrapper,
} from "./CreateAccount_styles";

import { SpowseImage } from "../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../core/utilities/app_images";
import {
  CommonText,
  Heading1,
} from "../../../core/common/reuseables/text_styles";
import { theme } from "../../../theme/theme";
import SpowseTextField from "../../../core/common/reuseables/spowse_textfield";
import { HeightGap } from "../../../core/common/reuseables/gap";
import { SpowseButton } from "../../../core/common/reuseables/spowse_button";
import { useNavigate } from "react-router-dom";
import { FormValues, getUserByEmail, initialValues, validationSchema } from "../controller/controller";
import { useFormik } from "formik";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SpinnerLoader from "../../../core/common/reuseables/spinner_loader";
import ApiService from "../../../core/api/api";
import { OnboardAdminData, OnboardAdminReponse } from "../../../logic/responnse/onboard_admin_response";
import { ApiUrl } from "../../../core/api/api_url";

type CreateAccountProps = {};

const CreateAccount: React.FC<CreateAccountProps> = () => {
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [success, setSucess] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const apiService = new ApiService();


  const formik = useFormik<FormValues>({
    initialValues,
    validationSchema,
    onSubmit: async (values: FormValues) => {
      try {
        setIsLoading(true);
        const userEmail = localStorage.getItem("user_email");
        await getUserByEmail(userEmail ?? "", setSucess).then(async (user) => {
          if (success) {
            const data: OnboardAdminData = {
              firstname: values.firstname,
              lastname: values.lastname,
              password: values.password,
            }

            const headers = {
              "x-registration-token": user?.token,
            }
            await apiService.postData<OnboardAdminData, OnboardAdminReponse>(ApiUrl.onnboardAdmin, data, headers).then((response) => {
              if (response.data?.success === true) {
                setIsLoading(false);
                navigate("/account_created", { replace: true });
              } else {
                setIsLoading(false);
                toast.error(response.message);
              }
            })
          } else {
            setIsLoading(false);
            toast.error("an error occurred while retrieving user data!");
            return;
          }
        });
      } catch (e) {
        setIsLoading(false);
        toast.error("Sorry an error occurred!");
      }

    },
  });


  return (
    <CreateAccountSectionWrapper>
      {isLoading && <SpinnerLoader />}
      <CreateAccountSectionContent>
        <CreateAccountSectionLeftSection>
          <CreateAccountSectionFormArea onSubmit={formik.handleSubmit}>

            <Heading1
              fontSize="30px"
              fontWeight="600"
              fontFamily="matter-bold"
              lineHeight="38px"
              style={{ paddingTop: "6rem" }}
            >
              Create Account
            </Heading1>
            <HeightGap height="10px" />
            <CommonText
              fontSize="16px"
              fontWeight="400"
              fontFamily="matter-light"
              lineHeight="24px"
              color={`${theme.colors.lightGrey}`}
            >
              Sign Up to spowse
            </CommonText>
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="Enter your first name"
              label="First Name"
              type="text"
              id="firstname"
              name="firstname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.firstname}
            />
            {formik.touched.firstname && formik.errors.firstname ? (
              <div className="error_text">{formik.errors.firstname}</div>
            ) : null}
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="Enter your last name"
              label="Last Name"
              type="text"
              id="lastname"
              name="lastname"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lastname}
            />
            {formik.touched.lastname && formik.errors.lastname ? (
              <div className="error_text">{formik.errors.lastname}</div>
            ) : null}
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="•  •  •  •  •  •  •  •"
              label="Password"
              type={password ? "text" : "password"}
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              surfixIcon={password ? <AiOutlineEyeInvisible
                className="icon"
                onClick={() => {
                  setPassword((probs) => probs = !probs)
                }} /> : <MdOutlineVisibility
                className="icon"
                onClick={() => {
                  setPassword((probs) => probs = !probs)
                }} />}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_text">{formik.errors.password}</div>
            ) : null}
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="•  •  •  •  •  •  •  •"
              label="Confirm Password"
              type={confirmPassword ? "text" : "password"}
              id="confirmPassword"
              name="confirmPassword"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.confirmPassword}
              surfixIcon={confirmPassword ? <AiOutlineEyeInvisible
                className="icon"
                onClick={() => {
                  setConfirmPassword((probs) => probs = !probs)
                }} /> : <MdOutlineVisibility
                className="icon"
                onClick={() => {
                  setConfirmPassword((probs) => probs = !probs)
                }} />}
            />
            {formik.touched.confirmPassword && formik.errors.confirmPassword ? (
              <div className="error_text">{formik.errors.confirmPassword}</div>
            ) : null}
            <HeightGap height="40px" />

            <SpowseButton
              width="360px"
              height="50px"
              backgroundColor={`${theme.colors.primary}`}
              fontSize="18px"
              fontWeight="400"
              lineHeight="20px"
              fontFamily="matter-light"
              textColor={`${theme.colors.white}`}
              type="submit"
            // onClick={() => {
            //   navigate("/account_created", { replace: true });
            // }}
            >
              Sign Up
            </SpowseButton>

            <HeightGap height="20px" />
            <AuthPromptContainer>
              <CommonText
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px"
                fontFamily="matter-light"
                color={`${theme.colors.lightGrey}`}
              >
                Already have an account?
              </CommonText>
              <SpowseButton
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                textColor={`${theme.colors.primary}`}
                width="auto"
                height="auto"
                backgroundColor="transparent"
                onClick={() => {
                  navigate("/login", { replace: true });
                }}
              >
                Log in
              </SpowseButton>
            </AuthPromptContainer>
          </CreateAccountSectionFormArea>
        </CreateAccountSectionLeftSection>
        <CreateAccountSectionRightSection>
          <SpowseImage src={AppImages.welcome} alt="welcome-image" />
        </CreateAccountSectionRightSection>
      </CreateAccountSectionContent>
    </CreateAccountSectionWrapper>
  );
};
export default CreateAccount;
