import React, { useState } from 'react';
import { AtiveUserByOsAndClickRateContainer, ClickRateSection, FeatureCampaignAndChannelContainer, FeatureCampaignContainer, FeatureCampaignListTableHeader, FeatureCampaignListTableHeaderText, FeatureCampaignListTableText, PaginationWrapper, RateContainer, SeoCard, SeoCardContainer, SeoCardFirstChild, SeoCardThirdChild, SeoCardWrapper, SeoWrapper, TableTr, FeatureCampaignListSearchbarContainer, FeatureCampaignListWrapper, ChannelContainer, ColoredContainerWrapper, ColoredContainerWithText } from './Seo_styles';
import { CommonText, Heading1 } from '../../../../core/common/reuseables/text_styles';
import { theme } from '../../../../theme/theme';
import { AppIcons } from '../../../../core/utilities/app_icons';
import { SpowseImage } from '../../../../core/common/reuseables/spowse_image';
import { emailPerformanceList, featureCampaignHeaderText, seoCard } from '../../controllers/marketing_config';
import { ResponsiveContainer, BarChart, CartesianGrid, XAxis, YAxis, Tooltip, Bar, LabelList, LineChart, Area, Line, PieChart, Pie, Cell } from 'recharts';
import { Divider } from '../../../../core/common/reuseables/Divider';
import { DeviceInforSection, DeviceOsBarChartContainer, DeviceOsContainer } from '../../../dashboard/widgets/Visitors_and_Device_type/VisitorsAndDevice_styles';
import ReactPaginate from 'react-paginate';
import SpowseTextField from '../../../../core/common/reuseables/spowse_textfield';

type SeoProps = {

};

const Seo: React.FC<SeoProps> = () => {
  const data1 = [
    { name: "Android", uv: 4000, pv: 500400, amt: 2800 },
    { name: "iOS", uv: 3000, pv: 300398, amt: 2210 },
  ];

  const formatValue = (value: number) => {
    if (value >= 1_000_000_000) {
      return `${(value / 1_000_000_000).toFixed(1)}B`;
    } else if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const data = [
    { name: "Tech", uv: 4000, pv: 400, amt: 2400 },
    { name: "Marketing", uv: 3000, pv: 198, amt: 2210 },
    { name: "Facebook", uv: 2000, pv: 200, amt: 2290 },
    { name: "Instagram", uv: 2780, pv: 108, amt: 2000 },
    { name: "Twitter", uv: 1890, pv: 300, amt: 2181 },
  ];

  const [currentPage, setCurrentPage] = useState(0);
  const emailsPerPage = 2;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayedEmails = emailPerformanceList.slice(
    currentPage * emailsPerPage,
    (currentPage + 1) * emailsPerPage
  );

  const data3 = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
    { name: 'Group C', value: 300 },
    { name: 'Group C', value: 500 },
  ];

  const COLORS = ['#165dff', '#4FBEFC', "#D18FF9", "#EAECF0"];

  return <SeoWrapper>
    <SeoCardContainer>
      {
        seoCard.map((s, index) => {
          return <SeoCard key={index}>
            <SeoCardFirstChild>
              <CommonText fontSize='14px' fontWeight='500' color={`${theme.colors.linkInActiveTextColor}`}>
                {s.type}
              </CommonText>
              <SpowseImage src={AppIcons.optionIcon2} alt='icon' width='auto' />
            </SeoCardFirstChild>
            <SeoCardWrapper>
              <Heading1 fontSize='24px' fontWeight='700'>{s.number}</Heading1>
              <SeoCardThirdChild>
                <RateContainer>
                  {s.rate}
                </RateContainer>
                <CommonText fontSize='12px'
                  fontWeight='500'
                  color={`${theme.colors.linkInActiveTextColor}`}>{
                    s.text}
                </CommonText>
              </SeoCardThirdChild>
            </SeoCardWrapper>
          </SeoCard>
        })
      }
    </SeoCardContainer>
    <AtiveUserByOsAndClickRateContainer>
      <DeviceInforSection>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Active users by OS/Device Type
        </CommonText>
        <Divider
          width="100%"
          height="1px"
          backgroundColor={`${theme.colors.borderColor}`}
        />
        <DeviceOsBarChartContainer>
          <DeviceOsContainer>
            <CommonText
              fontSize="14px"
              fontWeight="400"
              color={`${theme.colors.linkActiveTextColor}`}
              lineHeight="22px"
            >
              Android
            </CommonText>
            <CommonText
              fontSize="14px"
              fontWeight="400"
              color={`${theme.colors.linkActiveTextColor}`}
              lineHeight="22px"
            >
              iOS
            </CommonText>
          </DeviceOsContainer>
          <ResponsiveContainer width="70%" height="100%">
            <BarChart data={data1} layout="vertical">
              <CartesianGrid strokeDasharray="3 3" horizontal={false} />
              <XAxis
                type="number"
                domain={[0, 1000000]}
                tickFormatter={formatValue}
                tickLine={false}
                axisLine={false}
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-regular"
              />
              <YAxis type="category" dataKey="name" hide tickLine={false} />
              <Tooltip cursor={false} />
              <Bar dataKey="pv" fill="#6B8BFF" barSize={56}>
                <LabelList
                  dataKey="pv"
                  position="right"
                  formatter={formatValue}
                  fontSize="14px"
                  fontWeight="400"
                  color={`${theme.colors.linkActiveTextColor}`}
                  fontFamily="matter-regular"
                />
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        </DeviceOsBarChartContainer>
        <CommonText
          style={{
            marginLeft: "30%",
            marginTop: "10px",
          }}
          fontSize="14px"
          fontWeight="400"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-regular"
        >
          Percentage of Active Users
        </CommonText>
      </DeviceInforSection>
      <ClickRateSection>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Click Through rate
        </CommonText>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          rate
        </CommonText>
        <ResponsiveContainer width="100%" height="70%">
          <LineChart
            data={data}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" tickLine={false} fontSize="12px" domain={["", 100000]} />
            <YAxis axisLine={false} fontSize="12px" tickLine={false} />
            <Tooltip />
            {/* <Legend /> */}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#0f0f12"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#6B8BFF"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
          </LineChart>
        </ResponsiveContainer>
      </ClickRateSection>
    </AtiveUserByOsAndClickRateContainer>
    <FeatureCampaignAndChannelContainer>
      <FeatureCampaignContainer>

        <FeatureCampaignListWrapper>
          <FeatureCampaignListSearchbarContainer>
            <CommonText
              fontSize="18px"
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight="500"
            >
              Featured Campaign
            </CommonText>
            <SpowseTextField
              placeHolder="Search"
              width="320px"
              height="45px"
              borderColor="transparent"
              prefixIxon={
                <SpowseImage
                  src={AppIcons.searchIcon}
                  alt="search icon"
                  width="auto"
                />
              }
            />
          </FeatureCampaignListSearchbarContainer>
          <table
            style={{
              width: "100%",
              borderCollapse: "collapse",
              border: "0",
              outline: "none",
              marginTop: "2rem",
            }}
          >
            <FeatureCampaignListTableHeader>
              <tr>
                {featureCampaignHeaderText.map((e, index) => {
                  return (
                    <FeatureCampaignListTableHeaderText
                      key={index}
                      style={{
                        width: index === 0 ? "100px" : "auto",
                        paddingLeft: index === 0 ? "1.2rem" : "9px",
                        fontWeight: "600"
                      }}
                    >
                      {e}
                    </FeatureCampaignListTableHeaderText>
                  );
                })}
              </tr>
            </FeatureCampaignListTableHeader>
            <tbody>
              {displayedEmails.map((row, index) => (
                <TableTr key={index}>
                  <FeatureCampaignListTableText
                    style={{ width: "100px", paddingLeft: "1.2rem" }}
                  >
                    {index + 1}
                  </FeatureCampaignListTableText>
                  <FeatureCampaignListTableText style={{ fontWeight: "600" }}>
                    {row.email}
                  </FeatureCampaignListTableText>
                  <FeatureCampaignListTableText>
                    {row.publishDate}
                  </FeatureCampaignListTableText>
                  <FeatureCampaignListTableText>
                    {row.sent}
                  </FeatureCampaignListTableText>
                  <FeatureCampaignListTableText>{row.clickThroughRate}</FeatureCampaignListTableText>
                </TableTr>
              ))}
            </tbody>
          </table>
          <PaginationWrapper>
            <ReactPaginate
              previousLabel={`🡠 Previous`}
              nextLabel={"Next 🡢"}
              breakLabel={"..."}
              breakClassName={"break-me"}
              pageCount={Math.ceil(emailPerformanceList.length / emailsPerPage)}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={"pagination"}
              nextClassName="n"
              activeClassName={"activeClass"}
            />
          </PaginationWrapper>
        </FeatureCampaignListWrapper>
      </FeatureCampaignContainer>
      <ChannelContainer>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Channels
        </CommonText>
        <CommonText
          fontSize="14px"
          fontWeight="500"
          color={`${theme.colors.linkInActiveTextColor}`}
          fontFamily="matter-regular"
        >
          Users from all channels
        </CommonText>
        <ResponsiveContainer width="100%" height={150}>
          <PieChart>
            <Pie
              data={data3}
              cx="50%"
              cy="100%"
              startAngle={180}
              endAngle={0}
              innerRadius={78}
              outerRadius={110}
              paddingAngle={0}
              dataKey="value"

            >
              {data3.map((entry, index) => (
                <Cell key={`cell-${index}${entry.name}`} fill={COLORS[index % COLORS.length]} />
              ))}

            </Pie>
            <g>
              <text x="50%" y="70%" textAnchor="middle" dominantBaseline="middle" fontSize={24} fontWeight="bold" fontFamily='matter-bold'>
                40,000
              </text>
              <text x="50%" y="88%" textAnchor="middle" dominantBaseline="middle" fontSize={12} fontWeight="400" color={`${theme.colors.linkInActiveTextColor}`} fontFamily='matter-medium'>
                Use this channels
              </text>

            </g>
          </PieChart>
        </ResponsiveContainer>
        <ColoredContainerWrapper>
          <ColoredContainerWithText
            background={`${COLORS[0]}`}
          >
            <div></div>
            <CommonText
              fontSize='16px'
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight='500'
              fontFamily="matter-medium"
            >
              Google
            </CommonText>

          </ColoredContainerWithText>
          <ColoredContainerWithText
            background={`${COLORS[1]}`}
          >
            <div></div>
            <CommonText
              fontSize='16px'
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight='500'
              fontFamily="matter-medium">
              Facebook
            </CommonText>

          </ColoredContainerWithText>
          <ColoredContainerWithText
            background={`${COLORS[2]}`}
          >
            <div></div>
            <CommonText
              fontSize='16px'
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight='500'
              fontFamily="matter-medium">
              Youtube
            </CommonText>

          </ColoredContainerWithText>
          <ColoredContainerWithText
            background={`${COLORS[3]}`}
          >
            <div></div>
            <CommonText
              fontSize='16px'
              color={`${theme.colors.linkActiveTextColor}`}
              fontWeight='500'
              fontFamily="matter-medium">
              Others
            </CommonText>

          </ColoredContainerWithText>
        </ColoredContainerWrapper>
      </ChannelContainer>
    </FeatureCampaignAndChannelContainer>
  </SeoWrapper>
}
export default Seo;