import React, { useState } from "react";
import {
  AuthPromptContainer,
  ForgotPasswordContainer,
  LoginSectionContent,
  LoginSectionFormArea,
  LoginSectionLeftSection,
  LoginSectionRightSection,
  LoginSectionWrapper,
  RememberMeContainer,
} from "./Login_styles";

import { SpowseImage } from "../../../core/common/reuseables/spowse_image";
import { AppImages } from "../../../core/utilities/app_images";
import {
  CommonText,
  Heading1,
} from "../../../core/common/reuseables/text_styles";
import { theme } from "../../../theme/theme";
import SpowseTextField from "../../../core/common/reuseables/spowse_textfield";
import { HeightGap } from "../../../core/common/reuseables/gap";
import CheckContainer from "../../../core/common/reuseables/CheckContainer";
import { SpowseButton } from "../../../core/common/reuseables/spowse_button";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { LoginFormValues, loginValidationSchema } from "../controller/controller";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { LoginData, OnboardAdminReponse } from "../../../logic/responnse/onboard_admin_response";
import ApiService from "../../../core/api/api";
import { ApiUrl } from "../../../core/api/api_url";
import SpinnerLoader from "../../../core/common/reuseables/spinner_loader";
import { useDispatch } from "react-redux";
import { setIsSave } from "../../../logic/slices/authentication_slice";


type LoginProps = {};

const Login: React.FC<LoginProps> = () => {
  const [active, setActive] = useState(false);
  const [password, setPassword] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const apiSservice = new ApiService();

  const handleCheckBox = () => {
    setActive((prob) => (prob = !prob));
  };
  const formik = useFormik<LoginFormValues>({
    initialValues: {
      email: '',
      password: '',
    },
    validationSchema: loginValidationSchema,
    onSubmit: async (values) => {
      const data: LoginData = {
        password: values.password,
        email: values.email,
      }
      try {
        setIsLoading(true);
        localStorage.setItem("email", values.email);
        await apiSservice.postData<LoginData, OnboardAdminReponse>(ApiUrl.login, data).then((response) => {
          if (response.data?.success === true) {
            setIsLoading(false);
            if (active) {
              dispatch(setIsSave(true));
            }
            navigate("/verify_login");
            values.email = "";
            values.password = "";
          } else {
            setIsLoading(false);
            toast.error(response.data?.message ?? "");
          }
        });
      } catch (e) {
        setIsLoading(false);
        toast.error("Sorry an error occurred!");
      }
    }
  });
  return (
    <LoginSectionWrapper>
      {isLoading && <SpinnerLoader />}
      <LoginSectionContent>
        <LoginSectionLeftSection>
          <LoginSectionFormArea onSubmit={formik.handleSubmit}>
            <Heading1
              fontSize="30px"
              fontWeight="600"
              fontFamily="matter-bold"
              lineHeight="38px"
            >
              Welcome back
            </Heading1>
            <HeightGap height="10px" />
            <CommonText
              fontSize="16px"
              fontWeight="400"
              fontFamily="matter-light"
              lineHeight="24px"
              color={`${theme.colors.lightGrey}`}
            >
              Welcome back! Please enter your details.
            </CommonText>
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="Enter your email"
              label="Email"
              id="email"
              name="email"
              type="email"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="error_text">{formik.errors.email}</div>) : null
            }
            <HeightGap height="20px" />
            <SpowseTextField
              height="44px"
              width="360px"
              placeHolder="•  •  •  •  •  •  •  •"
              label="Password"
              type={password ? "text" : "password"}
              id="password"
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              surfixIcon={password ? <AiOutlineEyeInvisible
                className="icon"
                onClick={() => {
                  setPassword((probs) => probs = !probs)
                }} /> : <MdOutlineVisibility
                className="icon"
                onClick={() => {
                  setPassword((probs) => probs = !probs)
                }} />}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="error_text">{formik.errors.password}</div>
            ) : null}
            <HeightGap height="10px" />
            <ForgotPasswordContainer>
              <RememberMeContainer>
                <CheckContainer active={active} onClick={handleCheckBox} />
                <CommonText
                  color={`${theme.colors.veryDarkGrey}`}
                  fontSize="14px"
                  fontWeight="500"
                  lineHeight="20px"
                >
                  Remember for 30 days
                </CommonText>
              </RememberMeContainer>
              <SpowseButton
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                textColor={`${theme.colors.primary}`}
                width="auto"
                height="auto"
                backgroundColor="transparent"
              >
                Forgot password?
              </SpowseButton>
            </ForgotPasswordContainer>
            <HeightGap height="30px" />

            <SpowseButton
              width="360px"
              height="50px"
              backgroundColor={`${theme.colors.primary}`}
              fontSize="18px"
              fontWeight="400"
              lineHeight="20px"
              fontFamily="matter-light"
              textColor={`${theme.colors.white}`}
              // onClick={() => {
              //   navigate("/verify_login");
              // }}
              type="submit"
            >
              Sign in
            </SpowseButton>
            <HeightGap height="20px" />
            <AuthPromptContainer>
              <CommonText
                fontSize="14px"
                fontWeight="400"
                lineHeight="20px"
                fontFamily="matter-light"
                color={`${theme.colors.lightGrey}`}
              >
                Don't have an account?
              </CommonText>
              <SpowseButton
                fontSize="14px"
                fontWeight="600"
                lineHeight="20px"
                textColor={`${theme.colors.primary}`}
                width="auto"
                height="auto"
                backgroundColor="transparent"
                onClick={() => {
                  navigate("/request_verification_link");
                }}
              >
                Sign up
              </SpowseButton>
            </AuthPromptContainer>
          </LoginSectionFormArea>
        </LoginSectionLeftSection>
        <LoginSectionRightSection>
          <SpowseImage src={AppImages.welcome} alt="welcome-image" />
        </LoginSectionRightSection>
      </LoginSectionContent>
    </LoginSectionWrapper>
  );
};
export default Login;
