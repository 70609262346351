import React, { useEffect, useState } from "react";
import {
  VerifySignUpSection,
  VerifySignUpSectionWrapper,
} from "./VerifySignUp_styles";
import { useSearchParams } from "react-router-dom";
import SpinnerLoader from "../../../../core/common/reuseables/spinner_loader";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import ApiService from "../../../../core/api/api";
import { ApiUrl } from "../../../../core/api/api_url";
import { VerifyEmailData, VerifyEmailResponse } from "../../../../logic/responnse/verify_email_response";
import { updateUser } from "../../controller/controller";




type VerifySignUpProps = {};

const VerifySignUp: React.FC<VerifySignUpProps> = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  useEffect(() => {
    const apiService = new ApiService();
    const data: VerifyEmailData = {
      verificationToken: token ?? "",
    };
    const verifyEmail = async () => {
      if (token) {
        try {
          await apiService.postData<VerifyEmailData, VerifyEmailResponse>(ApiUrl.verifyEmail, data,).then(async (data) => {
            if (data.data?.success === true) {
              await updateUser(data.data?.data.email ?? "", data?.data?.accessToken ?? "", true);
              setIsLoading(false);
              setError(false);
            } else {
              setIsLoading(false);
              setError(true);
              setErrorMessage(data.data?.message ?? "");
            }
          });
        } catch (e) {
          setIsLoading(false);
          setError(true);
          setErrorMessage("An error occurred");
          console.log(e);
        }
      }

    }

    verifyEmail();

  }, [token])
  return (
    <VerifySignUpSectionWrapper>
      {
        token ? isLoading ? <SpinnerLoader /> : <VerifySignUpSection>
          {error ? <SpowseImage src="/assets/icons/octicon_alert-24.svg" alt="img" width="auto" /> : <IoIosCheckmarkCircle />}
          <HeightGap height="20px" />
          <CommonText
            fontSize="20px"
            fontFamily={"matter-medium"}
            color={`${error ? "red" : "black"}`}
          >
            {error ? `${errorMessage}` : "Email Verified"}
          </CommonText>
        </VerifySignUpSection> : <CommonText color="red">
          Ivalid Token
        </CommonText>
      }
    </VerifySignUpSectionWrapper>
  );
};
export default VerifySignUp;
