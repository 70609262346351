import React from 'react';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';
import { SpowseImage } from './spowse_image';
import { CommonText } from './text_styles';

type ImagePickerProps = {

};

const ImagePickerConntainer = styled.label`
width: 100%;
border: 1px solid ${theme.colors.borderColor};
height: 127px;
display:flex ;
border-radius: 7px;
flex-direction: column ;
align-items: center ;
justify-content: center;
gap: 10px;
margin-bottom: 1.3rem;
cursor: pointer;
`;

const ImagePicker: React.FC<ImagePickerProps> = () => {

  return <ImagePickerConntainer>
    <SpowseImage src="/assets/icons/Image plus 1.svg" alt='img' width='auto' />
    <CommonText
      fontFamily={"matter-medium"}
      fontSize="14px"
      color={`${theme.colors.primary}`}
    >Upload Image</CommonText>
  </ImagePickerConntainer>
}
export default ImagePicker;