import React, { useState } from "react";
import ReactPaginate from "react-paginate";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { AppIcons } from "../../../../core/utilities/app_icons";
import { theme } from "../../../../theme/theme";
import {
  partnershipList,
  overviewTotalNewsletterChart,
  newsLetterListHeaderText,
} from "../../controller/campaign_config";
import {
  NewsLetterSectionWrapper,
  NewsLetterListWrapper,
  NewsLetterListSearchbarContainer,
  SearchAndButtonContainer,
  NewsLetterListTableHeader,
  NewsLetterListTableHeaderText,
  TableTr,
  NewsLetterListTableText,
  UserStatusContainer,
  PaginationWrapper,
} from "./Newsletter_styles";
import { TotalNewsletterChartContainer } from "../overview_section/Overview_styles";
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from "recharts";
import { HeightGap } from "../../../../core/common/reuseables/gap";
import Modal from "../../../../core/common/reuseables/modal";
import SpowseTextArea from "../../../../core/common/reuseables/spowse_textarea";

type NewsletterSectionProps = {};

const NewsletterSection: React.FC<NewsletterSectionProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const partnershipPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displaypartnership = partnershipList.slice(
    currentPage * partnershipPerPage,
    (currentPage + 1) * partnershipPerPage
  );
  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                M${x},${y + height}
                L${x},${y + radius}
                Q${x},${y} ${x + radius},${y}
                L${x + width - radius},${y}
                Q${x + width},${y} ${x + width},${y + radius}
                L${x + width},${y + height}
                Z
              `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };

  const [isModalOpen, setModalOpen] = useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <NewsLetterSectionWrapper>
      <Modal title="Create Newsletter" isOpen={isModalOpen} onClose={closeModal}>
        <SpowseTextField
          label="Title"
          placeHolder="Enter title"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextArea
          label="Content"
          placeHolder="Enter content"
          width="100%"
          borderColor={`${theme.colors.borderColor}`}
        />
        <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Target Audience"
          placeHolder="Choose audience"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
        />
         <HeightGap height="1.3rem" />
        <SpowseTextField
          label="Send Date"
          placeHolder="Enter send date"
          width="100%"
          height="45px"
          borderColor={`${theme.colors.borderColor}`}
          surfixIcon={<SpowseImage src="/assets/icons/calendar.svg" alt="icon" width="auto" />}
        />

        <HeightGap height="1.3rem" />
        <SpowseButton
          width="100%"
          height="50px"
          fontSize="14px"
          fontWeight="400"
          fontFamily="matter-light"
          onClick={openModal}
        >
          Create Newsletter
        </SpowseButton>
      </Modal>
      <HeightGap height="1.5rem" />
      <TotalNewsletterChartContainer width="100%">
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-bold"
        >
          Total newsletter
        </CommonText>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          Amount
        </CommonText>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart data={overviewTotalNewsletterChart}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" fontSize="12px" tickLine={false} />
            <YAxis
              fontSize="12px"
              width={35}
              tickLine={false}
              axisLine={false}
              tickFormatter={formatValue}
            />
            <Tooltip cursor={false} />
            <Bar
              dataKey="pv"
              fill="#165DFF"
              shape={<CustomBar />}
              barSize={56}
            />
          </BarChart>
        </ResponsiveContainer>
      </TotalNewsletterChartContainer>
      <NewsLetterListWrapper>
        <NewsLetterListSearchbarContainer>
          <CommonText
            fontSize="18px"
            color={`${theme.colors.linkActiveTextColor}`}
            fontWeight="500"
          >
            Newsletter
          </CommonText>
          <SearchAndButtonContainer>
            <SpowseTextField
              placeHolder="Search"
              width="320px"
              height="45px"
              borderColor="transparent"
              prefixIxon={
                <SpowseImage
                  src={AppIcons.searchIcon}
                  alt="search icon"
                  width="auto"
                />
              }
            />
            <SpowseButton
              width="148px"
              height="40px"
              fontSize="14px"
              fontWeight="400"
              fontFamily="matter-light"
              onClick={openModal}
            >
              Create Newsletter
            </SpowseButton>
          </SearchAndButtonContainer>
        </NewsLetterListSearchbarContainer>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "0",
            outline: "none",
            marginTop: "2rem",
          }}
        >
          <NewsLetterListTableHeader>
            <tr>
              {newsLetterListHeaderText.map((e, index) => {
                return (
                  <NewsLetterListTableHeaderText
                    key={index}
                    style={{
                      width: index === 0 ? "100px" : "auto",
                      paddingLeft: index === 0 ? "1.2rem" : "9px",
                    }}
                  >
                    {e}
                  </NewsLetterListTableHeaderText>
                );
              })}
            </tr>
          </NewsLetterListTableHeader>
          <tbody>
            {displaypartnership.map((row, index) => (
              <TableTr key={index}>
                <NewsLetterListTableText
                  style={{ width: "100px", paddingLeft: "1.2rem" }}
                >
                  {index + 1}
                </NewsLetterListTableText>
                <NewsLetterListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-regular"
                    style={{
                      width: "150px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.partnerName}
                  </CommonText>
                </NewsLetterListTableText>
                <NewsLetterListTableText>
                  <CommonText
                    fontWeight="400"
                    fontSize=" 14px"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontFamily="matter-light"
                    style={{
                      width: "120px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {row.startDate}
                  </CommonText>
                </NewsLetterListTableText>
                <NewsLetterListTableText style={{ width: "130px" }}>
                  <UserStatusContainer status={row.openRate}>
                    {row.openRate}
                  </UserStatusContainer>
                </NewsLetterListTableText>
                <NewsLetterListTableText
                  style={{
                    width: "130px",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  <UserStatusContainer status={row.clickRate}>
                    {row.clickRate}
                  </UserStatusContainer>
                </NewsLetterListTableText>
                <NewsLetterListTableText style={{ width: "130px" }}>
                  <UserStatusContainer status={row.performance}>
                    {row.performance}
                  </UserStatusContainer>
                </NewsLetterListTableText>
                <NewsLetterListTableText>
                  <UserStatusContainer status={row.status}>
                    {row.status}
                  </UserStatusContainer>
                </NewsLetterListTableText>

              </TableTr>
            ))}
          </tbody>
        </table>
        <PaginationWrapper>
          <ReactPaginate
            previousLabel={`🡠   Previous`}
            nextLabel={"Next 🡢"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(partnershipList.length / partnershipPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            nextClassName="n"
            activeClassName={"activeClass"}
          />
        </PaginationWrapper>
      </NewsLetterListWrapper>
    </NewsLetterSectionWrapper>
  );
};
export default NewsletterSection;
