import React from "react";
import {
  ActiveUserLeftFirstSection,
  ActiveUserLeftSection,
  ActiveUserRightSection,
  ActiveUserWrapper,
  BarChartContainer,
  Select,
} from "./ActiveUser_styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  BarChart,
  Bar,
} from "recharts";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";

type ActiveUserProps = {};

const ActiveUser: React.FC<ActiveUserProps> = () => {
  const data = [
    { name: "Mon", uv: 4000, pv: 2400, amt: 2400 },
    { name: "Tue", uv: 3000, pv: 1398, amt: 2210 },
    { name: "Wed", uv: 2000, pv: 800, amt: 2290 },
    { name: "Thu", uv: 2780, pv: 3908, amt: 2000 },
    { name: "Fri", uv: 1890, pv: 4800, amt: 2181 },
    { name: "Sat", uv: 2390, pv: 3800, amt: 2500 },
    { name: "Sun", uv: 3490, pv: 5300, amt: 2100 },
  ];

  const data1 = [
    { name: "1", uv: 4000, pv: 2400, amt: 2400 },
    { name: "2", uv: 3000, pv: 1398, amt: 2210 },
    { name: "3", uv: 2000, pv: 800, amt: 2290 },
    { name: "4", uv: 2780, pv: 3908, amt: 2000 },
    { name: "5", uv: 1890, pv: 4800, amt: 2181 },
    { name: "6", uv: 2390, pv: 3800, amt: 2500 },
    { name: "7", uv: 3490, pv: 5300, amt: 2100 },
  ];

  const formatValue = (value: number) => {
    if (value >= 1_000_000_000) {
      return `${(value / 1_000_000_000).toFixed(1)}B`;
    } else if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
            M${x},${y + height}
            L${x},${y + radius}
            Q${x},${y} ${x + radius},${y}
            L${x + width - radius},${y}
            Q${x + width},${y} ${x + width},${y + radius}
            L${x + width},${y + height}
            Z
          `}
          fill={fill}
        />
      </g>
    );
  };

  return (
    <ActiveUserWrapper>
      <ActiveUserLeftSection>
        <ActiveUserLeftFirstSection>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            fontFamily="matter-medium"
            lineHeight="28px"
            color={`${theme.colors.linkActiveTextColor}`}
          >
            Active Users
          </CommonText>
          <Select>
            <option>Weekly</option>
            <option>Monthly</option>
          </Select>
        </ActiveUserLeftFirstSection>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          unit
        </CommonText>
        <BarChartContainer>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" fontSize="12px" />
              <YAxis fontSize="12px" width={35} />
              <Tooltip cursor={false} />
              <Bar
                dataKey="pv"
                fill="#165DFF"
                shape={<CustomBar />}
                barSize={50}
              />
            </BarChart>
          </ResponsiveContainer>
        </BarChartContainer>
      </ActiveUserLeftSection>
      <ActiveUserRightSection>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          fontFamily="matter-medium"
          lineHeight="28px"
          color={`${theme.colors.linkActiveTextColor}`}
        >
          Session length
        </CommonText>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          Sessions
        </CommonText>
        <ResponsiveContainer width="100%" height={340}>
          <LineChart data={data1}>
            <defs>
              <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
                <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              dataKey="name"
              fontSize="12px"
              fontWeight="400"
              fontFamily="matter-light"
              color="#475467"
            />
            <YAxis
              tickFormatter={formatValue}
              width={30}
              fontSize="12px"
              fontWeight="400"
              fontFamily="matter-light"
              color="#475467"
            />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="pv"
              stroke="#8884d8"
              fill="url(#colorPv)"
              fillOpacity={1}
            />
            <Line
              type="linear"
              dataKey="pv"
              stroke="#165DFF"
              strokeWidth={2}
              dot={false}
            />
          </LineChart>
        </ResponsiveContainer>
      </ActiveUserRightSection>
    </ActiveUserWrapper>
  );
};
export default ActiveUser;
