import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const EmailVerificationSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const EmailVerificationSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 5000px;
  width: 100%;
`;

export const GoBackContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  & > :nth-child(1) {
    width: 20px;
    height: 20px;
  }
`;

//!left sectionstyles
export const EmailVerificationSectionLeftSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

export const EmailVerificationSectionFormArea = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

//! right section styles
export const EmailVerificationSectionRightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.lightBlue};
  overflow: hidden;
`;
