import { ENV } from './env';

export class Utils {
  static getAppUrl(): string {
    if (ENV.NODE_ENV === 'DEVELOPMENT') {
      return ENV.SPOWSE_ADMIN_APP_URL_DEV || '';
    } else {
      return ENV.SPOWSE_ADMIN_APP_URL_PROD || '';
    }
  }
}
