import { useState } from "react";
import ReactPaginate from "react-paginate";
import { Area, Bar, BarChart, CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { SpowseImage } from "../../../../core/common/reuseables/spowse_image";
import SpowseTextField from "../../../../core/common/reuseables/spowse_textfield";
import { CommonText, Heading1 } from "../../../../core/common/reuseables/text_styles";
import { AppIcons } from "../../../../core/utilities/app_icons";
import { theme } from "../../../../theme/theme";
import { overviewTotalNewsletterChart } from "../../../campaigns/controller/campaign_config";
import { emailMarketingCardDetails, emailPerformanceHeaderText, emailPerformanceList } from "../../controllers/marketing_config";
import { ColoredContainer, EmailDataAndChartContainer, EmailDataAndSentEmailContainer, EmailMarketingCardContainer, EmailMarketingCardContainerFisrstRow, EmailMarketingCardContainerThirdRow, EmailMarketingCardIconContainer, EmailMarketingCardSectionWrapper, EmailMarketingWrapper, EmailPerformanceListSearchbarContainer, EmailPerformanceListTableHeader, EmailPerformanceListTableHeaderText, EmailPerformanceListTableText, EmailPerformanceListWrapper, EmailPerformanceStatusContainer, Option, PaginationWrapper, RateContainer, Select, SentEmailContainer, TableTr, ValueContainer, ValueTextContainer, } from "./EmailMarketing_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { SearchAndButtonContainer } from "../../../campaigns/widdets/campaign_section/CampignSection_styles";

type EmailMarketingProps = {};

const EmailMarketing: React.FC<EmailMarketingProps> = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const emailsPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayedEmails = emailPerformanceList.slice(
    currentPage * emailsPerPage,
    (currentPage + 1) * emailsPerPage
  );

  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                M${x},${y + height}
                L${x},${y + radius}
                Q${x},${y} ${x + radius},${y}
                L${x + width - radius},${y}
                Q${x + width},${y} ${x + width},${y + radius}
                L${x + width},${y + height}
                Z
              `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };


  return <EmailMarketingWrapper>
    <EmailMarketingCardSectionWrapper>
      {emailMarketingCardDetails.map((value, index) => {
        return (
          <EmailMarketingCardContainer key={index}>
            <EmailMarketingCardContainerFisrstRow>
              <EmailMarketingCardIconContainer
                background={value.backgroundColor}
              >
                <SpowseImage src={value.icon} alt="img" width="auto" />
              </EmailMarketingCardIconContainer>
              <Select id="options" name="options">
                <Option value="This month">This month</Option>
                <Option value="Today">Today</Option>
                <Option value="7 day ago">7 day ago</Option>
                <Option value="Last month">Last month</Option>
              </Select>
            </EmailMarketingCardContainerFisrstRow>
            <Heading1
              fontFamily="matter-bold"
              fontWeight="600"
              color={`${theme.colors.linkActiveTextColor}`}
              fontSize="20px"
              lineHeight="30px"
            >
              {value.amount}
            </Heading1>
            <EmailMarketingCardContainerThirdRow>
              <CommonText
                fontFamily="matter-medium"
                fontWeight="500"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontSize="14px"
                lineHeight="20px"
              >
                {value.emailMarketingType}
              </CommonText>
              <RateContainer>
                <CommonText
                  fontFamily="matter-medium"
                  fontWeight="500"
                  fontSize="12px"
                  lineHeight="18px"
                  color="#0F973D"
                >
                  {value.rate}
                </CommonText>
              </RateContainer>
            </EmailMarketingCardContainerThirdRow>
            {/*  */}

          </EmailMarketingCardContainer>
        );
      })}
    </EmailMarketingCardSectionWrapper>
    <EmailDataAndSentEmailContainer>
      <EmailDataAndChartContainer>
        <CommonText
          fontFamily="matter-medium"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="600"
          fontSize="20px"
        >
          Email Data
        </CommonText>
        <ValueContainer>
          <ValueTextContainer>
            <ColoredContainer backgroundColor="#569AD9" />
            <CommonText
              fontFamily="matter-regular"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontWeight="400"
              fontSize="14px"
            >
              Click through rate
            </CommonText>
          </ValueTextContainer>
          <ValueTextContainer>
            <ColoredContainer backgroundColor="#0FC6C2" />
            <CommonText
              fontFamily="matter-regular"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontWeight="400"
              fontSize="14px"
            >
              Open rate
            </CommonText>
          </ValueTextContainer>
        </ValueContainer>
        <ResponsiveContainer width="100%" height="70%">
          <LineChart
            data={overviewTotalNewsletterChart}
            margin={{
              top: 5,
              bottom: 5,
              left: 1,
              right: 1,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              tickLine={false}
              fontSize="12px"
              padding={{ left: 0 }}
            />
            <YAxis
              axisLine={false}
              fontSize="12px"
              tickLine={false}
              domain={["auto", "4000"]}
              width={55}
            />
            <Tooltip />
            {/* <Legend /> */}
            <Area
              type="monotone"
              dataKey="uv"
              stroke="#0f0f12"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
            <Line
              type="monotone"
              dataKey="pv"
              stroke="#569AD9"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
            <Line
              type="monotone"
              dataKey="uv"
              stroke="#0FC6C2"
              strokeWidth={2}
              dot={false}
              fill="url(#colorPv)"
            />
          </LineChart>
        </ResponsiveContainer>
        <ValueContainer marginTop="0" justifyContent="center">
          <CommonText
            fontFamily="Inter"
            color={`${theme.colors.linkInActiveTextColor}`}
            fontWeight="600"
            fontSize="12px"
          >
            Month
          </CommonText>
        </ValueContainer>
      </EmailDataAndChartContainer>
      <SentEmailContainer>
        <CommonText
          fontSize="20px"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
          fontFamily="matter-medium"
        >
          Sent Email
        </CommonText>
        <CommonText fontSize="12px" fontWeight="400" color="#475467">
          Amount
        </CommonText>
        <ResponsiveContainer width="100%" height="90%">
          <BarChart data={overviewTotalNewsletterChart}>
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis dataKey="name" fontSize="12px" tickLine={false} />
            <YAxis
              fontSize="12px"
              width={35}
              tickLine={false}
              axisLine={false}
              tickFormatter={formatValue}
            />
            <Tooltip cursor={false} />
            <Bar
              dataKey="pv"
              fill="#165DFF"
              shape={<CustomBar />}
              barSize={11}
            />
          </BarChart>
        </ResponsiveContainer>
      </SentEmailContainer>
    </EmailDataAndSentEmailContainer>
    <EmailPerformanceListWrapper>
      <EmailPerformanceListSearchbarContainer>
        <CommonText
          fontSize="18px"
          color={`${theme.colors.linkActiveTextColor}`}
          fontWeight="500"
        >
          All Email Performances
        </CommonText>
        <SearchAndButtonContainer>
          <SpowseTextField
            placeHolder="Search"
            width="320px"
            height="45px"
            borderColor="transparent"
            prefixIxon={
              <SpowseImage
                src={AppIcons.searchIcon}
                alt="search icon"
                width="auto"
              />
            }
          />
          <SpowseButton
            width="135px"
            height="38px"
            fontSize="14px"
            fontWeight="400"
            fontFamily="matter-light"
          >
            Create Email
          </SpowseButton>
        </SearchAndButtonContainer>
      </EmailPerformanceListSearchbarContainer>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
          border: "0",
          outline: "none",
          marginTop: "2rem",
        }}
      >
        <EmailPerformanceListTableHeader>
          <tr>
            {emailPerformanceHeaderText.map((e, index) => {
              return (
                <EmailPerformanceListTableHeaderText
                  key={index}
                  style={{
                    width: index === 0 ? "100px" : "auto",
                    paddingLeft: index === 0 ? "1.2rem" : "9px",
                    fontWeight: "600"
                  }}
                >
                  {e}
                </EmailPerformanceListTableHeaderText>
              );
            })}
          </tr>
        </EmailPerformanceListTableHeader>
        <tbody>
          {displayedEmails.map((row, index) => (
            <TableTr key={index}>
              <EmailPerformanceListTableText
                style={{ width: "100px", paddingLeft: "1.2rem" }}
              >
                {index + 1}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText style={{ fontWeight: "600" }}>
                {row.email}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText>
                {row.publishDate}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText>
                {row.sent}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText>{row.clickThroughRate}</EmailPerformanceListTableText>
              <EmailPerformanceListTableText>
                {row.deliveryRate}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText>
                {row.unSbscribeRate}
              </EmailPerformanceListTableText>
              <EmailPerformanceListTableText>
                <EmailPerformanceStatusContainer status={row.spamRate}>
                  {row.spamRate}
                </EmailPerformanceStatusContainer>
              </EmailPerformanceListTableText>
            </TableTr>
          ))}
        </tbody>
      </table>
      <PaginationWrapper>
        <ReactPaginate
          previousLabel={`🡠 Previous`}
          nextLabel={"Next 🡢"}
          breakLabel={"..."}
          breakClassName={"break-me"}
          pageCount={Math.ceil(emailPerformanceList.length / emailsPerPage)}
          marginPagesDisplayed={2}
          pageRangeDisplayed={5}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          nextClassName="n"
          activeClassName={"activeClass"}
        />
      </PaginationWrapper>
    </EmailPerformanceListWrapper>
  </EmailMarketingWrapper>
}
export default EmailMarketing;
