export const userTypes = [
  {
    iconBackgroundColor: '#FEF4E6',
    userCount: '100,000',
    userType: 'Total Users',
    rate: '+5%',
    icon: '/assets/icons/people.svg',
  },
  {
    iconBackgroundColor: '#FEE6E6',
    userCount: '20,000',
    userType: 'Inactive users',
    rate: '+5%',
    icon: '/assets/icons/people_red.svg',
  },
  {
    iconBackgroundColor: '#E6FEF0',
    userCount: '70,000',
    userType: 'Active Users',
    rate: '+5%',
    icon: '/assets/icons/people_green.svg',
  },

  {
    iconBackgroundColor: '#E7F6EC',
    userCount: '30',
    userType: 'Total Admins',
    rate: '+5%',
    icon: '/assets/icons/total_admin.svg',
  },
];

export const userListHeaderText = [
  'No',
  'Name',
  'Email',
  'Phone No',
  'Country',
  'Role',
  'Status',
  '',
];

export const roleHederText = [
  'Role',
  'Description',
  'Permission',
  'Member',
  'Action',
];

export const roles = [
  {
    role: 'Super Admin',
    description:
      'has full access to all system settings, manages users and roles, controls content, enforces security policies, and oversees platform-wide operations.',
    permission: ['Add member', 'Delete user', 'Manage permissions'],
    member: '1',
  },
  {
    role: 'Admin',
    description:
      'Manages day-to-day operations, oversees content creation, and moderates user activity while ensuring compliance with platform guidelines.',
    permission: ['Manage content', 'Moderate users', 'View analytics'],
    member: '3',
  },
  {
    role: 'Editor',
    description:
      'Responsible for creating, editing, and publishing content, ensuring quality and accuracy, and adhering to editorial standards.',
    permission: ['Create content', 'Edit content', 'Publish content'],
    member: 'No member',
  },
  {
    role: 'Moderator',
    description:
      'Monitors user activity, enforces community guidelines, and resolves disputes to maintain a safe and respectful environment.',
    permission: [
      'Monitor user activity',
      'Enforce guidelines',
      'Resolve disputes',
    ],
    member: '7',
  },
  {
    role: 'Viewer',
    description:
      'Has limited access to view content and data but cannot make changes or participate in administrative functions.',
    permission: ['View content', 'Access reports', 'Follow updates'],
    member: '10',
  },
];

export const editPermission = [
  {
    option: 'Modules',
    value: 'Enable/Disable Access',
  },
  {
    option: 'Edit',
    value: '',
  },
  {
    option: 'Update',
    value: '',
  },
  {
    option: 'Deactivate User',
    value: '',
  },
  {
    option: 'Block User',
    value: '',
  },
  {
    option: 'Reports, Analytics & Insights',
    value: 'Enable/Disable Access',
  },
  {
    option: 'Client Data Management',
    value: '',
  },
];

export const userData = [
  {
    name: 'Hosea Salako',
    email: 'hoseasalako@gmail.com',
    phone: '07015572136',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Nigeria',
    plan: 'Platinium',
  },
  {
    name: 'Jane Doe',
    email: 'janedoe@gmail.com',
    phone: '08023456789',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'United States',
    plan: 'Platinium',
  },
  {
    name: 'John Smith',
    email: 'johnsmith@gmail.com',
    phone: '09012345678',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'England',
    plan: 'Premium',
  },
  {
    name: 'Alice Johnson',
    email: 'alicejohnson@gmail.com',
    phone: '07098765432',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Canada',
    plan: 'Platinium',
  },
  {
    name: 'Bob Brown',
    email: 'bobbrown@gmail.com',
    phone: '08123456789',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Australia',
    plan: 'Premium',
  },
  {
    name: 'Emily Davis',
    email: 'emilydavis@gmail.com',
    phone: '09123456789',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Nigeria',
    plan: 'Premium',
  },
  {
    name: 'Michael Wilson',
    email: 'michaelwilson@gmail.com',
    phone: '07123456789',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'South Africa',
    plan: 'Platinium',
  },
  {
    name: 'Jessica Miller',
    email: 'jessicamiller@gmail.com',
    phone: '08198765432',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Togo',
    plan: 'Platinium',
  },
  {
    name: 'Chris Evans',
    email: 'chrisevans@gmail.com',
    phone: '09198765432',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Ghana',
    plan: 'Platinium',
  },
  {
    name: 'Sarah Taylor',
    email: 'sarahtaylor@gmail.com',
    phone: '07056789012',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Kenya',
    plan: 'Platinium',
  },
  {
    name: 'Kevin Scott',
    email: 'kevinscott@gmail.com',
    phone: '08123456789',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Uganda',
    plan: 'Platinium',
  },
  {
    name: 'Laura Lee',
    email: 'lauralee@gmail.com',
    phone: '09123456789',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Germany',
    plan: 'Platinium',
  },
  {
    name: 'Robert King',
    email: 'robertking@gmail.com',
    phone: '07012345678',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Brazil',
    plan: 'Platinium',
  },
  {
    name: 'Angela White',
    email: 'angelawhite@gmail.com',
    phone: '08134567890',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Argentina',
    plan: 'Platinium',
  },
  {
    name: 'Peter Green',
    email: 'petergreen@gmail.com',
    phone: '09145678901',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'France',
    plan: 'Platinium',
  },
  {
    name: 'Linda Clark',
    email: 'lindaclark@gmail.com',
    phone: '07123456789',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Spain',
    plan: 'Platinium',
  },
  {
    name: 'Brian Lewis',
    email: 'brianlewis@gmail.com',
    phone: '08123456789',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Italy',
    plan: 'Platinium',
  },
  {
    name: 'Emma Walker',
    email: 'emmawalker@gmail.com',
    phone: '09123456789',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Portugal',
    plan: 'Platinium',
  },
  {
    name: 'George Harris',
    email: 'georgeharris@gmail.com',
    phone: '07098765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Netherlands',
    plan: 'Platinium',
  },
  {
    name: 'Mia Martinez',
    email: 'miamartinez@gmail.com',
    phone: '08187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Belgium',
    plan: 'Platinium',
  },
  {
    name: 'Ethan Young',
    email: 'ethanyoung@gmail.com',
    phone: '09176543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Sweden',
    plan: 'Platinium',
  },
  {
    name: 'Olivia Hall',
    email: 'oliviahall@gmail.com',
    phone: '07065432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Norway',
    plan: 'Platinium',
  },
  {
    name: 'David Allen',
    email: 'davidallen@gmail.com',
    phone: '08154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Finland',
    plan: 'Platinium',
  },
  {
    name: 'Sophia Wright',
    email: 'sophiawright@gmail.com',
    phone: '09143210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Denmark',
    plan: 'Platinium',
  },
  {
    name: 'James Hill',
    email: 'jameshill@gmail.com',
    phone: '07032109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Switzerland',
    plan: 'Platinium',
  },
  {
    name: 'Isabella Adams',
    email: 'isabellaadams@gmail.com',
    phone: '08121098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Austria',
    plan: 'Platinium',
  },
  {
    name: 'Jack Turner',
    email: 'jackturner@gmail.com',
    phone: '09110987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Ireland',
    plan: 'Platinium',
  },
  {
    name: 'Charlotte Perez',
    email: 'charlotteperez@gmail.com',
    phone: '07009876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Poland',
    plan: 'Platinium',
  },
  {
    name: 'Henry Nelson',
    email: 'henrynelson@gmail.com',
    phone: '08198765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Czech Republic',
    plan: 'Platinium',
  },
  {
    name: 'Amelia Mitchell',
    email: 'ameliamitchell@gmail.com',
    phone: '09187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Greece',
    plan: 'Platinium',
  },
  {
    name: 'Liam Carter',
    email: 'liamcarter@gmail.com',
    phone: '07076543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Romania',
    plan: 'Platinium',
  },
  {
    name: 'Mason Anderson',
    email: 'masonanderson@gmail.com',
    phone: '08165432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Hungary',
    plan: 'Platinium',
  },
  {
    name: 'Evelyn Thompson',
    email: 'evelynthompson@gmail.com',
    phone: '09154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Bulgaria',
    plan: 'Platinium',
  },
  {
    name: 'Logan Scott',
    email: 'loganscott@gmail.com',
    phone: '07043210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Croatia',
    plan: 'Premium',
  },
  {
    name: 'Ella Collins',
    email: 'ellacollins@gmail.com',
    phone: '08132109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Slovakia',
    plan: 'Premium',
  },
  {
    name: 'Jacob Roberts',
    email: 'jacobroberts@gmail.com',
    phone: '09121098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Slovenia',
    plan: 'Premium',
  },
  {
    name: 'Ava Lewis',
    email: 'avalewis@gmail.com',
    phone: '07010987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Lithuania',
    plan: 'Premium',
  },
  {
    name: 'Lucas Walker',
    email: 'lucaswalker@gmail.com',
    phone: '08109876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Latvia',
    plan: 'Premium',
  },
  {
    name: 'Mia Wright',
    email: 'miawright@gmail.com',
    phone: '09198765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Estonia',
    plan: 'Premium',
  },
  {
    name: 'Oliver Hall',
    email: 'oliverhall@gmail.com',
    phone: '07087654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Luxembourg',
    plan: 'Premium',
  },
  {
    name: 'Noah Harris',
    email: 'noahharris@gmail.com',
    phone: '08176543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Malta',
    plan: 'Premium',
  },
  {
    name: 'Emily Baker',
    email: 'emilybaker@gmail.com',
    phone: '09165432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Cyprus',
    plan: 'Premium',
  },
  {
    name: 'Benjamin Allen',
    email: 'benjaminallen@gmail.com',
    phone: '07054321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Iceland',
    plan: 'Premium',
  },
  {
    name: 'Charlotte Lee',
    email: 'charlottele@gmail.com',
    phone: '08143210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Serbia',
    plan: 'Premium',
  },
  {
    name: 'Alexander King',
    email: 'alexanderking@gmail.com',
    phone: '09132109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Montenegro',
    plan: 'Premium',
  },
  {
    name: 'Grace Young',
    email: 'graceyoung@gmail.com',
    phone: '07021098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Macedonia',
    plan: 'Premium',
  },
  {
    name: 'Elijah Gonzalez',
    email: 'elijahgonzalez@gmail.com',
    phone: '08110987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Kosovo',
    plan: 'Premium',
  },
  {
    name: 'Chloe Anderson',
    email: 'chloeanderson@gmail.com',
    phone: '09109876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Bosnia',
    plan: 'Premium',
  },
  {
    name: 'Daniel Martinez',
    email: 'danielmartinez@gmail.com',
    phone: '07098765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Herzegovina',
    plan: 'Premium',
  },
  {
    name: 'Victoria Hernandez',
    email: 'victoriahernandez@gmail.com',
    phone: '08187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Albania',
    plan: 'Premium',
  },
  {
    name: 'Matthew Clark',
    email: 'matthewclark@gmail.com',
    phone: '09176543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Armenia',
    plan: 'Premium',
  },
  {
    name: 'Avery Lewis',
    email: 'averylewis@gmail.com',
    phone: '07065432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Georgia',
    plan: 'Premium',
  },
  {
    name: 'Harper Rodriguez',
    email: 'harperrodriguez@gmail.com',
    phone: '08154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Azerbaijan',
    plan: 'Premium',
  },
  {
    name: 'Samuel Lee',
    email: 'samuellee@gmail.com',
    phone: '09143210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Kazakhstan',
    plan: 'Premium',
  },
  {
    name: 'Zoey Scott',
    email: 'zoeyscott@gmail.com',
    phone: '07032109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Uzbekistan',
    plan: 'Premium',
  },
  {
    name: 'Gabriel Martinez',
    email: 'gabrielmartinez@gmail.com',
    phone: '08121098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Turkmenistan',
    plan: 'Premium',
  },
  {
    name: 'Scarlett White',
    email: 'scarlettwhite@gmail.com',
    phone: '09110987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Kyrgyzstan',
    plan: 'Premium',
  },
  {
    name: 'David Walker',
    email: 'davidwalker@gmail.com',
    phone: '07009876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Tajikistan',
    plan: 'Premium',
  },
  {
    name: 'Natalie Turner',
    email: 'natalieturner@gmail.com',
    phone: '08198765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Pakistan',
    plan: 'Premium',
  },
  {
    name: 'Joshua Harris',
    email: 'joshuaharris@gmail.com',
    phone: '09187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Afghanistan',
    plan: 'Premium',
  },
  {
    name: 'Eleanor Thompson',
    email: 'eleanorthompson@gmail.com',
    phone: '07076543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Sri Lanka',
    plan: 'Premium',
  },
  {
    name: 'Jackson Davis',
    email: 'jacksondavis@gmail.com',
    phone: '08165432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Nepal',
    plan: 'Premium',
  },
  {
    name: 'Sofia Allen',
    email: 'sofiaallen@gmail.com',
    phone: '09154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Bangladesh',
    plan: 'Premium',
  },
  {
    name: 'Levi Green',
    email: 'levigreen@gmail.com',
    phone: '07043210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Myanmar',
    plan: 'Platinium',
  },
  {
    name: 'Zoe Martinez',
    email: 'zoemartinez@gmail.com',
    phone: '08132109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Laos',
    plan: 'Platinium',
  },
  {
    name: 'Isaac Gonzalez',
    email: 'isaacgonzalez@gmail.com',
    phone: '09121098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Cambodia',
    plan: 'Platinium',
  },
  {
    name: 'Layla Hall',
    email: 'laylahall@gmail.com',
    phone: '07010987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Vietnam',
    plan: 'Platinium',
  },
  {
    name: 'Sebastian King',
    email: 'sebastianking@gmail.com',
    phone: '08109876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Philippines',
    plan: 'Platinium',
  },
  {
    name: 'Madison Hill',
    email: 'madisonhill@gmail.com',
    phone: '09198765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Indonesia',
    plan: 'Platinium',
  },
  {
    name: 'Aiden Lee',
    email: 'aidenlee@gmail.com',
    phone: '07087654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Thailand',
    plan: 'Platinium',
  },
  {
    name: 'Brooklyn Allen',
    email: 'brooklynallen@gmail.com',
    phone: '08176543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Malaysia',
    plan: 'Platinium',
  },
  {
    name: 'Caleb Baker',
    email: 'calebbaker@gmail.com',
    phone: '09165432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Singapore',
    plan: 'Platinium',
  },
  {
    name: 'Aurora Lewis',
    email: 'auroralewis@gmail.com',
    phone: '07054321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Brunei',
    plan: 'Platinium',
  },
  {
    name: 'Maverick White',
    email: 'maverickwhite@gmail.com',
    phone: '08143210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'East Timor',
    plan: 'Platinium',
  },
  {
    name: 'Savannah Brown',
    email: 'savannahbrown@gmail.com',
    phone: '09132109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Papua New Guinea',
    plan: 'Platinium',
  },
  {
    name: 'Hunter Martinez',
    email: 'huntermartinez@gmail.com',
    phone: '07021098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Solomon Islands',
    plan: 'Platinium',
  },
  {
    name: 'Aubrey Harris',
    email: 'aubreyharris@gmail.com',
    phone: '08110987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Vanuatu',
    plan: 'Platinium',
  },
  {
    name: 'Lincoln Walker',
    email: 'lincolnwalker@gmail.com',
    phone: '09109876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Fiji',
    plan: 'Platinium',
  },
  {
    name: 'Lily Turner',
    email: 'lilyturner@gmail.com',
    phone: '07098765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Samoa',
    plan: 'Platinium',
  },
  {
    name: 'Isaiah Thompson',
    email: 'isaiahthompson@gmail.com',
    phone: '08187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Tonga',
    plan: 'Platinium',
  },
  {
    name: 'Aria Brown',
    email: 'ariabrown@gmail.com',
    phone: '09176543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'New Zealand',
    plan: 'Platinium',
  },
  {
    name: 'Eli Davis',
    email: 'elidavis@gmail.com',
    phone: '07065432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Australia',
    plan: 'Platinium',
  },
  {
    name: 'Camila Martinez',
    email: 'camilamartinez@gmail.com',
    phone: '08154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'New Caledonia',
    plan: 'Platinium',
  },
  {
    name: 'Nolan Harris',
    email: 'nolanharris@gmail.com',
    phone: '09143210987',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'French Polynesia',
    plan: 'Platinium',
  },
  {
    name: 'Hannah Walker',
    email: 'hannahwalker@gmail.com',
    phone: '07032109876',
    role: 'Admin',
    status: 'Blocked',
    userImage: '',
    country: 'Wallis and Futuna',
    plan: 'Platinium',
  },
  {
    name: 'Leo Turner',
    email: 'leoturner@gmail.com',
    phone: '08121098765',
    role: 'User',
    status: 'Active',
    userImage: '',
    country: 'Tuvalu',
    plan: 'Platinium',
  },
  {
    name: 'Stella Hill',
    email: 'stellahill@gmail.com',
    phone: '09110987654',
    role: 'Admin',
    status: 'Suspended',
    userImage: '',
    country: 'Nauru',
    plan: 'Platinium',
  },
  {
    name: 'Dylan Brown',
    email: 'dylanbrown@gmail.com',
    phone: '07009876543',
    role: 'User',
    status: 'Inactive',
    userImage: '',
    country: 'Kiribati',
    plan: 'Platinium',
  },
  {
    name: 'Victoria Johnson',
    email: 'victoriajohnson@gmail.com',
    phone: '08198765432',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Marshall Islands',
    plan: 'Platinium',
  },
  {
    name: 'Eliana Adams',
    email: 'elianaadams@gmail.com',
    phone: '09187654321',
    role: 'User',
    status: 'Blocked',
    userImage: '',
    country: 'Micronesia',
    plan: 'Platinium',
  },
  {
    name: 'Landon Wilson',
    email: 'landonwilson@gmail.com',
    phone: '07076543210',
    role: 'Admin',
    status: 'Active',
    userImage: '',
    country: 'Palau',
    plan: 'Platinium',
  },
  {
    name: 'Penelope Lee',
    email: 'penelopelee@gmail.com',
    phone: '08165432109',
    role: 'User',
    status: 'Suspended',
    userImage: '',
    country: 'Guam',
    plan: 'Platinium',
  },
  {
    name: 'Logan Anderson',
    email: 'logananderson@gmail.com',
    phone: '09154321098',
    role: 'Admin',
    status: 'Inactive',
    userImage: '',
    country: 'Northern Mariana Islands',
    plan: 'Platinium',
  },
];
