export class ENV {
  static FIREBASE_API_KEY = 'AIzaSyAmeC0ODrfbLzsbs9VnOEoXE4xAEnjb7dk';
  static FIREBASE_AUTH_DOMAIN = 'spowse-ee460.firebaseapp.com';
  static FIREBASE_DATA_BASE_URL =
    'https://spowse-ee460-default-rtdb.firebaseio.com';
  static FIREBASE_PROJECT_ID = 'spowse-ee460';
  static FIREBASE_STORAGE_BUDGET = 'spowse-ee460.appspot.com';
  static FIREBASE_MESSAGE_SENDER_ID = '500638183325';
  static FIREBASE_APP_ID = '1:500638183325:web:5900979516605b259e8974';
  static FIREBASE_MEASUREMENT_ID = 'G-92D22TNYRC';
  static NODE_ENV = 'DEVELOPMENT';
  static SPOWSE_ADMIN_APP_URL_DEV = 'https://admin-dev.spowse.io';
  static SPOWSE_ADMIN_APP_URL_PROD = 'https://admin-dev.spowse.io';
}
