import React, { useState } from "react";
import {
  UserManagementContent,
  UserManagementContentWrapper,
  UserManagementHeaderContainer,
  UserManagementWrapper,
} from "./UserManagement_styles";
import Header from "../header/Header";
import SideBar from "../side_bar/SideBar";
import ActiveButton from "../../core/common/active_button";
import { AppIcons } from "../../core/utilities/app_icons";
import Overview from "./widgets/overview/Overview";
import Roles from "./widgets/roles/Roles";
import Permission from "./widgets/permission/Permission";

type UserManagementProps = {};

const UserManagement: React.FC<UserManagementProps> = () => {
  const [type, setTypeName] = useState("overview");

  const handleTypeSet = (type: string) => {
    setTypeName(type);
  }

  return (
    <UserManagementWrapper>
      <Header headingText="User Management" />
      <SideBar />
      <UserManagementContentWrapper>
        <UserManagementContent>
          <UserManagementHeaderContainer>
            <ActiveButton
              type={type}
              icon={AppIcons.userOverview}
              buttonText='Overview'
              onClick={() => { handleTypeSet("overview") }}
              activeText='overview'
              width="220px"
            />
            <ActiveButton type={type}
              icon={AppIcons.userRolesIcon}
              buttonText='Roles'
              onClick={() => { handleTypeSet("roles") }}
              activeText='roles' />
            <ActiveButton type={type}
              icon={AppIcons.permissionsIcon}
              buttonText='Permissions'
              onClick={() => { handleTypeSet("permissions") }}
              activeText='permissions' />
          </UserManagementHeaderContainer>
          {
            type === "overview" ? <Overview /> : type === "roles" ? <Roles /> : <Permission />
          }
        </UserManagementContent>
      </UserManagementContentWrapper>
    </UserManagementWrapper>
  );
};
export default UserManagement;
