import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const UserTypeWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const UserTypeContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 7px;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

export const UserTypeContainerFisrstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const UserTypeIconContainer = styled.div<{ background: string }>`
  background: ${(probs) => probs.background};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 110px;
  border: 0;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.linkInActiveTextColor};
  font-family: "matter-medium";
  font-weight: 500;
`;

export const Option = styled.option``;

export const UserTypeContainerThirdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RateContainer = styled.div`
  background: #e7f6ec;
  width: 28px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;
