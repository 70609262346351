import React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { theme } from '../../../theme/theme';

type SpinnerLoaderProps = {

};

const SpinnerLoaderContainer = styled.div`
  background: rgba(0, 0,0, 0.15);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100vh;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 999999;
`;

const SpinnerLoader: React.FC<SpinnerLoaderProps> = () => {

  return <SpinnerLoaderContainer>
    <ReactLoading type="spinningBubbles" color={`${theme.colors.primary}`} height={'40px'} width={'40px'} />
  </SpinnerLoaderContainer>
}
export default SpinnerLoader;