import React from "react";
import {
  SubscriptionCardDataContainer,
  SubscriptionCardFirstChild,
  SubscriptionCardWrapper,
} from "./SubscriptionCard_styles";
import { CommonText } from "../../../../core/common/reuseables/text_styles";
import { SpowseButton } from "../../../../core/common/reuseables/spowse_button";
import { theme } from "../../../../theme/theme";

interface DataProbs {
  value: string;
  price: string;
}

type SubscriptionCardProps = {
  data: DataProbs[];
  onTap: any;
  subscriptionType: string;
};

const SubscriptionCard: React.FC<SubscriptionCardProps> = ({
  data,
  onTap,
  subscriptionType,
}) => {
  return (
    <SubscriptionCardWrapper>
      <SubscriptionCardFirstChild>
        <CommonText
          fontSize="16px"
          fontFamily="matter-medium"
          fontWeight="500"
          color={`${theme.colors.linkActiveTextColor}`}
        >
          {subscriptionType}
        </CommonText>
        <SpowseButton
          onClick={onTap}
          backgroundColor="transparent"
          width="94px"
          height="32px"
          border={`1px solid ${theme.colors.borderColor}`}
          borderRadius="16px"
          fontSize="14px"
          fontFamily="matter-regular"
          fontWeight="400"
          textColor={`${theme.colors.linkInActiveTextColor}`}
        >
          Edit
        </SpowseButton>
      </SubscriptionCardFirstChild>
      {data.map((e, index) => {
        return (
          <SubscriptionCardDataContainer key={index}>
            <CommonText
              fontSize="16px"
              fontFamily="matter-medium"
              fontWeight="500"
              color={`${theme.colors.linkActiveTextColor}`}
            >
              {e.value}
            </CommonText>
            <CommonText
              fontSize="18px"
              fontFamily="athletics-medium"
              fontWeight="700"
              color="#005B36"
            >
              {e.price}
            </CommonText>
          </SubscriptionCardDataContainer>
        );
      })}
    </SubscriptionCardWrapper>
  );
};
export default SubscriptionCard;
