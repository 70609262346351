import React from "react";
import {
  SubscriptionContent,
  SubscriptionContentWrapper,
  SubscriptionWrapper,
} from "./subscriptions_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import SubscriptionLeftSection from "./widgets/Subscription_Left_Section/SubscriptionLeftSection";
import SubscriptionRightSection from "./widgets/Subscription_Right_Section/SubscriptionRightSection";

type SubscriptionProps = {};

const Subscription: React.FC<SubscriptionProps> = () => {
  return (
    <SubscriptionWrapper>
      <SideBar />
      <Header headingText="Subscriptions" />
      <SubscriptionContentWrapper>
        <SubscriptionContent>
          <SubscriptionLeftSection />
          <SubscriptionRightSection />
        </SubscriptionContent>
      </SubscriptionContentWrapper>
    </SubscriptionWrapper>
  );
};
export default Subscription;
