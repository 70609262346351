import { Link } from "react-router-dom";
import styled from "styled-components";
import { theme } from "../../../theme/theme";
interface TextProbs {
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  lineHeight?: string;
  fontFamily?: any;
  letterSpacing?: any;
}
export const Heading1 = styled.h1<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "2rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "athletics-bold"};
  line-height: ${(props) => props.lineHeight};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;

export const Heading2 = styled.h2<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1.5rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "athletics-bold"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;

export const Heading3 = styled.h3<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1.25rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "athletics-bold"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;

export const Heading4 = styled.h4<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1.1rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "athletics-bold"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;

export const CommonText = styled.p<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "regular"};
  line-height: ${(props) => props.lineHeight};
  font-family: ${(probs: any) => probs.fontFamily || "matter-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;

export const LinkText = styled(Link)<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "1rem"};
  font-weight: ${(props) => props.fontWeight || "regular"};
  line-height: ${(props) => props.lineHeight};
  font-family: ${(probs: any) => probs.fontFamily || "matter-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  text-decoration: none;

  &:hover {
    color: ${theme.colors.lightGreen};
    transition: all 300ms ease-in;
  }
`;

export const SpanText = styled.span<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "2rem"};
  font-weight: ${(props) => props.fontWeight || "regular"};
  font-family: ${(probs: any) => probs.fontFamily || "matter-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
  line-height: ${(props) => props.lineHeight};
`;

export const Heading6 = styled.h6<TextProbs>`
  color: ${(props) => props.color || "black"};
  font-size: ${(props) => props.fontSize || "0.875rem"};
  font-weight: ${(props) => props.fontWeight || "bold"};
  font-family: ${(probs: any) => probs.fontFamily || "matter-regular"};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.2px"};
`;
