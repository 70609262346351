import React, { useState } from 'react';
import { FollowersAndReachedAudienceContainer, FollowersChartContainer, GenderConntainer, GenderConntainerWrapper, OptionContainer, PaginationWrapper, PurpolarPostListSearchbarContainer, PurpolarPostListTableHeader, PurpolarPostListTableHeaderText, PurpolarPostListTableText, PurpolarPostListWrapper, RateContainer, ReachedAudienceContainer, SocialMediaMarketingLeftSection, SocialMediaMarketingRightSection, SocialMediaMarketingRightSectionCard, SocialMediaMarketingRightSectionCardContainer, SocialMediaMarketingRightSectionCardFirstChild, SocialMediaMarketingRightSectionCardThirdChild, SocialMediaMarketingRightSectionCardWrapper, SocialMediaMarketingWrapper, TableTr } from './SocialMediaMarketin_style';
import { AppIcons } from '../../../../core/utilities/app_icons';
import ActiveButton from '../../../../core/common/active_button';
import { purpularPostList, purpularPostListHeaderText, socialMediaCard } from '../../controllers/marketing_config';
import { CommonText, Heading1, SpanText } from '../../../../core/common/reuseables/text_styles';
import { SpowseImage } from '../../../../core/common/reuseables/spowse_image';
import { theme } from '../../../../theme/theme';
import { Bar, BarChart, CartesianGrid, Cell, Pie, PieChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { overviewTotalNewsletterChart } from '../../../campaigns/controller/campaign_config';
import SpowseTextField from '../../../../core/common/reuseables/spowse_textfield';
import ReactPaginate from 'react-paginate';
import { UserOptionContainer } from '../../../User_logs/UserLogs_styles';
import { SpowseButton } from '../../../../core/common/reuseables/spowse_button';
import { SearchAndButtonContainer } from '../../../campaigns/widdets/campaign_section/CampignSection_styles';

type SocialMediaMarketingProps = {

};

const SocialMediaMarketing: React.FC<SocialMediaMarketingProps> = () => {
  const [socialMediaName, setSocialMediaName] = useState("twitter");
  const [currentPage, setCurrentPage] = useState(0);
  const purpularPostPerPage = 10;

  const handlePageClick = (data: any) => {
    setCurrentPage(data.selected);
  };

  const displayePurpularPost = purpularPostList.slice(
    currentPage * purpularPostPerPage,
    (currentPage + 1) * purpularPostPerPage
  );

  const handleTypeSet = (type: string) => {
    setSocialMediaName(type);
  }

  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                M${x},${y + height}
                L${x},${y + radius}
                Q${x},${y} ${x + radius},${y}
                L${x + width - radius},${y}
                Q${x + width},${y} ${x + width},${y + radius}
                L${x + width},${y + height}
                Z
              `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    if (value >= 1_000_000_000) {
      return `${(value / 1_000_000_000).toFixed(1)}B`;
    } else if (value >= 1_000_000) {
      return `${(value / 1_000_000).toFixed(1)}M`;
    } else if (value >= 1_000) {
      return `${(value / 1_000).toFixed(1)}k`;
    }
    return value.toString();
  };

  const data = [
    { name: 'Group A', value: 400 },
    { name: 'Group B', value: 300 },
  ];

  const COLORS = ['#165dff', '#50CD89'];

  return <SocialMediaMarketingWrapper>
    <SocialMediaMarketingLeftSection>
      <ActiveButton
        type={socialMediaName}
        icon={AppIcons.twitter}
        buttonText='Twitter'
        onClick={() => { handleTypeSet("twitter") }}
        activeText='twitter' width='90%'
      />
      <ActiveButton type={socialMediaName}
        icon={AppIcons.instagram}
        buttonText='Instagram'
        onClick={() => { handleTypeSet("instagram") }}
        activeText='instagram' width='90%' />
      <ActiveButton type={socialMediaName}
        icon={AppIcons.youtube}
        buttonText='Youtube'
        onClick={() => { handleTypeSet("youtube") }}
        activeText='youtube' width='90%' />
      <ActiveButton type={socialMediaName}
        icon={AppIcons.linkedin}
        buttonText='LinkedIn'
        onClick={() => { handleTypeSet("linkedin") }}
        activeText='linkedin' width='90%' />
    </SocialMediaMarketingLeftSection>
    <SocialMediaMarketingRightSection>
      <SocialMediaMarketingRightSectionCardContainer>
        {
          socialMediaCard.map((s, index) => {
            return <SocialMediaMarketingRightSectionCard key={index}>
              <SocialMediaMarketingRightSectionCardFirstChild>
                <CommonText fontSize='14px' fontWeight='500' color={`${theme.colors.linkInActiveTextColor}`}>
                  {s.type}
                </CommonText>
                <SpowseImage src={AppIcons.optionIcon2} alt='icon' width='auto' />
              </SocialMediaMarketingRightSectionCardFirstChild>
              <SocialMediaMarketingRightSectionCardWrapper>
                <Heading1 fontSize='24px' fontWeight='700'>{s.number}</Heading1>
                <SocialMediaMarketingRightSectionCardThirdChild>
                  <RateContainer>
                    {s.rate}
                  </RateContainer>
                  <CommonText fontSize='12px'
                    fontWeight='500'
                    color={`${theme.colors.linkInActiveTextColor}`}>{
                      s.text}
                  </CommonText>
                </SocialMediaMarketingRightSectionCardThirdChild>
              </SocialMediaMarketingRightSectionCardWrapper>
            </SocialMediaMarketingRightSectionCard>
          })
        }
      </SocialMediaMarketingRightSectionCardContainer>
      {/* followers and reeached audience */}
      <FollowersAndReachedAudienceContainer>
        <FollowersChartContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-medium"
          >
            Followers
          </CommonText>
          <CommonText fontSize="12px" fontWeight="400" color="#475467">
            Count
          </CommonText>
          <ResponsiveContainer width="100%" height="90%">
            <BarChart data={overviewTotalNewsletterChart}>
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis dataKey="name" fontSize="12px" tickLine={false} />
              <YAxis
                fontSize="12px"
                width={35}
                tickLine={false}
                axisLine={false}
                tickFormatter={formatValue}
              />
              <Tooltip cursor={false} />
              <Bar
                dataKey="pv"
                fill="#165DFF"
                shape={<CustomBar />}
                barSize={11}
              />
            </BarChart>
          </ResponsiveContainer>
        </FollowersChartContainer>
        <ReachedAudienceContainer>
          <CommonText
            fontSize="20px"
            fontWeight="500"
            color={`${theme.colors.linkActiveTextColor}`}
            fontFamily="matter-medium"
          >
            Reached audience
          </CommonText>
          <ResponsiveContainer width="100%" height="70%">
            <PieChart>
              <Pie
                data={data}
                cx="50%"
                cy="50%"
                innerRadius={88}
                outerRadius={110}
                paddingAngle={0}
                dataKey="value"

              >
                {data.map((entry, index) => (
                  <Cell key={`cell-${index}${entry.name}`} fill={COLORS[index % COLORS.length]} />
                ))}

              </Pie>
              <g>
                <text x="50%" y="50%" textAnchor="middle" dominantBaseline="middle" fontSize={12} fontWeight="400" color={`${theme.colors.linkInActiveTextColor}`}>
                  Total
                </text>
                <text x="50%" y="60%" textAnchor="middle" dominantBaseline="middle" fontSize={24} fontWeight="bold" fontFamily=''>
                  40,000
                </text>
              </g>
            </PieChart>
          </ResponsiveContainer>
          <GenderConntainerWrapper>
            <GenderConntainer>
              <div></div>
              <CommonText fontSize='14px' fontWeight='400' color={`${theme.colors.linkInActiveTextColor}`}>Men</CommonText>
              <CommonText fontSize='20px' fontWeight='500'>
                50
                <SpanText fontSize='12px' fontWeight='400' color={`${theme.colors.linkInActiveTextColor}`}>%</SpanText>
              </CommonText>
            </GenderConntainer>
            <GenderConntainer background='#50CD89'>
              <div></div>
              <CommonText fontSize='14px' fontWeight='400' color={`${theme.colors.linkInActiveTextColor}`}>Women</CommonText>
              <CommonText fontSize='20px' fontWeight='500'>
                50
                <SpanText fontSize='12px' fontWeight='400' color={`${theme.colors.linkInActiveTextColor}`}>%</SpanText>
              </CommonText>
            </GenderConntainer>
          </GenderConntainerWrapper>
        </ReachedAudienceContainer>
      </FollowersAndReachedAudienceContainer>

      <PurpolarPostListWrapper>
        <PurpolarPostListSearchbarContainer>
          <CommonText
            fontSize="18px"
            color={`${theme.colors.linkActiveTextColor}`}
            fontWeight="500"
          >
            All Email Performances
          </CommonText>
          <SearchAndButtonContainer>
            <SpowseTextField
              placeHolder="Search"
              width="320px"
              height="45px"
              borderColor="transparent"
              prefixIxon={
                <SpowseImage
                  src={AppIcons.searchIcon}
                  alt="search icon"
                  width="auto"
                />
              }
            />
            <SpowseButton
              width="135px"
              height="38px"
              fontSize="14px"
              fontWeight="400"
              fontFamily="matter-light"
            >
              Create Post
            </SpowseButton>
          </SearchAndButtonContainer>
        </PurpolarPostListSearchbarContainer>
        <table
          style={{
            width: "100%",
            borderCollapse: "collapse",
            border: "0",
            outline: "none",
            marginTop: "2rem",
          }}
        >
          <PurpolarPostListTableHeader>
            <tr>
              {purpularPostListHeaderText.map((e, index) => {
                return (
                  <PurpolarPostListTableHeaderText
                    key={index}
                    style={{
                      width: index === 0 ? "100px" : "auto",
                      paddingLeft: index === 0 ? "1.2rem" : "9px",
                      fontWeight: "600"
                    }}
                  >
                    {e}
                  </PurpolarPostListTableHeaderText>
                );
              })}
            </tr>
          </PurpolarPostListTableHeader>
          <tbody>
            {displayePurpularPost.map((row, index) => (
              <TableTr key={index}>
                <PurpolarPostListTableText
                  style={{ width: "100px", paddingLeft: "1.2rem" }}
                >
                  {index + 1}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText style={{ fontWeight: "600" }}>
                  {row.name}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText style={{ width: "200px" }}>
                  {row.date}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText style={{ width: "130px" }}>
                  {row.views}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText style={{ width: "130px" }}>
                  {row.likes}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText>
                  {row.comments}
                </PurpolarPostListTableText>
                <PurpolarPostListTableText>
                  <UserOptionContainer>
                    <OptionContainer>
                      <SpowseImage
                        src={AppIcons.optionIcon}
                        alt="img"
                        width="auto"
                      />
                    </OptionContainer>
                  </UserOptionContainer>
                </PurpolarPostListTableText>
              </TableTr>
            ))}
          </tbody>
        </table>
        <PaginationWrapper>
          <ReactPaginate
            previousLabel={`🡠 Previous`}
            nextLabel={"Next 🡢"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={Math.ceil(purpularPostList.length / purpularPostPerPage)}
            marginPagesDisplayed={2}
            pageRangeDisplayed={5}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            nextClassName="n"
            activeClassName={"activeClass"}
          />
        </PaginationWrapper>
      </PurpolarPostListWrapper>
    </SocialMediaMarketingRightSection>
  </SocialMediaMarketingWrapper >
}
export default SocialMediaMarketing;