import styled from 'styled-components';
import { theme } from '../../../../theme/theme';

export const SocialMediaMarketingWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const SocialMediaMarketingLeftSection = styled.div`
  width: 16%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-right: 1px solid ${theme.colors.borderColor};
  padding: 1.5rem;
  gap: 2rem;
`;

export const SocialMediaMarketingRightSection = styled.div`
  width: 84%;
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  margin-bottom: 1rem;
`;

export const SocialMediaMarketingRightSectionCardContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const SocialMediaMarketingRightSectionCard = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
`;

export const SocialMediaMarketingRightSectionCardWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 10px;

  & > :nth-child(1) {
    margin-top: 1.5rem;
  }
`;

export const SocialMediaMarketingRightSectionCardFirstChild = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid ${theme.colors.borderColor};
  padding: 10px 10px;
`;

export const SocialMediaMarketingRightSectionCardThirdChild = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 5px;
  margin-bottom: 1rem;
  gap: 10px;
`;

export const RateContainer = styled.div`
  display: flex;
  font-size: 12px;
  font-weight: 500;
  font-family: 'matter-medium';
  color: #0f973d;
  width: 38px;
  height: 17px;
  align-items: center;
  justify-content: center;
  background-color: #e7f6ec;
  border-radius: 8px;
`;

// followers an reached audience styles
export const FollowersAndReachedAudienceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 1.5rem;
`;

export const FollowersChartContainer = styled.div<{ width?: string }>`
  width: ${(probs) => probs.width || '55%'};
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    margin-bottom: 1rem;
  }

  & > :nth-child(2) {
    margin-bottom: 10px;
  }
`;

export const ReachedAudienceContainer = styled.div`
  width: 43%;
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  display: flex;
  flex-direction: column;

  & > :nth-child(1) {
    margin-bottom: 1rem;
  }

  & > :nth-child(2) {
    margin-bottom: 10px;
  }
`;

export const GenderConntainerWrapper = styled.div`
  display: flex;
  align-self: center;
  justify-self: center;
  align-items: center;
  gap: 1rem;
`;

export const GenderConntainer = styled.div<{ background?: string }>`
  display: flex;
  align-items: center;

  & > :nth-child(1) {
    height: 30px;
    width: 4px;
    background-color: ${(probs) => probs.background || '#165dff'};
    border-radius: 4px;
    margin-right: 10px;
  }

  & > :nth-child(2) {
    margin-right: 3rem;
  }
`;


// purpolar post styles
export const PurpolarPostListWrapper = styled.div`
  width: 100%;
  display: flex;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 10px;
  padding: 1.2rem 0;
  flex-direction: column;
  margin-top: 2rem;
`;

export const PurpolarPostListSearchbarContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1.5rem;
`;

//table styles
export const PurpolarPostListTableHeader = styled.thead`
  background: #f2f4f7;
`;

export const PurpolarPostListTableHeaderText = styled.th`
  background: #f2f4f7;
  padding: 1.2rem 9px;
  text-align: start;
  font-size: 12px;
  font-weight: 500;
  color: ${theme.colors.linkInActiveTextColor};
`;

export const PurpolarPostListTableText = styled.td`
  padding: 1rem 9px;
  text-align: start;
  border-bottom: 1px solid ${theme.colors.borderColor};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 400;
  font-size: 14px;
  color: ${theme.colors.linkActiveTextColor};
`;

export const PurpolarPostDetailsWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  align-items: center;

  & .ellipsis-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    font-weight: 400;
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
  }
`;

export const PurpolarPostProfilePicsAndNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const PurpolarPostStatusContainer = styled.div<{ status?: string }>`
  background: #e4fff7;
  padding: 5px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: ${(probs) =>
    probs.status === "Good"
      ? "#0a9341"
      : probs.status === "Blocked" || probs.status === "Fair"
      ? "#F50707"
      : "#475467"};
  width: ${(probs) => (probs.status === "Excellent" ? "75px" : "50px")};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) =>
    probs.status === "Good"
      ? "#E4FFF7"
      : probs.status === "Blocked" || probs.status === "Fair"
      ? "#FEE6E6"
      : "#EAECF0"};
`;

export const PurpolarPostOptionContainer = styled.div`
  width: 100px;
  display: flex;
  align-items: end;
  justify-content: end;
  padding-right: 10px;
`;

export const OptionContainer = styled.div`
  width: 34px;
  height: 36px;
  border: 1px solid ${theme.colors.borderColor};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  cursor: pointer;
`;

export const PurpolarPostImageContainer = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 100%;
  background: black;
  font-size: 8px;
  font-weight: 300;
  font-family: "Inter";
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TableTr = styled.tr``;

//pagination styles

export const PaginationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: end;
  justify-content: end;
  margin-top: 1rem;

  & .pagination {
    border: 1px solid ${theme.colors.borderColor};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.3rem;
    list-style: none;
  }

  & .pagination li {
    height: 40px;
    width: 40px;
    border-left: 1px solid ${theme.colors.borderColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    font-family: "matter-bold";
    font-size: 14px;
    color: ${theme.colors.linkActiveTextColor};
    font-weight: 600;
  }

  & .pagination > :nth-child(1) {
    width: 100px;
  }

  & .n {
    width: 100px !important;
  }

  & .activeClass {
    background: #eaecf0;
  }
`;

