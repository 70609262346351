import CryptoJS from 'crypto-js';
import { VerifyLoginEmailResponse } from '../../logic/responnse/verify_login_email_response';
import { SecureDataBaseUrl } from './secure_database_key';

//set admin data
export const setSecureItem = (key: string, data: VerifyLoginEmailResponse) => {
  const encryptedData = CryptoJS.AES.encrypt(
    JSON.stringify(data),
    SecureDataBaseUrl.USER_DATA_SECRET,
  ).toString();
  localStorage.setItem(key, encryptedData);
};

//! get admin data
export const getSecureItem = (key: string) => {
  const encryptedData = localStorage.getItem(key);
  if (!encryptedData) {
    return null;
  }

  try {
    const bytes = CryptoJS.AES.decrypt(
      encryptedData,
      SecureDataBaseUrl.USER_DATA_SECRET,
    );
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    console.error('Error decrypting or parsing data:', error);
    return null;
  }
};
