import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const OverviewWrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const CampaignCardSectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minMax(200px, 1fr));
  column-gap: 1rem;
  margin-top: 1rem;
`;

export const CampaignCardContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  border: 1px solid ${theme.colors.borderColor};
  border-radius: 7px;
  padding: 10px;
  flex-direction: column;
  gap: 10px;
`;

export const CampaignCardContainerFisrstRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

export const CampaignCardIconContainer = styled.div<{ background: string }>`
  background: ${(probs) => probs.background};
  width: 40px;
  height: 40px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Select = styled.select`
  width: 110px;
  border: 0;
  outline: none;
  font-size: 14px;
  color: ${theme.colors.linkInActiveTextColor};
  font-family: "matter-medium";
  font-weight: 500;
`;

export const Option = styled.option``;

export const CampaignCardContainerThirdRow = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
`;

export const RateContainer = styled.div`
  background: #e7f6ec;
  width: 28px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
`;

//! campaigns and total newsletter styles
export const CampaignAndNewsletterContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 1.5rem;
  justify-content: space-between;
`;

export const CampaignChartContainer = styled.div`
  width: 49%;
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  & > :nth-child(1) {
    margin-bottom: 1rem;
  }

  @media (min-width: 1400px) {
    & > :nth-child(2) {
      padding-left: 2rem;
    }
  }
`;

export const TotalNewsletterChartContainer = styled.div<{ width?: string }>`
  width: ${(probs) => probs.width || "49%"};
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;

  & > :nth-child(1) {
    margin-bottom: 1rem;
  }

  & > :nth-child(2) {
    margin-bottom: 10px;
  }
`;

// partnership styles
export const PartnerShipContainer = styled.div`
  width: 100%;
  height: 400px;
  border: 1px solid ${theme.colors.borderColor};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  margin-top: 1.5rem;
`;

export const PartnerShipContainerFirstChild = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1.5rem;
  margin-bottom: 10px;
  padding: 0 10px;

  & > :nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 1rem;
  }
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`;

export const StatusCircularContainer = styled.div<{ backgroundColor: string }>`
  width: 12px;
  height: 12px;
  background: ${(probs) => probs.backgroundColor};
  border-radius: 100%;
`;
