import styled from "styled-components";
import { theme } from "../../../theme/theme";

interface ButtonProbs {
  width?: any;
  height?: any;
  backgroundColor?: any;
  fontSize?: any;
  textColor?: any;
  fontFamily?: string;
  border?: string;
  borderRadius?: any;
  letterSpacing?: any;
  lineHeight?: any;
  fontWeight?: string;
}

export const SpowseButton = styled.button<ButtonProbs>`
  width: ${(probs: any) => probs.width || "198px"};
  height: ${(probs: any) => probs.height || "52px"};
  background: ${(probs: any) =>
    probs.backgroundColor || `${theme.colors.secondary}`};
  font-size: ${(probs: any) => probs.fontSize || "16px"};
  font-family: ${(probs: any) => probs.fontFamily || "matter-regular"};
  color: ${(probs: any) => probs.textColor || `${theme.colors.white}`};
  transition: 300ms all ease-in-out;
  border: ${(probs: any) => probs.border || `2px solid ${theme.colors.white}`};
  line-height: ${(probs) => probs.lineHeight};
  letter-spacing: ${(probs) => probs.letterSpacing || "0.8px"};
  font-weight: ${(probs) => probs.fontWeight || "400"};
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${(probs: any) => probs.borderRadius || "30px"};
  text-align: center;
  &:hover {
    transform: scale(0.99);
  }

  &:active {
    transform: scale(0.97);
  }
`;
