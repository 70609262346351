import React from "react";
import {
  RevenueContent,
  RevenueContentWrapper,
  RevenueWrapper,
  Option,
  RateContainer,
  RevenueCardContainer,
  RevenueCardContainerFisrstRow,
  RevenueCardContainerThirdRow,
  RevenueCardIconContainer,
  RevenueCardSectionWrapper,
  Select,
  RevenueAreaChartContainer,
  RevenueByAgeAndCountryContainer,
  RevenueMadeByAgeContainer,
  RevenueMadeByCountryContainer,
  ProgressWrapper,
  ProgressContainer,
  ProgressAndPercentContainer,
  Progress,
} from "./Revenue_styles";
import SideBar from "../side_bar/SideBar";
import Header from "../header/Header";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { Heading1, CommonText } from "../../core/common/reuseables/text_styles";
import { theme } from "../../theme/theme";
import {
  countries,
  revenueCardDetails,
  revenueData,
  revenueMadeByAge,
} from "./controller/revenue_config";
import {
  ResponsiveContainer,
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Area,
  BarChart,
  Bar,
} from "recharts";
import { CountryFlagContainer } from "../dashboard/widgets/Active_user_right_now/ActiveUsersRightNow_styles";

type RevenueProps = {};

const Revenue: React.FC<RevenueProps> = () => {
  const CustomBar = (props: any) => {
    const { x, y, width, height, fill } = props;
    const radius = 5;
    return (
      <g>
        <path
          d={`
                    M${x},${y + height}
                    L${x},${y + radius}
                    Q${x},${y} ${x + radius},${y}
                    L${x + width - radius},${y}
                    Q${x + width},${y} ${x + width},${y + radius}
                    L${x + width},${y + height}
                    Z
                  `}
          fill={fill}
        />
      </g>
    );
  };

  const formatValue = (value: number) => {
    return value.toLocaleString();
  };
  return (
    <RevenueWrapper>
      <SideBar />
      <Header headingText="Revenue" />
      <RevenueContentWrapper>
        <RevenueContent>
          <RevenueCardSectionWrapper>
            {revenueCardDetails.map((value, index) => {
              return (
                <RevenueCardContainer key={index}>
                  <RevenueCardContainerFisrstRow>
                    <RevenueCardIconContainer
                      background={value.backgroundColor}
                    >
                      <SpowseImage src={value.icon} alt="img" width="auto" />
                    </RevenueCardIconContainer>
                    <Select id="options" name="options">
                      <Option value="This month">This month</Option>
                      <Option value="Today">Today</Option>
                      <Option value="7 day ago">7 day ago</Option>
                      <Option value="Last month">Last month</Option>
                    </Select>
                  </RevenueCardContainerFisrstRow>
                  <Heading1
                    fontFamily="matter-bold"
                    fontWeight="600"
                    color={`${theme.colors.linkActiveTextColor}`}
                    fontSize="20px"
                    lineHeight="30px"
                  >
                    {value.amount}
                  </Heading1>
                  <RevenueCardContainerThirdRow>
                    <CommonText
                      fontFamily="matter-medium"
                      fontWeight="500"
                      color={`${theme.colors.linkInActiveTextColor}`}
                      fontSize="14px"
                      lineHeight="20px"
                    >
                      {value.revenueType}
                    </CommonText>
                    <RateContainer>
                      <CommonText
                        fontFamily="matter-medium"
                        fontWeight="500"
                        fontSize="12px"
                        lineHeight="18px"
                        color="#0F973D"
                      >
                        {value.rate}
                      </CommonText>
                    </RateContainer>
                  </RevenueCardContainerThirdRow>
                </RevenueCardContainer>
              );
            })}
          </RevenueCardSectionWrapper>
          <RevenueAreaChartContainer>
            <CommonText
              fontSize="20px"
              fontWeight="500"
              color={`${theme.colors.linkActiveTextColor}`}
              fontFamily="matter-medium"
            >
              Revenue
            </CommonText>
            <CommonText
              fontSize="12px"
              fontWeight="400"
              color={`${theme.colors.linkInActiveTextColor}`}
              fontFamily="matter-regular"
            >
              Amount
            </CommonText>
            <ResponsiveContainer width="100%" height="80%">
              <AreaChart
                data={revenueData}
                margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
              >
                <defs>
                  <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="5%" stopColor="#165DFF" stopOpacity={0.2} />
                    <stop offset="85%" stopColor="#165DFF" stopOpacity={0} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="name"
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily="matter-light"
                  color="#475467"
                  padding={{ left: 0 }}
                  tickLine={false}
                />
                <YAxis
                  domain={[0, 10000]}
                  fontSize="12px"
                  fontWeight="400"
                  fontFamily="matter-light"
                  color="#475467"
                  tickLine={false}
                  axisLine={false}
                  width={45}
                />
                <CartesianGrid strokeDasharray="3 3" vertical={false} />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="uv"
                  stroke="#165DFF"
                  fillOpacity={1}
                  fill="url(#colorUv)"
                  strokeWidth={1.5}
                />
              </AreaChart>
            </ResponsiveContainer>
          </RevenueAreaChartContainer>
          <RevenueByAgeAndCountryContainer>
            <RevenueMadeByAgeContainer>
              <CommonText
                fontSize="20px"
                fontWeight="500"
                color={`${theme.colors.linkActiveTextColor}`}
                fontFamily="matter-medium"
              >
                Revenue Made By Age
              </CommonText>
              <CommonText
                fontSize="12px"
                fontWeight="400"
                color={`${theme.colors.linkInActiveTextColor}`}
                fontFamily="matter-regular"
              >
                Amount spent
              </CommonText>
              <ResponsiveContainer width="100%" height="90%">
                <BarChart data={revenueMadeByAge}>
                  <CartesianGrid strokeDasharray="3 3" vertical={false} />
                  <XAxis dataKey="name" fontSize="12px" tickLine={false} />
                  <YAxis
                    fontSize="12px"
                    width={35}
                    tickLine={false}
                    axisLine={false}
                    tickFormatter={formatValue}
                  />
                  <Tooltip cursor={false} />
                  <Bar
                    dataKey="pv"
                    fill="#165DFF"
                    shape={<CustomBar />}
                    barSize={54}
                  />
                </BarChart>
              </ResponsiveContainer>
            </RevenueMadeByAgeContainer>
            <RevenueMadeByCountryContainer>
              <CommonText
                fontSize="20px"
                fontWeight="500"
                color={`${theme.colors.linkActiveTextColor}`}
                fontFamily="matter-medium"
              >
                Revenue Made By Country
              </CommonText>
              <Heading1
                fontSize="36px"
                fontWeight="600"
                fontFamily="matter-bold"
                lineHeight="44px"
                color={`${theme.colors.linkActiveTextColor}`}
              >
                10.8k
              </Heading1>
              {countries.map((country, index) => {
                return (
                  <ProgressWrapper key={index}>
                    <CountryFlagContainer backgroundImage={country.flag} />
                    <ProgressContainer>
                      <CommonText
                        fontSize="14px"
                        fontWeight="600"
                        fontFamily="Inter"
                        lineHeight="18px"
                        color={`${theme.colors.linkActiveTextColor}`}
                      >
                        {country.name}
                      </CommonText>
                      <ProgressAndPercentContainer>
                        <Progress
                          completed={country.progress}
                          bgColor="#099838"
                          maxCompleted={10000}
                          isLabelVisible={false}
                          height="8px"
                          width="250px"
                        />
                        <CommonText
                          fontSize="14px"
                          fontWeight="500"
                          fontFamily="Inter"
                          lineHeight="20px"
                          color={`${theme.colors.linkInActiveTextColor}`}
                        >
                          {country.progress / 100}%
                        </CommonText>
                      </ProgressAndPercentContainer>
                    </ProgressContainer>
                  </ProgressWrapper>
                );
              })}
            </RevenueMadeByCountryContainer>
          </RevenueByAgeAndCountryContainer>
        </RevenueContent>
      </RevenueContentWrapper>
    </RevenueWrapper>
  );
};
export default Revenue;
