import styled from "styled-components";
import { theme } from "../../../../theme/theme";

export const VerifyLoginSectionWrapper = styled.section`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const VerifyLoginSectionContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  max-height: 5000px;
  width: 100%;
`;

//!left sectionstyles
export const VerifyLoginSectionLeftSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.white};
`;

export const VerifyLoginSectionFormArea = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  @media (max-width: 1280px){
    width: 80%;
  }
`;
//! right section styles
export const VerifyLoginSectionRightSection = styled.div`
  width: 50%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${theme.colors.lightBlue};
  overflow: hidden;
`;
