import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  ActiveUsersRightNowContentSection,
  ActiveUsersRightNowContryInfor,
  ActiveUsersRightNowFirstPointer,
  ActiveUsersRightNowLeftSection,
  ActiveUsersRightNowRightSection,
  ActiveUsersRightNowSecondPointer,
  ActiveUsersRightNowThirdPointer,
  ActiveUsersRightNowWrapper,
  CountryFlagContainer,
  Progress,
  ProgressAndPercentContainer,
  ProgressContainer,
  ProgressWrapper,
} from "./ActiveUsersRightNow_styles";
import {
  CommonText,
  Heading1,
} from "../../../../core/common/reuseables/text_styles";
import { theme } from "../../../../theme/theme";
import { Divider } from "../../../../core/common/reuseables/Divider";
import useMediaQuery from "../../../../core/common/use_media_Query";

type ActiveUsersRightNowProps = {};

const ActiveUsersRightNow: React.FC<ActiveUsersRightNowProps> = () => {
  const [index, setIndex] = useState<number>(0);
  const media = useMediaQuery("(max-width: 1500px)");
   const countries = useMemo(
    () => [
      {
        name: "United States",
        flag: "/assets/images/england.jpeg",
        progress: 3560,
      },
      {
        name: "Nigeria",
        flag: "/assets/images/Nigeria.jpeg",
        progress: 3000,
      },
      { name: "Togo", flag: "/assets/images/togo.jpeg", progress: 7000 },
      {
        name: "England",
        flag: "/assets/images/unitedkindom.jpeg",
        progress: 3700,
      },
      { name: "Canada", flag: "/assets/images/canada.jpeg", progress: 300 },
      { name: "Brazil", flag: "/assets/images/brazil.jpeg", progress: 1000 },
      {
        name: "Australia",
        flag: "/assets/images/autralia.jpeg",
        progress: 2000,
      },
      { name: "India", flag: "/assets/images/India.jpeg", progress: 3000 },
      {
        name: "China",
        flag: "/assets/images/china.jpeg",
        progress: 300,
      },
      { name: "Japan", flag: "/assets/images/japan.jpeg", progress: 200 },
    ],
    []
  );

  const containerRef = useRef<HTMLDivElement>(null);
  const [positions, setPositions] = useState<{ top: number; left: number }[]>(
    []
  );

  const isOverlap = useCallback(
    (
      newPos: { top: number; left: number },
      positions: { top: number; left: number }[]
    ) => {
      for (let pos of positions) {
        const distance = Math.sqrt(
          Math.pow(newPos.top - pos.top, 2) +
            Math.pow(newPos.left - pos.left, 2)
        );
        if (distance < 100) {
          return true;
        }
      }
      return false;
    },
    []
  );

  const getRandomPosition = useCallback(
    (
      containerWidth: number,
      containerHeight: number,
      positions: { top: number; left: number }[]
    ) => {
      let newPos;
      do {
        newPos = {
          top: Math.random() * (containerHeight - 20),
          left: Math.random() * (containerWidth - 50),
        };
      } while (isOverlap(newPos, positions));
      return newPos;
    },
    [isOverlap]
  );

  useEffect(() => {
    if (containerRef.current && positions.length === 0) {
      const container = containerRef.current;
      const containerWidth = container.clientWidth;
      const containerHeight = container.clientHeight;
      const newPositions = countries.map(() =>
        getRandomPosition(containerWidth, containerHeight, positions)
      );
      setPositions(newPositions);
    }
  }, [positions.length, countries, getRandomPosition, positions]);

  return (
    <ActiveUsersRightNowWrapper>
      <CommonText
        fontSize="18px"
        fontWeight="600"
        fontFamily="matter-bold"
        lineHeight="28px"
        color={`${theme.colors.linkActiveTextColor}`}
      >
        Active users right now
      </CommonText>
      <Divider height="1px" backgroundColor={`${theme.colors.borderColor}`} />
      <ActiveUsersRightNowContentSection>
        <ActiveUsersRightNowLeftSection>
          <div
            ref={containerRef}
            style={{
              width: "95%",
              height: "95%",
              position: "relative",
            }}
          >
            {positions.length > 0 &&
              countries.map((country, pointerIndex) => (
                <ActiveUsersRightNowFirstPointer
                  onMouseEnter={() => {
                    setIndex((prob) => (prob = pointerIndex + 1));
                  }}
                  onMouseLeave={() => {
                    setIndex(0);
                  }}
                  key={pointerIndex}
                  style={{
                    position: "absolute",
                    top: positions[pointerIndex]?.top,
                    left: positions[pointerIndex]?.left,
                    transform: "translate(-50%, -50%)",
                    zIndex: "5",
                  }}
                >
                  <ActiveUsersRightNowSecondPointer>
                    <ActiveUsersRightNowThirdPointer>
                      <ActiveUsersRightNowContryInfor
                        index={index}
                        pointerIndex={pointerIndex}
                      >
                        <CountryFlagContainer backgroundImage={country.flag} />
                        <CommonText
                          fontSize="12px"
                          fontWeight="600"
                          fontFamily="Inter"
                          lineHeight="18px"
                          color={`${theme.colors.linkActiveTextColor}`}
                        >
                          {country.name}
                        </CommonText>
                      </ActiveUsersRightNowContryInfor>
                    </ActiveUsersRightNowThirdPointer>
                  </ActiveUsersRightNowSecondPointer>
                </ActiveUsersRightNowFirstPointer>
              ))}
          </div>
        </ActiveUsersRightNowLeftSection>
        <ActiveUsersRightNowRightSection>
          <Heading1
            fontSize="36px"
            fontWeight="600"
            fontFamily="matter-bold"
            lineHeight="44px"
            color={`${theme.colors.linkActiveTextColor}`}
          >
            10.8k
          </Heading1>
          {countries.map((country, index) => {
            return (
              <ProgressWrapper>
                <CountryFlagContainer backgroundImage={country.flag} />
                <ProgressContainer>
                  <CommonText
                    fontSize="14px"
                    fontWeight="600"
                    fontFamily="Inter"
                    lineHeight="18px"
                    color={`${theme.colors.linkActiveTextColor}`}
                  >
                    {country.name}
                  </CommonText>
                  <ProgressAndPercentContainer>
                    <Progress
                      completed={country.progress}
                      bgColor="#099838"
                      maxCompleted={10000}
                      isLabelVisible={false}
                      height="8px"
                      width={media ? "200px" : "250px"}
                    />
                    <CommonText
                      fontSize="14px"
                      fontWeight="500"
                      fontFamily="Inter"
                      lineHeight="20px"
                      color={`${theme.colors.linkInActiveTextColor}`}
                    >
                      {country.progress / 100}%
                    </CommonText>
                  </ProgressAndPercentContainer>
                </ProgressContainer>
              </ProgressWrapper>
            );
          })}
        </ActiveUsersRightNowRightSection>
      </ActiveUsersRightNowContentSection>
    </ActiveUsersRightNowWrapper>
  );
};
export default ActiveUsersRightNow;
