import React from "react";
import {
  HeaderContent,
  HeaderContentWrapper,
  HeaderNotificationIconContainer,
  HeaderProfileImageContainer,
  HeaderRightSection,
  HeaderUserContainer,
  HeaderUserNameAndRoleContainer,
  HeaderWrapper,
} from "./Header_styles";
import { CommonText, Heading1 } from "../../core/common/reuseables/text_styles";
import { theme } from "../../theme/theme";
import SpowseTextField from "../../core/common/reuseables/spowse_textfield";
import { SpowseImage } from "../../core/common/reuseables/spowse_image";
import { AppIcons } from "../../core/utilities/app_icons";
import useMediaQuery from "../../core/common/use_media_Query";

type HeaderProps = {
  headingText: string;
};

const Header: React.FC<HeaderProps> = ({ headingText }) => {
  const media = useMediaQuery("(max-width: 1200px)");

  return (
    <HeaderWrapper>
      <HeaderContentWrapper>
        <HeaderContent>
          <Heading1
            fontFamily="matter-medium"
            color={`${theme.colors.linkActiveTextColor}`}
            fontSize="24px"
            lineHeight="32px"
            fontWeight="500"
          >
            {headingText}
          </Heading1>
          <SpowseTextField
            placeHolder="Search"
            width={media ? "300px" : "433px"}
            height="45px"
            background="rgba(234, 236, 240, 1)"
            borderColor="transparent"
            prefixIxon={
              <SpowseImage
                src={AppIcons.searchIcon}
                alt="search icon"
                width="auto"
              />
            }
          />
          <HeaderRightSection>
            <HeaderNotificationIconContainer>
              <SpowseImage
                src={AppIcons.notificationIcon}
                alt="notification icon"
                width="auto"
              />
            </HeaderNotificationIconContainer>
            <HeaderUserContainer>
              <HeaderProfileImageContainer></HeaderProfileImageContainer>
              <HeaderUserNameAndRoleContainer>
                <CommonText
                  fontFamily="Inter"
                  fontWeight="600"
                  color={`${theme.colors.linkActiveTextColor}`}
                  fontSize="14px"
                  lineHeight="20px"
                >
                  Hozier Smith
                </CommonText>
                <CommonText
                  fontFamily="Inter"
                  fontWeight="500"
                  color={`${theme.colors.veryDarkGrey}`}
                  fontSize="14px"
                  lineHeight="20px"
                >
                  Admin
                </CommonText>
              </HeaderUserNameAndRoleContainer>
            </HeaderUserContainer>
          </HeaderRightSection>
        </HeaderContent>
      </HeaderContentWrapper>
    </HeaderWrapper>
  );
};
export default Header;
