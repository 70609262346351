import React from "react";
import styled from "styled-components";
import { FaCheck } from "react-icons/fa6";
import { theme } from "../../../theme/theme";

type CheckContainerProps = {
  onClick: any;
  active: boolean;
  width?: string;
  height?: string;
};

const CheckBoxContainer = styled.div<{
  height?: string;
  width?: string;
  borderRaduis?: string;
  border?: any;
  backgroundColor?: any;
}>`
  height: ${(probs) => probs.height || "16px"};
  width: ${(probs) => probs.width || "16px"};
  border-radius: ${(probs) => probs.borderRaduis || "3px"};
  border: ${(probs) => probs.border || `1px solid ${theme.colors.borderColor}`};
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${(probs) => probs.backgroundColor};
  cursor: pointer;
`;

const CheckContainer: React.FC<CheckContainerProps> = ({
  height,
  width,
  onClick,
  active,
}) => {
  return (
    <CheckBoxContainer
      border={
        active
          ? `1px solid ${theme.colors.white}`
          : `1px solid ${theme.colors.borderColor}`
      }
      backgroundColor={
        active ? `${theme.colors.primary}` : `${theme.colors.white}`
      }
      onClick={onClick}
      width={width}
      height={height}
    >
      <FaCheck
        style={{
          width: "10px",
          height: "10px",
          color: `${theme.colors.white}`,
        }}
      />
    </CheckBoxContainer>
  );
};
export default CheckContainer;
